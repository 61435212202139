/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

import axios from 'axios';

import {
    TAGS_REQUEST, TAGS_RECEIVED, TAGS_REQUEST_ERROR,
    TAGS_CREATE_TAG_REQUEST, TAGS_CREATE_TAG_REQUEST_ERROR,
    TAGS_DELETE_TAG_REQUEST, TAGS_DELETE_TAG_REQUEST_ERROR,
} from '../actions/types';

import { displayNotification, getRequestConfig, handleCustomErrors, handleServerErrors } from '../utils';

import { API_URL } from '../settings';

// LIST TAGS
const requestTags = () => ({
    type: TAGS_REQUEST,
});

const receiveTags = (tags, maxResults, selectedPage, totalResults) => ({
    type: TAGS_RECEIVED,
    payload: {
        tags,
        paginationObj: {
            maxResults,
            selectedPage,
            totalResults,
        },
    },
});

const requestTagsError = () => ({
    type: TAGS_REQUEST_ERROR,
});

// CREATE TAG
const requestTagsCreateTag = () => ({
    type: TAGS_CREATE_TAG_REQUEST,
});

const requestTagsCreateTagError = () => ({
    type: TAGS_CREATE_TAG_REQUEST_ERROR,
});

// DELETE TAGS
const requestTagsDeleteTag = () => ({
    type: TAGS_DELETE_TAG_REQUEST,
});

const requestTagsDeleteTagError = () => ({
    type: TAGS_DELETE_TAG_REQUEST_ERROR,
});

// UI ACTIONS

export const fetchTags = (token, selectedPage = 1, searchObj = {}) => {
    return async (dispatch) => {
        dispatch(requestTags());
        try {
            let url = `${API_URL}/tag`;

            let filtersNum = 0;

            if (searchObj.nameSearch && searchObj.nameSearch.length > 0) {
                url = url.concat(`?name=${searchObj.nameSearch}`);
                filtersNum++;
            }

            if (searchObj.sortColumn && searchObj.sortColumn.length > 0) {
                let prefix = '?';
                if (filtersNum > 0) {
                    prefix = '&';
                }
                url = url.concat(`${prefix}sortAsc=${searchObj.sortDirection === 'ascending'}`);
            } else {
                let prefix = '?';
                if (filtersNum > 0) {
                    prefix = '&';
                }
                url = url.concat(`${prefix}sortAsc=true`);
            }

            const { data, headers } = await axios.get(
                url,
                getRequestConfig(token, selectedPage),
            );
            dispatch(receiveTags(
                data,
                headers['max-results'], headers['selected-page'], headers['total-results'],
            ));
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.other && error.response.data.other.errorCode) {
                handleCustomErrors(error.response.data.other.errorCode);
            } else {
                handleServerErrors(error, dispatch, requestTagsError);
            }
        }
    };
};

export const createTag = (token, tag, modalClose) => {
    return async (dispatch) => {
        dispatch(requestTagsCreateTag());
        try {
            const formData = new FormData();

            formData.append('iconOn', new Blob([tag.iconOn], { type: 'application/octet-stream' }));
            formData.append('iconOff', new Blob([tag.iconOff], { type: 'application/octet-stream' }));
            formData.append('name', tag.name);

            await axios.post(`${API_URL}/tag`, formData, getRequestConfig(token, null, true));

            dispatch(fetchTags(token));

            displayNotification({ type: 'success', message: 'Tag created!' });

            modalClose();
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.other && error.response.data.other.errorCode) {
                if (error.response.data.other.errorCode === 22) displayNotification({ type: 'error', message: 'File is too big or invalid' });
                else handleCustomErrors(error.response.data.other.errorCode);
            } else {
                handleServerErrors(error, dispatch, requestTagsCreateTagError);
            }
        }
    };
};

export const updateTag = (token, tag, modalClose) => {
    return async (dispatch) => {
        dispatch(requestTagsCreateTag());
        try {
            const formData = new FormData();

            if (tag.iconOn) formData.append('iconOn', new Blob([tag.iconOn], { type: 'application/octet-stream' }));
            if (tag.iconOff) formData.append('iconOff', new Blob([tag.iconOff], { type: 'application/octet-stream' }));
            formData.append('name', tag.name);

            await axios.put(`${API_URL}/tag/${tag.sequence}`, formData, getRequestConfig(token, null, false));

            dispatch(fetchTags(token));

            displayNotification({ type: 'success', message: 'Tag updated!' });

            modalClose();
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.other && error.response.data.other.errorCode) {
                if (error.response.data.other.errorCode === 22) displayNotification({ type: 'error', message: 'File is too big or invalid' });
                else handleCustomErrors(error.response.data.other.errorCode);
            } else {
                handleServerErrors(error, dispatch, requestTagsCreateTagError);
            }
        }
    };
};

export const deleteTag = (token, sequence, selectedPage, filtersObj = {}) => {
    return async (dispatch) => {
        try {
            dispatch(requestTagsDeleteTag());
            await axios.delete(`${API_URL}/tag/${sequence}`, getRequestConfig(token, selectedPage));
            dispatch(fetchTags(token, selectedPage, filtersObj));
            displayNotification({ type: 'success', message: 'Tag deleted!' });
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.other && error.response.data.other.errorCode) {
                handleCustomErrors(error.response.data.other.errorCode);
            } else {
                handleServerErrors(error, dispatch, requestTagsDeleteTagError);
            }
        }
    };
};
