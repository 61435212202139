/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { Button, Icon, Modal } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import withModal from '../hocs/with_modal';

const INITIAL_STATE = { modalOpen: false };

class RemoveMediaModal extends Component {
    state = INITIAL_STATE;

    handleOpen = () => this.setState({ modalOpen: true });

    handleCancel = () => this.setState({ ...INITIAL_STATE });

    handleSubmit = () => {
        const { media, onRemoveMediaPress } = this.props;
        this.setState({ modalOpen: false });
        onRemoveMediaPress(media.sequence);
    };

    renderRemoveMediaModalTrigger = () => (
        <button
            onClick={this.handleOpen}
        >
            <Icon name="trash"/>
        </button>
    );

    render() {
        const { fixBody } = this.props;
        const { modalOpen } = this.state;

        return (
            <Modal
                size="tiny"
                data-testid="remove-media-modal"
                open={modalOpen}
                onUnmount={fixBody}
                onClose={this.handleClose}
                trigger={this.renderRemoveMediaModalTrigger()}>
                <Modal.Header content="Remove media" />
                <Modal.Content>
                    <p>Are you sure you want to remove this media?</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button.Group>
                        <Button content="Cancel" onClick={this.handleCancel}/>
                        <Button.Or/>
                        <Button negative icon="trash" labelPosition="right" content="Remove" onClick={this.handleSubmit} />
                    </Button.Group>
                </Modal.Actions>
            </Modal>
        );
    }
}

RemoveMediaModal.propTypes = {
    media: PropTypes.object,
    fixBody: PropTypes.func,
    onRemoveMediaPress: PropTypes.func,
};

RemoveMediaModal.defaultProps = {
    media: null,
    fixBody: () => {},
    onRemoveMediaPress: () => {},
};

export default withModal(RemoveMediaModal);
