/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone';

// ELEMENTS
import { Card, Grid, Image, Form, Button, Embed, Divider, Segment } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { CustomDatePicker } from '../elements';
// HOCS
import withPagination from '../hocs/with_pagination';
// ACTIONS
import { fetchMedia, createMedia, deleteMedia } from '../../actions';
import { formatDate, getApiImage } from '../../utils';

// MODALS

class MediaGridClass extends Component {
    state = {
        selectedMedia: this.props.otherMedia ? this.props.otherMedia : [],
        newMedia: {
            file: null,
            caption: '',
            captionUrl: '',
            captionDate: 0,
        },
    };

    onDrop = (acceptedFiles) => {
        this.setState({
            ...this.state,
            newMedia: {
                ...this.newMedia,
                file: acceptedFiles[0],
            },
        });
    };

    getGridItems = () => {
        const { data } = this.props;
        const { selectedMedia } = this.state;

        return data.map((media) => {
            const { sequence, caption, captionDate, publicUrls } = media;
            let mediaUrl = null;
            let isVideo = false;
            if (publicUrls) {
                if (publicUrls.mediaVideoUrl) {
                    isVideo = true;
                    mediaUrl = publicUrls.mediaVideoUrl;
                } else {
                    mediaUrl = publicUrls.mediaMediumUrl;
                }
            } else {
                return null;
            }
            return (
                <Grid.Column key={sequence}>
                    <Card style={{ height: '400px' }}>
                        <Card.Content style={{ textAlign: 'center' }}>
                            {isVideo &&
                            <Embed
                                placeholder="/images/image-16by9.png"
                                url={getApiImage(mediaUrl)}
                            />
                            }
                            {!isVideo &&
                            <Image src={getApiImage(mediaUrl)} style={{ maxHeight: '200px' }}/>
                            }
                            <Card.Meta textAlign="left" style={{ marginTop: '20px' }}>
                                {caption ? caption.length > 50 ? `${caption.slice(0, 50)}...` : caption : ''}
                            </Card.Meta>
                        </Card.Content>
                        <Card.Content extra style={{ textAlign: 'center' }}>
                            <Card.Meta textAlign="right">
                                {formatDate(captionDate, 'DD-MM-YYYY')}
                            </Card.Meta>
                            <Button
                                fluid
                                basic={selectedMedia && this.checkSelected(media)}
                                color="green"
                                onClick={() => {
                                    this.selectMedia(media);
                                }}>
                                {selectedMedia && this.checkSelected(media) ? 'Selected' : 'Select'}
                            </Button>
                        </Card.Content>
                    </Card>
                </Grid.Column>
            );
        });
    };

    getSelectedItems = () => {
        const { selectedMedia } = this.state;
        return selectedMedia.map((media) => {
            if (media !== null) {
                let mediaUrl = null;
                let isVideo = false;
                if (media.publicUrls) {
                    if (media.publicUrls.mediaVideoUrl) {
                        isVideo = true;
                        mediaUrl = media.publicUrls.mediaVideoUrl;
                    } else {
                        mediaUrl = media.publicUrls.mediaThumbnailUrl;
                    }
                } else {
                    return null;
                }
                return (
                    <Card
                        key={media.placeMediaId ? media.placeMediaId : media.sequence}
                        style={{ display: 'inline-block', width: '250px', height: '250px', textAlign: 'center', marginLeft: '10px' }}>
                        <Card.Content style={{ height: '178px' }}>
                            {isVideo &&
                            <Embed
                                url={getApiImage(mediaUrl)}
                            />
                            }
                            {!isVideo &&
                            <Image src={getApiImage(mediaUrl)}/>
                            }
                        </Card.Content>
                        {this.props.multiple &&
                        <Card.Content extra style={{ textAlign: 'center' }}>
                            <Button
                                color="red"
                                onClick={() => {
                                    this.selectMedia(media);
                                }}
                            >
                                Remove
                            </Button>
                        </Card.Content>
                        }
                    </Card>
                );
            }
            return null;
        });
    };

    checkSelected = (media) => {
        const { selectedMedia } = this.state;
        for (let i = 0; i < selectedMedia.length; i++) {
            const selected = selectedMedia[i];
            if (selected !== null && selected.sequence) {
                if (selected.sequence === media.sequence) {
                    return true;
                }
            }
        }
        return false;
    };

    handleChange = (e, { name, value }) => {
        this.setState({
            newMedia: {
                ...this.state.newMedia,
                [name]: value,
            },
        });
    };

    uploadMedia = () => {
        const { token } = this.props;
        const { newMedia } = this.state;
        this.props.createMedia(token, newMedia)
            .then(() => {
                setTimeout(() => {
                    this.props.fetchListData(token);
                    this.setState({
                        ...this.state,
                        newMedia: {
                            file: null,
                            caption: '',
                            captionUrl: '',
                            captionDate: 0,
                        },
                    });
                }, 1000);
            });
    };

    deleteMedia = (sequence) => {
        const { token } = this.props;
        this.props.deleteMedia(token, sequence)
            .then(() => {
                this.props.fetchListData(token);
            });
    };

    selectMedia = (media) => {
        const { selectedMedia } = this.state;
        let selected = [];
        this.setState({
            ...this.state,
            selectedMedia: selected,
        });
        if (this.props.multiple) {
            selected = selectedMedia;

            if ((media.mediaId && media.placeMediaId) || (media.mediaId && !media.placeMediaId)) {
                selected.splice(selected.indexOf(media), 1);
            } else {
                let found = null;
                for (let i = 0; i < selectedMedia.length; i++) {
                    const m = selectedMedia[i];
                    if ((m.sequence && m.sequence === media.sequence) || (m.mediaId && m.mediaId === media.sequence)) {
                        found = m;
                        break;
                    }
                }
                if (found) {
                    selected.splice(selected.indexOf(found), 1);
                } else {
                    selected.push(media);
                }
            }
        } else {
            selected.push(media);
        }
        this.props.onSelect(selected);
        this.setState({
            ...this.state,
            selectedMedia: selected,
        });
    };

    render() {
        const { paginationObj } = this.props;
        if (paginationObj === null) return null;

        const { newMedia, selectedMedia } = this.state;
        const { file, caption, captionUrl, captionDate } = newMedia;
        return (
            <div style={{ marginLeft: '5%', marginRight: '5%', width: '100%' }} data-testid="media-grid">
                <div style={{ height: '50vh', width: '90%', overflowY: 'scroll' }}>
                    <Segment style={{ overflowX: 'scroll', whiteSpace: 'nowrap' }}>
                        {selectedMedia.length > 0 && this.getSelectedItems()}
                    </Segment>
                    <Divider/>
                    <Grid style={{ marginLeft: '1%' }}>
                        <Grid.Column width={6}>
                            <Dropzone
                                accept="image/png, image/jpeg, video/mp4, video/webm"
                                onDrop={this.onDrop}
                                multiple={false}
                            >
                                {!file &&
                                <p>Please drag and drop a file or click here to open file selection dialog.</p>
                                }
                                {file &&
                                <Image src={this.state.newMedia.file.preview} style={{ width: '200px', height: '196px' }}/>
                                }
                            </Dropzone>
                        </Grid.Column>
                        <Grid.Column width={10}>
                            <Form.Input
                                placeholder="Caption"
                                name="caption"
                                value={caption || ''}
                                onChange={this.handleChange}
                                style={{ width: '200px' }}/>
                            <Form.Input
                                placeholder="Caption URL"
                                name="captionUrl"
                                value={captionUrl || ''}
                                onChange={this.handleChange}
                                style={{ width: '200px' }}/>
                            <Form.Input
                                name="captionDate"
                                className="form-date-input"
                                style={{ width: '200px' }}
                                input={
                                    <CustomDatePicker
                                        placeholder="Caption Date"
                                        value={captionDate}
                                        handleChange={date => this.handleChange(null, { name: 'captionDate', value: date })}/>
                                }/>
                            <Button positive onClick={this.uploadMedia} style={{ width: '200px' }}>Upload Media</Button>
                        </Grid.Column>
                    </Grid>
                    <Grid columns={2} style={{ marginLeft: '1%' }}>
                        {this.getGridItems()}
                    </Grid>
                </div>
            </div>
        );
    }
}

function mapStateToProps({ auth, media }) {
    const { token } = auth;
    const { media: data, paginationObj } = media;

    return {
        token,
        data,
        paginationObj,
    };
}

export const MediaGrid = connect(mapStateToProps, {
    fetchListData: fetchMedia,
    createMedia,
    deleteMedia,
})(withPagination(MediaGridClass));

MediaGridClass.propTypes = {
    onSelect: PropTypes.func.isRequired,
    multiple: PropTypes.bool,
    otherMedia: PropTypes.array,
};

MediaGridClass.defaultProps = {
    multiple: false,
    otherMedia: [],
};
