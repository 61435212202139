/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

import axios from 'axios';

import {
    STORIES_REQUEST, STORIES_RECEIVED, STORIES_REQUEST_ERROR,
    STORIES_CREATE_STORY_REQUEST, STORIES_CREATE_STORY_REQUEST_ERROR,
    STORIES_DELETE_STORY_REQUEST, STORIES_DELETE_STORY_REQUEST_ERROR,
} from '../actions/types';

import { displayNotification, getRequestConfig, handleCustomErrors, handleServerErrors } from '../utils';

import { API_URL } from '../settings';

// LIST TAGS
const requestStories = () => ({
    type: STORIES_REQUEST,
});

const receiveStories = (stories, maxResults, selectedPage, totalResults) => ({
    type: STORIES_RECEIVED,
    payload: {
        stories,
        paginationObj: {
            maxResults,
            selectedPage,
            totalResults,
        },
    },
});

const requestStoriesError = () => ({
    type: STORIES_REQUEST_ERROR,
});

// CREATE TAG
const requestStoriesCreateStory = () => ({
    type: STORIES_CREATE_STORY_REQUEST,
});

const requestStoriesCreateStoryError = () => ({
    type: STORIES_CREATE_STORY_REQUEST_ERROR,
});

// DELETE TAGS
const requestStoriesDeleteStory = () => ({
    type: STORIES_DELETE_STORY_REQUEST,
});

const requestStoriesDeleteStoryError = () => ({
    type: STORIES_DELETE_STORY_REQUEST_ERROR,
});

// UI ACTIONS

export const fetchStories = (token, selectedPage = 1, searchObj = {}) => {
    return async (dispatch) => {
        dispatch(requestStories());
        try {
            let url = `${API_URL}/story/bo`;

            let filtersNum = 0;

            if (searchObj.dateSearch && searchObj.dateSearch.length > 0) {
                url = url.concat(`?date=${searchObj.dateSearch}`);
                filtersNum++;
            }

            if (searchObj.nameSearch && searchObj.nameSearch.length > 0) {
                let prefix = '?';
                if (filtersNum > 0) {
                    prefix = '&';
                }
                filtersNum++;
                url = url.concat(`${prefix}title=${searchObj.nameSearch}`);
            }

            if (searchObj.authorSearch && searchObj.authorSearch.length > 0) {
                let prefix = '?';
                if (filtersNum > 0) {
                    prefix = '&';
                }
                url = url.concat(`${prefix}userName=${searchObj.authorSearch}`);
            }

            if (searchObj.storyCardsNumberSearch && searchObj.storyCardsNumberSearch.length > 0) {
                let prefix = '?';
                if (filtersNum > 0) {
                    prefix = '&';
                }
                url = url.concat(`${prefix}storyCardsNumber=${searchObj.storyCardsNumberSearch}`);
            }

            if (searchObj.storyTypeNameSearch && searchObj.storyTypeNameSearch.length > 0) {
                let prefix = '?';
                if (filtersNum > 0) {
                    prefix = '&';
                }
                url = url.concat(`${prefix}storyTypeName=${searchObj.storyTypeNameSearch}`);
            }

            if (searchObj.userNameSearch && searchObj.userNameSearch.length > 0) {
                let prefix = '?';
                if (filtersNum > 0) {
                    prefix = '&';
                }
                url = url.concat(`${prefix}userName=${searchObj.userNameSearch}`);
            }

            if (searchObj.sortColumn && searchObj.sortColumn.length > 0) {
                let prefix = '?';
                if (filtersNum > 0) {
                    prefix = '&';
                }
                url = url.concat(`${prefix}storySort=${searchObj.sortColumn}&sortAsc=${searchObj.sortDirection === 'ascending'}`);
            }

            const { data, headers } = await axios.get(
                url,
                getRequestConfig(token, selectedPage),
            );
            dispatch(receiveStories(
                data,
                headers['max-results'], headers['selected-page'], headers['total-results'],
            ));
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.other && error.response.data.other.errorCode) {
                handleCustomErrors(error.response.data.other.errorCode);
            } else {
                handleServerErrors(error, dispatch, requestStoriesError);
            }
        }
    };
};

export const createStory = (token, story, modalClose) => {
    return async (dispatch) => {
        dispatch(requestStoriesCreateStory());
        try {
            const orderedCards = [];

            story.storyCards.forEach((card, index) => {
                orderedCards.push({
                    storyCardType: card.storyCardType,
                    textTitle: card.textTitle,
                    textContent: card.textContent,
                    textLink: card.textLink,
                    placeSequence: card.placeSequence,
                    cardOrder: index + 1,
                    photoSequence: card.photoSequence instanceof Array ? card.photoSequence[0] : card.photoSequence,
                });
            });

            const parsedStory = {
                ...story,
                storyCards: orderedCards,
            };

            await axios.post(`${API_URL}/story`, parsedStory, getRequestConfig(token, null, false));
            dispatch(fetchStories(token));
            displayNotification({ type: 'success', message: 'Story created!' });
            modalClose();
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.other && error.response.data.other.errorCode) {
                handleCustomErrors(error.response.data.other.errorCode);
            } else {
                handleServerErrors(error, dispatch, requestStoriesCreateStoryError);
            }
        }
    };
};

export const updateStory = (token, story, modalClose) => {
    return async (dispatch) => {
        dispatch(requestStoriesCreateStory());
        try {
            const orderedCards = [];

            story.storyCards.forEach((card, index) => {
                if (card.photoSequence) {
                    const photoSequence = card.photoSequence instanceof Array ? card.photoSequence[0] : card.photoSequence;
                    orderedCards.push({
                        ...card,
                        cardOrder: index + 1,
                        photoSequence: photoSequence.sequence ? photoSequence.sequence : photoSequence,
                    });
                } else {
                    orderedCards.push({
                        ...card,
                        cardOrder: index + 1,
                    });
                }
            });

            const parsedStory = {
                ...story,
                storyCards: orderedCards,
            };

            await axios.put(`${API_URL}/story/${story.sequence}`, parsedStory, getRequestConfig(token, null, false));
            dispatch(fetchStories(token));
            displayNotification({ type: 'success', message: 'Story updated!' });
            modalClose();
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.other && error.response.data.other.errorCode) {
                handleCustomErrors(error.response.data.other.errorCode);
            } else {
                handleServerErrors(error, dispatch, requestStoriesCreateStoryError);
            }
        }
    };
};

export const deleteStory = (token, sequence, selectedPage, filtersObj = {}) => {
    return async (dispatch) => {
        try {
            dispatch(requestStoriesDeleteStory());
            await axios.delete(`${API_URL}/story/${sequence}`, getRequestConfig(token, selectedPage));
            dispatch(fetchStories(token, selectedPage, filtersObj));
            displayNotification({ type: 'success', message: 'Story deleted!' });
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.other && error.response.data.other.errorCode) {
                handleCustomErrors(error.response.data.other.errorCode);
            } else {
                handleServerErrors(error, dispatch, requestStoriesDeleteStoryError);
            }
        }
    };
};
