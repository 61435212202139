/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import UploadMediaModal from '../modals/upload_media_modal';
import { MediaUploaderListItem } from './media_uploader_list_item';
import { displayFieldsMap, validateTextInputField } from '../../utils';
import { createMedia, updateMedia } from '../../actions';

const INITIAL_STATE = {
    mediaList: [],
    formErrors: null,
    updateMediaWithSequence: null,
};

class MediaUploaderListClass extends Component {
    constructor(props) {
        super(props);
        const { initialData } = props;
        if (initialData) {
            this.state = {
                ...INITIAL_STATE,
                mediaList: [...initialData.map(mediaObj => mediaObj.mediaSequence)],
            };
        } else {
            this.state = { ...INITIAL_STATE };
        }
    }

    onSaveMediaPress = (data, uploaderLocked, callback = null, editSequence = null) => {
        const { token, onMediaListChange } = this.props;
        if (this.validateMedia(data, uploaderLocked)) {
            if (uploaderLocked) {
                this.props.updateMedia(token, data, editSequence, () => {
                    this.setState({ updateMediaWithSequence: editSequence });
                    if (callback) callback();
                });
            } else {
                this.props.createMedia(token, data, (mediaSequence) => {
                    this.setState({
                        mediaList: [
                            ...this.state.mediaList,
                            mediaSequence,
                        ],
                    }, () => {
                        onMediaListChange(this.state.mediaList);
                    });
                    if (callback) callback();
                });
            }
        }
    };

    onRemoveMediaPress = (mediaSequence) => {
        const { onMediaListChange } = this.props;
        const { mediaList } = this.state;
        const filteredSequences = mediaList.filter(sequence => sequence !== mediaSequence);
        this.setState({ mediaList: filteredSequences }, () => {
            onMediaListChange(this.state.mediaList);
        });
    };

    clearFormErrors = () => this.setState({ formErrors: null });

    markMediaAsUpdated = () => this.setState({ updateMediaWithSequence: null });

    validateMedia = (data, uploaderLocked) => {
        const { validationForm } = this.props;
        let errors = {};
        Object.keys(data).forEach((key) => {
            switch (key) {
                case 'mediaRaw':
                    if (!uploaderLocked) {
                        errors[key] = validateTextInputField(data[key], key, displayFieldsMap[key], validationForm);
                        if (!errors[key]) delete errors[key];
                    }
                    break;
                default:
                    console.log(`Something went wrong evaluating ${key}.`);
            }
            if (key === 'mediaMeta') {
                Object.keys(data[key]).forEach((metaKey) => {
                    switch (metaKey) {
                        case 'captionDate':
                        case 'userObject':
                            errors[metaKey] = validateTextInputField(data[key][metaKey], metaKey, displayFieldsMap[metaKey], validationForm);
                            if (!errors[metaKey]) delete errors[metaKey];
                            break;
                        default:
                            console.log(`Something went wrong evaluating ${metaKey}.`);
                    }
                });
            }
        });

        const noErrors = Object.keys(errors).length === 0;
        if (noErrors) errors = null;
        this.setState({ formErrors: errors });
        return errors === null;
    };

    renderMediaListItems = () => {
        const { token, uploaderTitle, uploaderSaveButtonLabel, addMediaTitle } = this.props;
        const { mediaList, formErrors, updateMediaWithSequence } = this.state;
        return mediaList.map(mediaSequence => (
            <MediaUploaderListItem
                key={mediaSequence}
                token={token}
                formErrors={formErrors}
                mediaSequence={mediaSequence}
                uploaderTitle={uploaderTitle}
                addMediaTitle={addMediaTitle}
                onMediaUpdated={this.markMediaAsUpdated}
                clearFormErrors={this.clearFormErrors}
                onSaveMediaPress={this.onSaveMediaPress}
                onRemoveMediaPress={this.onRemoveMediaPress}
                updateMediaWithSequence={updateMediaWithSequence}
                uploaderSaveButtonLabel={uploaderSaveButtonLabel}
            />
        ));
    };

    render() {
        const {
            token, uploaderTitle, limit, hasError, addMediaTitle,
            emptyMediaMessage, uploaderSaveButtonLabel,
        } = this.props;
        const { mediaList, formErrors } = this.state;
        let limitReached = false;
        if (limit) limitReached = mediaList.length >= limit;

        return (
            <Grid.Row className={`photo-grid ${hasError ? 'error' : ''}`}>
                <Grid.Column width={16}>
                    <UploadMediaModal
                        token={token}
                        formErrors={formErrors}
                        limitReached={limitReached}
                        addMediaTitle={addMediaTitle}
                        uploaderTitle={uploaderTitle}
                        clearFormErrors={this.clearFormErrors}
                        onSaveMediaPress={this.onSaveMediaPress}
                        uploaderSaveButtonLabel={uploaderSaveButtonLabel}
                    />
                </Grid.Column>
                <Grid.Column width={16}>
                    <div className="photo-grid-list">
                        {
                            mediaList.length > 0 ?
                                this.renderMediaListItems() :
                                (
                                    <p className="photo-grid-list-empty">
                                        {emptyMediaMessage}
                                    </p>
                                )
                        }
                    </div>
                </Grid.Column>
            </Grid.Row>
        );
    }
}

MediaUploaderListClass.propTypes = {
    token: PropTypes.string,
    limit: PropTypes.number,
    hasError: PropTypes.bool,
    initialData: PropTypes.array,
    createMedia: PropTypes.func,
    updateMedia: PropTypes.func,
    addMediaTitle: PropTypes.string,
    uploaderTitle: PropTypes.string,
    validationForm: PropTypes.string,
    onMediaListChange: PropTypes.func,
    emptyMediaMessage: PropTypes.string,
    uploaderSaveButtonLabel: PropTypes.string,
};

MediaUploaderListClass.defaultProps = {
    token: null,
    limit: null,
    hasError: false,
    initialData: [],
    createMedia: () => {},
    updateMedia: () => {},
    addMediaTitle: null,
    uploaderTitle: null,
    validationForm: null,
    onMediaListChange: () => {},
    emptyMediaMessage: null,
    uploaderSaveButtonLabel: null,
};

export const MediaUploaderList = connect(null, {
    createMedia,
    updateMedia,
})(MediaUploaderListClass);
