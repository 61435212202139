/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

export const USER_PROFILES = [
    'ARGO_ADMINISTRATOR',
    'ANONYMOUS',
    'REGISTERED',
    'CITY_EDITOR',
    'AVATAR',
];

export const USER_PROFILES_MAP = {
    ARGO_ADMINISTRATOR: 'ARGO Administrator',
    ANONYMOUS: 'Anonymous',
    REGISTERED: 'Registered',
    CITY_EDITOR: 'City Editor',
    AVATAR: 'Avatar',
};
