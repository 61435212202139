/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Image, Form, Button, Grid } from 'semantic-ui-react';
import { ToastContainer } from 'react-toastify';

import logo from '../../assets/img/logo-login.png';
import { loginUser } from '../../actions';
import withNavigate from '../hocs/with_navigate';
import { validateTextInputField, displayFieldsMap } from '../../utils';
import { FormErrorMessage } from '../../components/elements';

class LoginScreen extends Component {
    constructor(props) {
        super(props);
        const funcsToBind = [
            'onLoginSubmit',
            'handleChange',
            'updateErrors',
            'validateForm',
        ];
        funcsToBind.forEach((f) => {
            this[f] = this[f].bind(this);
        });
    }

    state = {
        username: '',
        password: '',
        formErrors: null,
    };

    componentWillReceiveProps(newProps) {
        if (newProps.formErrors) {
            this.setState({
                formErrors: { ...newProps.formErrors },
            });
        }
    }

    onLoginSubmit() {
        const credentials = { username: this.state.username.trim(), password: this.state.password.trim() };

        if (this.validateForm()) {
            this.props.loginUser(credentials, this.props.navigateTo);
        }
    }

    handleChange(event, { name, value }) {
        this.setState({
            [name]: value,
            formErrors: this.updateErrors(name),
        });
    }

    validateForm() {
        let errors = {};

        Object.keys(this.state).forEach((field) => {
            switch (field) {
                case 'username':
                case 'password':
                    errors[field] = validateTextInputField(this.state[field], field, displayFieldsMap[field], 'LOGIN_FORM');
                    if (!errors[field]) delete errors[field];
                    break;
                default:
                // silent
            }
        });

        if (Object.keys(errors).length === 0) errors = null;
        this.setState({ formErrors: errors });
        return errors === null;
    }

    updateErrors(fieldName) {
        let updatedErrors = { ...this.state.formErrors };
        delete updatedErrors.other;
        if (updatedErrors[fieldName]) delete updatedErrors[fieldName];
        if (Object.keys(updatedErrors).length === 0) updatedErrors = null;

        return updatedErrors;
    }

    render() {
        const { isFetching } = this.props;
        const {
            username,
            password,
            formErrors,
        } = this.state;

        return (
            <Grid stretched columns="equal" style={{ width: '100%', margin: 0 }}>
                <ToastContainer style={{ marginTop: 80 }}/>
                <Grid.Column/>
                <Grid.Column verticalAlign="middle" mobile={12} tablet={8} computer={6} largeScreen={4} widescreen={4}>
                    <Card fluid raised style={{ backgroundColor: '#000001' }}>
                        <Image src={logo} style={{ padding: 15 }}/>
                        <Card.Content>
                            <Form
                                error={!!formErrors}
                                loading={isFetching}>
                                <Form.Input
                                    label={(formErrors && formErrors.username && formErrors.username.message) || ''}
                                    error={formErrors && !!formErrors.username}
                                    value={username}
                                    name="username"
                                    icon="user"
                                    iconPosition="left"
                                    placeholder="username"
                                    onChange={this.handleChange}/>
                                <Form.Input
                                    label={(formErrors && formErrors.password && formErrors.password.message) || ''}
                                    error={formErrors && !!formErrors.password}
                                    value={password}
                                    name="password"
                                    icon="unlock"
                                    iconPosition="left"
                                    placeholder="password"
                                    type="password"
                                    onChange={this.handleChange}/>
                                <FormErrorMessage formErrors={formErrors}/>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Button
                                        onClick={this.onLoginSubmit}
                                        circular
                                        color="green"
                                        size="big"
                                        icon="check"/>
                                </div>
                            </Form>
                        </Card.Content>
                    </Card>
                </Grid.Column>
                <Grid.Column/>
            </Grid>
        );
    }
}

function mapStateToProps({ auth }) {
    const { isAuthenticated, isFetching, formErrors } = auth;

    return {
        isAuthenticated,
        isFetching,
        formErrors,
    };
}

export default connect(mapStateToProps, {
    loginUser,
})(withNavigate(LoginScreen));
