/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

//  order is important
export const WEEK_DAYS = {
    sun: 'Sunday',
    mon: 'Monday',
    tue: 'Tuesday',
    wed: 'Wednesday',
    thu: 'Thursday',
    fri: 'Friday',
    sat: 'Saturday',
};

export const DAYS_WEEK = {
    SUNDAY: 'sun',
    MONDAY: 'mon',
    TUESDAY: 'tue',
    WEDNESDAY: 'wed',
    THURSDAY: 'thu',
    FRIDAY: 'fri',
    SATURDAY: 'sat',
};

export const MAP_WEEKS_TO_INDEX = (week) => {
    switch (week) {
        case DAYS_WEEK.SUNDAY: return 0;
        case DAYS_WEEK.MONDAY: return 1;
        case DAYS_WEEK.TUESDAY: return 2;
        case DAYS_WEEK.WEDNESDAY: return 3;
        case DAYS_WEEK.THURSDAY: return 4;
        case DAYS_WEEK.FRIDAY: return 5;
        case DAYS_WEEK.SATURDAY: return 6;
        default: return week;
    }
};
