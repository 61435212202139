/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

import React, { Component, Fragment } from 'react';
import { Button, Icon, Modal, Grid } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import withModal from '../hocs/with_modal';
import { MediaUploader } from '../elements';
import { displayNotification } from '../../utils';

const INITIAL_STATE = { modalOpen: false };

class UploadMediaModal extends Component {
    state = INITIAL_STATE;

    handleOpen = () => {
        const { limitReached } = this.props;
        if (limitReached) return displayNotification({ type: 'error', message: 'The maximum limit of media uploaded has been reached.' });
        return this.setState({ modalOpen: true });
    };

    handleClose = () => {
        const { clearFormErrors } = this.props;
        this.setState({ ...INITIAL_STATE });
        clearFormErrors();
    };

    renderUploadModalTrigger = () => {
        const { media, addMediaTitle, uploaderTitle } = this.props;
        const isEditing = !!media;

        if (isEditing) {
            return (
                <button
                    onClick={this.handleOpen}
                >
                    <Icon name="pencil"/>
                </button>
            );
        }
        return (
            <Fragment>
                <h3>{uploaderTitle}</h3>
                <Button
                    icon
                    labelPosition="right"
                    onClick={this.handleOpen}
                    className="photo-grid-add-action"
                >
                    {addMediaTitle} <Icon name="add"/>
                </Button>
            </Fragment>
        );
    };

    render() {
        const {
            token, fixBody, uploaderTitle,
            uploaderSaveButtonLabel, formErrors,
            onSaveMediaPress, media,
        } = this.props;
        let sequence = null;
        if (media) ({ sequence } = media);
        const { modalOpen } = this.state;

        return (
            <Modal
                size="large"
                open={modalOpen}
                onUnmount={fixBody}
                onClose={this.handleClose}
                trigger={this.renderUploadModalTrigger()}
            >
                <Modal.Header content="Upload Media" />
                <Modal.Content>
                    <Grid>
                        <MediaUploader
                            mandatory
                            noBorders
                            token={token}
                            initialData={media}
                            title={uploaderTitle}
                            formErrors={formErrors}
                            onSavePress={(data, uploaderLocked) => onSaveMediaPress(data, uploaderLocked, this.handleClose, sequence)}
                            saveButtonLabel={uploaderSaveButtonLabel}
                        />
                    </Grid>
                </Modal.Content>
                <Modal.Actions>
                    <Button content="Close" onClick={this.handleClose}/>
                </Modal.Actions>
            </Modal>
        );
    }
}

UploadMediaModal.propTypes = {
    token: PropTypes.string,
    media: PropTypes.object,
    fixBody: PropTypes.func,
    formErrors: PropTypes.object,
    limitReached: PropTypes.bool,
    addMediaTitle: PropTypes.string,
    uploaderTitle: PropTypes.string,
    clearFormErrors: PropTypes.func,
    onSaveMediaPress: PropTypes.func,
    uploaderSaveButtonLabel: PropTypes.string,
};

UploadMediaModal.defaultProps = {
    token: null,
    media: null,
    fixBody: () => {},
    formErrors: null,
    limitReached: false,
    addMediaTitle: null,
    uploaderTitle: null,
    clearFormErrors: () => {},
    onSaveMediaPress: () => {},
    uploaderSaveButtonLabel: null,
};

export default withModal(UploadMediaModal);
