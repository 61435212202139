/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Input } from 'semantic-ui-react';
import SortableTree, { toggleExpandedForAll } from 'react-sortable-tree';

import { TableHeader } from '../../elements';
import withPagination from '../../hocs/with_pagination';
import { fetchCategoriesTree } from '../../../actions';

const INITIAL_STATE = {
    treeData: [],
    search: '',
};

class CategoriesTreeClass extends Component {
    state = INITIAL_STATE;

    componentDidMount() {
        const { token } = this.props;
        this.props.fetchListData(token);
        this.mapNodes();
    }

    mapNodes = () => {
        const { data } = this.props;
        const treeData = [];
        data.forEach(node => treeData.push(this.mapNode(node)));
        this.setState({ ...this.state, treeData });
    };

    mapNode = (node) => {
        const children = [];
        if (node.children.length > 0) {
            node.children.forEach(child => children.push(this.mapNode(child)));
        }
        return { title: node.name, children, expanded: false, sequence: node.sequence };
    };

    expand = (expanded) => {
        const newTreeData = toggleExpandedForAll({
            treeData: this.state.treeData,
            expanded,
        });
        this.setState({ ...this.state, treeData: newTreeData });
    };

    render() {
        return (
            <div>
                <TableHeader title="Categories" icon="list" content="View Categories Tree"/>
                <div>
                    <Button onClick={() => { this.expand(true); }}>
                        Expand all
                    </Button>
                    <Button onClick={() => { this.expand(false); }}>
                        Collapse all
                    </Button>
                    <Input
                        placeholder="Search"
                        value={this.state.search}
                        onChange={(e, { value }) => { this.setState({ ...this.state, search: value }); }}
                    />
                    <SortableTree
                        canDrag={false}
                        isVirtualized={false}
                        scaffoldBlockPxWidth={200}
                        treeData={this.state.treeData}
                        onChange={(treeData) => { this.setState({ ...this.state, treeData }); }}
                        getNodeKey={({ node }) => node.sequence}
                        searchQuery={this.state.search}
                    />
                </div>
            </div>
        );
    }
}

function mapStateToProps({ auth, categoriesTree }) {
    const { token } = auth;
    const { categoriesTree: data, paginationObj } = categoriesTree;

    return {
        token,
        data,
        paginationObj,
    };
}

export const CategoriesTree = connect(mapStateToProps, {
    fetchListData: fetchCategoriesTree,
})(withPagination(CategoriesTreeClass));
