/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

import React from 'react';
import { Grid } from 'semantic-ui-react';
import { ToastContainer } from 'react-toastify';
import PropTypes from 'prop-types';

import {
    CustomGrid,
    CustomGridRightColumn,
    AppSidebar,
    NavBar,
} from '../elements';

const tableRowStyle = { padding: 20, paddingLeft: '260px' };

export const MainLayout = (props) => {
    const { user, logout, children } = props;
    return (
        <CustomGrid>
            <AppSidebar user={user}/>
            <CustomGridRightColumn>
                <Grid.Row>
                    <NavBar
                        logoutAction={logout}
                        user={user}/>
                </Grid.Row>
                <Grid.Row style={tableRowStyle}>
                    {children}
                </Grid.Row>
            </CustomGridRightColumn>
            <ToastContainer style={{ marginTop: 80 }}/>
        </CustomGrid>
    );
};

MainLayout.propTypes = {
    user: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired,
};

