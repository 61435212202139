/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Image, Table, Label, Accordion, Dropdown, Button } from 'semantic-ui-react';

import { TablePaginationFooter, TableHeader, SearchBar } from '../../elements';
import withPagination from '../../hocs/with_pagination';
import { fetchUsers, updateUser, deleteUser, changeUserProfiles, resetUserPassword } from '../../../actions';
import { formatDate, getApiImage } from '../../../utils';
import DeleteConfirmationModal from '../../modals/general_delete_confirmation_modal';
import ChangeUserProfilesModal from '../../modals/change_user_profiles_modal';
import CreateUserModal from '../../modals/create_user_modal';
import { USER_PROFILES, USER_PROFILES_MAP } from '../../../constants';
import DefaultAvatarImg from '../../../assets/img/avatar-default.png';

class UsersTableClass extends Component {
    constructor(props) {
        super(props);
        this.userProfiles = USER_PROFILES.map(p => ({ text: USER_PROFILES_MAP[p], value: p }));
    }

    state = {
        searchOpen: false,
        nameSearch: '',
        usernameSearch: '',
        profileSearch: '',
        sortColumn: '',
        sortDirection: '',
    };

    getRows = () => {
        const { data } = this.props;
        if (data.length === 0) return (<Table.Row key="no_results"><Table.Cell>No results</Table.Cell></Table.Row>);

        return data.map((user) => {
            const { sequence, profilePhotoUrl, username, userCategory, name, phone, lastLoginDate, profiles } = user;

            return (
                <Table.Row key={sequence}>
                    <Table.Cell><Image src={profilePhotoUrl ? getApiImage(profilePhotoUrl) : DefaultAvatarImg} style={{ width: '35px', height: '35px' }}/></Table.Cell>
                    <Table.Cell>{name}</Table.Cell>
                    <Table.Cell>{username}</Table.Cell>
                    <Table.Cell>{phone}</Table.Cell>
                    <Table.Cell>{userCategory.name}</Table.Cell>
                    <Table.Cell>{formatDate(lastLoginDate)}</Table.Cell>
                    <Table.Cell>
                        {this.getUserProfileTags(profiles)}
                    </Table.Cell>
                    <Table.Cell textAlign="right">
                        <ChangeUserProfilesModal user={user} cb={this.changeUserProfiles}/>
                        <DeleteConfirmationModal
                            title="Reset Password"
                            content={`Are you sure you want to reset ${name}'s password?`}
                            icon="key"
                            color="yellow"
                            tooltip="Reset Password"
                            sequence={sequence}
                            cb={this.userResetPassword}/>
                        <CreateUserModal user={user}/>
                        <DeleteConfirmationModal
                            title="Delete User"
                            content={`Are you sure you want to delete ${name}?`}
                            icon="trash outline"
                            tooltip="Delete User"
                            sequence={sequence}
                            cb={this.deleteUser}/>
                    </Table.Cell>
                </Table.Row>
            );
        });
    };

    getUserProfileTags(profiles) {
        let labelCount = 0;
        return profiles.map((profile) => {
            labelCount++;
            switch (profile) {
                case 'ARGO_ADMINISTRATOR':
                    if (labelCount === 2 || labelCount === 4) {
                        return (
                            <Fragment key={labelCount}>
                                <Label style={{ width: '100px', textAlign: 'center' }} color="blue">Administrator</Label><br/>
                            </Fragment>);
                    }
                    return (<Label key={labelCount} style={{ width: '100px', textAlign: 'center' }} color="blue">Administrator</Label>);
                case 'ARGO_TEAM':
                    if (labelCount === 2 || labelCount === 4) {
                        return (
                            <Fragment key={labelCount}>
                                <Label style={{ width: '100px', textAlign: 'center' }} color="yellow">Argo Team</Label><br/>
                            </Fragment>);
                    }
                    return (<Label key={labelCount} style={{ width: '100px', textAlign: 'center' }} color="yellow">Argo Team</Label>);
                case 'CITY_EDITOR':
                    if (labelCount === 2 || labelCount === 4) {
                        return (
                            <Fragment key={labelCount}>
                                <Label style={{ width: '100px', textAlign: 'center' }} color="teal">City Editor</Label><br/>
                            </Fragment>);
                    }
                    return (<Label key={labelCount} style={{ width: '100px', textAlign: 'center' }} color="teal">City Editor</Label>);
                case 'AVATAR':
                    if (labelCount === 2 || labelCount === 4) {
                        return (
                            <Fragment key={labelCount}>
                                <Label style={{ width: '100px', textAlign: 'center' }} color="purple">Avatar</Label><br/>
                            </Fragment>);
                    }
                    return (<Label key={labelCount} style={{ width: '100px', textAlign: 'center' }} color="purple">Avatar</Label>);
                case 'REGISTERED':
                    if (labelCount === 2 || labelCount === 4) {
                        return (
                            <Fragment key={labelCount}>
                                <Label style={{ width: '100px', textAlign: 'center' }} color="black">Registered</Label><br/>
                            </Fragment>);
                    }
                    return (<Label key={labelCount} style={{ width: '100px', textAlign: 'center' }} color="black">Registered</Label>);
                default:
                    return null;
            }
        });
    }

    handleSearchName = (event, { value }) => {
        const { token } = this.props;
        const { usernameSearch, profileSearch, sortColumn, sortDirection } = this.state;
        clearTimeout(this.timeout);
        this.setState({
            ...this.state,
            nameSearch: value,
        });
        this.timeout = setTimeout(() => {
            this.props.fetchListData(token, 1, { nameSearch: value, usernameSearch, profileSearch, sortColumn, sortDirection });
        }, 500);
    };

    clearSearchName = () => {
        this.handleSearchName(null, { value: '' });
    };

    handleSearchUsername = (event, { value }) => {
        const { token } = this.props;
        const { nameSearch, profileSearch, sortColumn, sortDirection } = this.state;
        clearTimeout(this.timeout);
        this.setState({
            ...this.state,
            usernameSearch: value,
        });
        this.timeout = setTimeout(() => {
            this.props.fetchListData(token, 1, { nameSearch, profileSearch, usernameSearch: value, sortColumn, sortDirection });
        }, 500);
    };

    clearSearchUsername = () => {
        this.handleSearchUsername(null, { value: '' });
    };

    handleSearchProfile = (event, { value }) => {
        const { token } = this.props;
        const { nameSearch, usernameSearch, sortColumn, sortDirection } = this.state;
        clearTimeout(this.timeout);
        this.setState({
            ...this.state,
            profileSearch: value,
        });
        this.timeout = setTimeout(() => {
            this.props.fetchListData(token, 1, { profileSearch: value, nameSearch, usernameSearch, sortColumn, sortDirection });
        }, 500);
    };

    clearSearchProfile = () => {
        this.handleSearchProfile(null, { value: '' });
    };

    handleSort = (clickedColumn) => {
        const { sortColumn, sortDirection } = this.state;
        const { paginationObj, token } = this.props;

        let newColumn = clickedColumn;
        let newDirection = 'ascending';

        if (sortColumn === newColumn) {
            if (sortDirection === 'ascending') {
                newDirection = 'descending';
            } else {
                newColumn = null;
                newDirection = null;
            }
        }

        this.setState({
            sortColumn: newColumn,
            sortDirection: newDirection,
        });

        this.props.fetchListData(
            token,
            paginationObj.selectedPage,
            { sortColumn: newColumn, sortDirection: newDirection },
        );
    };

    deleteUser = (sequence) => {
        const { token, paginationObj } = this.props;
        const { nameSearch, usernameSearch, sortColumn, sortDirection } = this.state;
        this.props.deleteUser(token, sequence, paginationObj.selectedPage, { nameSearch, usernameSearch, sortColumn, sortDirection });
    };

    userResetPassword = (sequence) => {
        const { token, paginationObj } = this.props;
        const { nameSearch, usernameSearch, sortColumn, sortDirection } = this.state;
        this.props.resetUserPassword(token, sequence, paginationObj.selectedPage, { nameSearch, usernameSearch, sortColumn, sortDirection });
    };

    changeUserProfiles = (sequence, profiles, closeModal) => {
        const { token, paginationObj } = this.props;
        const { nameSearch, usernameSearch, sortColumn, sortDirection } = this.state;
        this.props.changeUserProfiles(
            token,
            sequence,
            profiles,
            paginationObj.selectedPage,
            { nameSearch, usernameSearch, sortColumn, sortDirection },
            closeModal,
        );
    };

    render() {
        const { paginationObj, paginateChangePage } = this.props;
        const { searchOpen, nameSearch, usernameSearch, profileSearch, sortColumn, sortDirection } = this.state;

        if (paginationObj === null) return null;

        return (
            <div>
                <TableHeader title="Users" icon="user" content="Manage Requests"/>
                <div>
                    <CreateUserModal/>
                    <br/><br/>
                    <Accordion styled style={{ width: '100%' }}>
                        <Accordion.Title
                            active={searchOpen}
                            onClick={() => this.setState({ ...this.state, searchOpen: !searchOpen })}>
                            Search
                        </Accordion.Title>
                        <Accordion.Content active={searchOpen}>
                            <div style={{ width: '300px', display: 'inline-block' }}>
                                Name<br/>
                                <SearchBar
                                    searchFunc={this.handleSearchName}
                                    clearSearchFunc={this.clearSearchName}
                                    value={nameSearch}/>
                            </div>
                            <div style={{ width: '300px', display: 'inline-block' }}>
                                Username<br/>
                                <SearchBar
                                    searchFunc={this.handleSearchUsername}
                                    clearSearchFunc={this.clearSearchUsername}
                                    value={usernameSearch}/>
                            </div>
                            <div style={{ width: '300px', display: 'inline-block' }}>
                                Profile<br/>
                                <div className="search-dropdown">
                                    <Dropdown
                                        fluid
                                        selection
                                        value={profileSearch}
                                        onChange={this.handleSearchProfile}
                                        placeholder="Search"
                                        options={this.userProfiles}
                                    />
                                    <Button
                                        negative
                                        icon="close"
                                        size="small"
                                        onClick={this.clearSearchProfile}
                                    />
                                </div>
                            </div>
                        </Accordion.Content>
                    </Accordion>
                    <Table striped sortable fixed singleLine>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell width={1}>
                                    Photo
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    width={2}
                                    sorted={sortColumn === 'NAME' ? sortDirection : null}
                                    onClick={() => { this.handleSort('NAME'); }}
                                >
                                    Name
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    width={2}
                                    sorted={sortColumn === 'USERNAME' ? sortDirection : null}
                                    onClick={() => { this.handleSort('USERNAME'); }}
                                >
                                    Email
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    width={1}
                                    sorted={sortColumn === 'PHONE' ? sortDirection : null}
                                    onClick={() => { this.handleSort('PHONE'); }}
                                >
                                    Phone
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    width={1}
                                    sorted={sortColumn === 'USER_CATEGORY' ? sortDirection : null}
                                    onClick={() => { this.handleSort('USER_CATEGORY'); }}
                                >
                                    Category
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    width={2}
                                    sorted={sortColumn === 'LAST_LOGIN_DATE' ? sortDirection : null}
                                    onClick={() => { this.handleSort('LAST_LOGIN_DATE'); }}>
                                    Last Login
                                </Table.HeaderCell>
                                <Table.HeaderCell width={4}>
                                    Profiles
                                </Table.HeaderCell>
                                <Table.HeaderCell width={3}>
                                    Options
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {this.getRows()}
                        </Table.Body>
                        <TablePaginationFooter
                            paginationObj={paginationObj}
                            colSpan={10}
                            handleChangePage={paginateChangePage}
                            searchObj={this.state}
                        />
                    </Table>
                </div>
            </div>
        );
    }
}

function mapStateToProps({ auth, users }) {
    const { token } = auth;
    const { users: data, paginationObj } = users;

    return {
        token,
        data,
        paginationObj,
    };
}

export const UsersTable = connect(mapStateToProps, {
    fetchListData: fetchUsers,
    updateUser,
    deleteUser,
    changeUserProfiles,
    resetUserPassword,
})(withPagination(UsersTableClass));

