/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

import React, { Component, Fragment } from 'react';
import { Button, Icon, Modal, Grid } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import withModal from '../hocs/with_modal';
import { getDefaultMedias, validateTextInputField, displayFieldsMap, displayNotification } from '../../utils';
import { MediaUploader } from '../elements';
import { createMedia, updateMedia, updateDefaultMedia } from '../../actions';
import { DEFAULT_MEDIA_TYPES } from '../../constants';

const INITIAL_STATE = {
    modalOpen: false,
    formErrors: null,
    defaultCityCover: null,
    defaultFeedCover: null,
    defaultPlaceCover: null,
    defaultUserCover: null,
    editing: {
        defaultCityCover: true,
        defaultFeedCover: true,
        defaultPlaceCover: true,
        defaultUserCover: true,
    },
    sequences: {
        defaultCityCover: null,
        defaultFeedCover: null,
        defaultPlaceCover: null,
        defaultUserCover: null,
    },
};

class NoContentModal extends Component {
    state = INITIAL_STATE;

    componentDidMount() {
        this.handleOpen();
    }


    onSavePhotoClick = (type, data, uploaderLocked) => {
        if (this.validateMedia(type, data, uploaderLocked)) {
            const { token } = this.props;
            if (uploaderLocked) {
                this.props.updateMedia(token, data, this.state.sequences[type], () => {
                    this.setState({
                        editing: {
                            ...this.state.editing,
                            [type]: false,
                        },
                    });
                });
            } else {
                this.props.createMedia(token, data, (sequence) => {
                    this.setState({
                        ...this.state,
                        sequences: {
                            ...this.state.sequences,
                            [type]: sequence,
                        },
                        editing: {
                            ...this.state.editing,
                            [type]: false,
                        },
                    });
                });
            }
        }
    }

    onEditMediaClick = type => this.setState({
        editing: {
            ...this.state.editing,
            [type]: true,
        },
    });

    onRemoveMediaClick = (type) => {
        this.setState({
            [type]: null,
            sequences: {
                ...this.state.sequences,
                [type]: null,
            },
            editing: {
                ...this.state.editing,
                [type]: true,
            },
        });
    }

    activeMediaSubmission = new Set();


    handleOpen = () => {
        getDefaultMedias(this.props.token)
            .then((response) => {
                const { data } = response;
                this.setState({
                    defaultCityCover: data.cityCover || null,
                    defaultFeedCover: data.feedCover || null,
                    defaultPlaceCover: data.placeCover || null,
                    defaultUserCover: data.userCover || null,
                    editing: {
                        defaultCityCover: data.cityCover === undefined,
                        defaultFeedCover: data.feedCover === undefined,
                        defaultPlaceCover: data.placeCover === undefined,
                        defaultUserCover: data.userCover === undefined,
                    },
                    sequences: {
                        ...this.state.sequences,
                        defaultCityCover: data.cityCover && data.cityCover.sequence,
                        defaultFeedCover: data.feedCover && data.feedCover.sequence,
                        defaultPlaceCover: data.placeCover && data.placeCover.sequence,
                        defaultUserCover: data.userCover && data.userCover.sequence,
                    },
                    modalOpen: true,
                });
            })
            .catch(e => console.log(e));
    };

    handleClose = () => {
        this.setState({ modalOpen: false });
    };

    handleSubmit = () => {
        if (this.state.formErrors) {
            displayNotification({
                type: 'error',
                message:
            'You haven\'t uploaded the mandatory media or you have not saved it yet.',
            });
            return;
        }
        const { token } = this.props;
        this.props.updateDefaultMedia(token, this.state.sequences, (error) => {
            if (!error) {
                this.setState({
                    modalOpen: false,
                });
                displayNotification({
                    type: 'success',
                    message:
                'Saved',
                });
            }
        });
    }


    validateMedia = (type, data, uploaderLocked) => {
        let errors = {};
        Object.keys(data).forEach((key) => {
            switch (key) {
                case 'mediaRaw':
                    if (!uploaderLocked) {
                        errors[key] = validateTextInputField(
                            data[key],
                            key,
                            displayFieldsMap[key],
                            'NO_CONTENT_FORM',
                        );
                        if (!errors[key]) delete errors[key];
                    }
                    break;
                default:
                    console.log(`Something went wrong evaluating ${key}.`);
            }
            if (key === 'mediaMeta') {
                Object.keys(data[key]).forEach((metaKey) => {
                    switch (metaKey) {
                        case 'captionDate':
                        case 'userObject':
                            errors[metaKey] = validateTextInputField(
                                data[key][metaKey],
                                metaKey,
                                displayFieldsMap[metaKey],
                                'NO_CONTENT_FORM',
                            );
                            if (!errors[metaKey]) delete errors[metaKey];
                            break;
                        default:
                            console.log(`Something went wrong evaluating ${metaKey}.`);
                    }
                });
            }
        });

        const noErrors = Object.keys(errors).length === 0;
        if (noErrors) {
            errors = null;
            this.activeMediaSubmission.delete(type);
        } else this.activeMediaSubmission.add(type);
        this.setState({ formErrors: errors });
        return errors === null;
    };

    renderUploadModalTrigger = () => {
        return (
            <Fragment>
                <h3>No Content</h3>
                <Button
                    icon
                    labelPosition="right"
                    onClick={this.handleOpen}
                    className="photo-grid-add-action"
                >
                    Default medias <Icon name="add"/>
                </Button>
            </Fragment>
        );
    };

    renderMediaUploder = () => {
        const { token } = this.props;

        const { formErrors } = this.state;

        const types = [
            DEFAULT_MEDIA_TYPES.CITY,
            DEFAULT_MEDIA_TYPES.PLACE,
            DEFAULT_MEDIA_TYPES.FEED,
            DEFAULT_MEDIA_TYPES.USER,
        ];

        const map = [
            'City',
            'Place',
            'Latest in this Area/Place',
            'User',
        ]

        return types.map((type, index) => {
            return (
                <MediaUploader
                    mandatory
                    token={token}
                    // initialData={(isEditing && initialCoverMediaObject) || null}
                    initialData={this.state[type]}
                    acceptedMimeTypes={['image/png', 'image/jpeg', 'image/svg+xml', 'video/mp4']}
                    isEditing={this.state.editing[type]}
                    formErrors={this.activeMediaSubmission.has(type) ? formErrors : null}
                    onSavePress={(data, uploaderLocked) => this.onSavePhotoClick(type, data, uploaderLocked)}
                    onEditMediaClick={() => this.onEditMediaClick(type)}
                    onRemoveMediaClick={() => this.onRemoveMediaClick(type)}
                    saveButtonLabel="Save media"
                    title={map[index]}
                />
            );
        });
    };

    render() {
        const { modalOpen } = this.state;

        return (
            <Modal
                closeIcon
                closeOnDimmerClick={false}
                closeOnEscape={false}
                size="large"
                open={modalOpen}
                onClose={this.handleClose}
                trigger={this.renderUploadModalTrigger()}
            >
                <Modal.Header content="No Content" />
                <Modal.Content>
                    <Grid>
                        {
                            this.renderMediaUploder()
                        }

                    </Grid>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        icon
                        color="green"
                        labelPosition="right"
                        onClick={this.handleSubmit}
                    >
                        Save <Icon name="save" />
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

NoContentModal.propTypes = {
    token: PropTypes.string,
};

NoContentModal.defaultProps = {
    token: null,
};

export default connect(null, {
    createMedia,
    updateMedia,
    updateDefaultMedia,
})(withModal(NoContentModal));
