/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Grid, Button, Icon, Form, Image } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';

// Actions
import { createTag, updateTag } from '../../actions';
// Utils
import {
    displayFieldsMap, displayNotification, getApiImage, validateImageField,
    validateTextInputField,
} from '../../utils';
// Components
import { RowActionButton } from '../elements';

// HOCS
import withModal from '../hocs/with_modal';

const INITIAL_STATE = {
    modalOpen: false,
    formErrors: null,
    tag: {
        name: '',
        iconOn: null,
        iconOff: null,
    },
};

class CreateTagModal extends Component {
    static propTypes = {
        tag: PropTypes.object,
    };

    static defaultProps = {
        tag: null,
    };

    constructor(props) {
        super(props);
        const funcsToBind = [
            'getTrigger',
            'handleOpen',
            'handleClose',
            'handleSubmit',
            'handleChange',
            'validateForm',
        ];
        funcsToBind.forEach((f) => {
            this[f] = this[f].bind(this);
        });
    }

    state = INITIAL_STATE;

    onDropIconOn = (acceptedFiles) => {
        this.setState({
            ...this.state,
            tag: {
                ...this.state.tag,
                iconOn: acceptedFiles[0],
            },
        });
    };

    onDropIconOff = (acceptedFiles) => {
        this.setState({
            ...this.state,
            tag: {
                ...this.state.tag,
                iconOff: acceptedFiles[0],
            },
        });
    };

    getTrigger() {
        if (this.props.tag) {
            return <RowActionButton text="Edit" icon="pencil" color="yellow" onClick={this.handleOpen}/>;
        }

        return (
            <Button
                icon
                primary
                style={{ marginBottom: 10 }}
                onClick={this.handleOpen}
                floated="right"
                labelPosition="left"
                size="small">
                <Icon name="tag"/> Create Tag
            </Button>
        );
    }

    handleOpen() {
        if (this.props.tag) {
            this.setState({ ...INITIAL_STATE, tag: this.props.tag, modalOpen: true });
        } else {
            this.setState({ ...INITIAL_STATE, modalOpen: true });
        }
    }

    handleClose() {
        this.setState(INITIAL_STATE);
    }

    handleSubmit() {
        if (this.validateForm()) {
            if (this.props.tag) {
                this.props.updateTag(this.props.token, this.state.tag, this.handleClose);
            } else {
                this.props.createTag(this.props.token, this.state.tag, this.handleClose);
            }
        }
    }

    handleChange(e, { name, value }) {
        this.setState({
            tag: {
                ...this.state.tag,
                [name]: value,
            },
        });
    }

    validateForm() {
        let errors = {};
        Object.keys(this.state.tag)
            .forEach((field) => {
                switch (field) {
                    case 'name':
                        errors[field] = validateTextInputField(this.state.tag[field], field, displayFieldsMap[field], 'CREATE_TAG_FORM');
                        if (!errors[field]) delete errors[field];
                        break;
                    case 'iconOn':
                        errors[field] = validateImageField(this.state.tag[field], field, displayFieldsMap[field], 'CREATE_TAG_FORM');
                        if (!errors[field]) delete errors[field];
                        else displayNotification({ type: 'error', message: 'Icon On image field may not be empty.' });
                        break;
                    case 'iconOff':
                        errors[field] = validateImageField(this.state.tag[field], field, displayFieldsMap[field], 'CREATE_TAG_FORM');
                        if (!errors[field]) delete errors[field];
                        else displayNotification({ type: 'error', message: 'Icon Off image field may not be empty.' });
                        break;
                    default:
                        console.log(`Something went wrong evaluating ${field}.`);
                }
            });

        if (Object.keys(errors).length === 0) errors = null;
        this.setState({ formErrors: errors });
        return errors === null;
    }

    render() {
        const { modalOpen, tag, formErrors } = this.state;
        const { name, iconOn, iconOff, iconOnURL, iconOffURL } = tag;

        if (!modalOpen) return this.getTrigger();

        return (
            <Modal
                closeIcon
                className="create-tag-modal"
                onClose={this.handleClose}
                onUnmount={this.props.fixBody}
                closeOnDimmerClick={false}
                closeOnEscape={false}
                open={modalOpen}
                size="mini"
                trigger={this.getTrigger()}>
                <Modal.Header>{this.props.tag ? `Edit Tag - ${this.props.tag.name}` : 'Create Tag'}</Modal.Header>
                <Modal.Content>
                    <Grid>
                        <Grid.Column width={16}>
                            <Form.Input
                                fluid
                                placeholder="Name"
                                label={`Name ${(formErrors && formErrors.name && formErrors.name.message) || ''}`}
                                error={formErrors && !!formErrors.name}
                                name="name"
                                value={name || ''}
                                onChange={this.handleChange}/>
                        </Grid.Column>
                        <Grid.Column width={16} style={{ marginLeft: '19%' }}>
                            <h5>Icon On</h5>
                            <Dropzone
                                accept="image/png, image/jpeg, image/svg+xml"
                                onDrop={this.onDropIconOn}
                                multiple={false}>
                                {!iconOn && !iconOnURL &&
                                    <p>Please drag and drop a file or click here to open file selection dialog.</p>
                                }
                                {!iconOn && iconOnURL &&
                                <Image src={getApiImage(iconOnURL)} style={{ width: '200px', height: '196px' }}/>
                                }
                                {iconOn &&
                                <Image src={iconOn.preview} style={{ width: '200px', height: '196px' }}/>
                                }
                            </Dropzone>
                        </Grid.Column>
                        <Grid.Column width={16} style={{ marginLeft: '19%' }}>
                            <h5>Icon Off</h5>
                            <Dropzone
                                accept="image/png, image/jpeg, image/svg+xml"
                                onDrop={this.onDropIconOff}
                                multiple={false}>
                                {!iconOff && !iconOffURL &&
                                    <p>Please drag and drop a file or click here to open file selection dialog.</p>
                                }
                                {!iconOff && iconOffURL &&
                                <Image src={getApiImage(iconOffURL)} style={{ width: '200px', height: '196px' }}/>
                                }
                                {iconOff &&
                                <Image src={iconOff.preview} style={{ width: '200px', height: '196px' }}/>
                                }
                            </Dropzone>
                        </Grid.Column>
                    </Grid>
                </Modal.Content>
                <Modal.Actions>
                    <Button icon onClick={this.handleSubmit} labelPosition="right" color="green">Save<Icon name="save"/></Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

function mapStateToProps({ auth, tags }) {
    const { token } = auth;
    const { isFetching, formErrors } = tags;

    return {
        token,
        isFetching,
        formErrors,
    };
}

export default connect(mapStateToProps, {
    createTag,
    updateTag,
})(withModal(CreateTagModal));
