/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Menu, Dropdown, Header } from 'semantic-ui-react';

// ONLY FOR TESTING
import { configureStore } from '../../configure_store';
import withAuth from '../hocs/with_auth';
import withNavigate from '../hocs/with_navigate';

const { persistor } = configureStore();

class NavBarClass extends Component {
    static propTypes = {
        user: PropTypes.object,
        logoutAction: PropTypes.func.isRequired,
    };

    static defaultProps = {
        user: null,
    };

    constructor(props) {
        super(props);
        const funcsToBind = [
            'cleanApplicationData',
            'handleLogout',
        ];
        funcsToBind.forEach((f) => {
            this[f] = this[f].bind(this);
        });
    }

    // ONLY FOR TESTING
    cleanApplicationData() {
        persistor.purge();
        this.props.logoutAction();
    }

    handleLogout() {
        this.props.logoutAction();
    }

    render() {
        if (!this.props.user) return null;
        const { user } = this.props;
        const { name } = user.user;
        return (
            <div className="navbar">
                <Menu secondary borderless fixed="top" style={{ paddingRight: '5%', paddingLeft: '3%', paddingTop: 10 }}>
                    <Menu.Menu position="right">
                        <Menu.Item header fitted>
                            <Dropdown
                                trigger={
                                    <div style={{ display: 'flex', flexDirection: 'column', padding: 5 }}>
                                        <Header as="h4" image>
                                            <Header.Content style={{ marginTop: 20, color: 'white' }}>
                                                {name}
                                            </Header.Content>
                                        </Header>
                                    </div>
                                }
                                pointing="top right"
                                icon={null}>
                                <Dropdown.Menu>
                                    {/*<Dropdown.Item icon="user" content="Account"/>*/}
                                    {/*<Dropdown.Item icon="settings" content="Settings"/>*/}
                                    <Dropdown.Item icon="sign out" content="Sign Out" onClick={this.handleLogout}/>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Menu.Item>
                    </Menu.Menu>
                </Menu>
            </div>
        );
    }
}

function mapStateToProps({ auth }) {
    const { token } = auth;

    return {
        token,
    };
}

export const NavBar = connect(mapStateToProps)(withAuth(withNavigate(NavBarClass)));

