/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

export const LOGIN_ROUTE = '/login';
export const COUNTRIES_ROUTE = '/countries';
export const CITIES_ROUTE = '/cities';
export const TAGS_ROUTE = '/tags';
export const CATEGORIES_ROUTE = '/categories';
export const CATEGORIES_TREE_ROUTE = '/tree_categories';
export const CITY_EDITOR_REQUESTS_ROUTE = '/city_editor_requests';
export const PLACES_ROUTE = '/places';
export const STORIES_ROUTE = '/stories';
export const USERS_ROUTE = '/users';
export const USER_CATEGORIES_ROUTE = '/usercategories';
export const NO_CONTENT_ROUTE = '/no_content';
export const STATION_ROUTE = '/station';
