/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

import axios from 'axios';

import {
    CITY_EDITOR_REQUESTS_REQUEST, CITY_EDITOR_REQUESTS_RECEIVED, CITY_EDITOR_REQUESTS_REQUEST_ERROR,
    CITY_EDITOR_REQUESTS_GRANT_REQUEST, CITY_EDITOR_REQUESTS_GRANT_ERROR,
} from '../actions/types';

import { displayNotification, getRequestConfig, handleCustomErrors, handleServerErrors } from '../utils';

import { API_URL } from '../settings';

// LIST CITY EDITOR REQUESTS
const requestCityEditorRequests = () => ({
    type: CITY_EDITOR_REQUESTS_REQUEST,
});

const receiveCityEditorRequests = (cityEditorRequests, maxResults, selectedPage, totalResults) => ({
    type: CITY_EDITOR_REQUESTS_RECEIVED,
    payload: {
        cityEditorRequests,
        paginationObj: {
            maxResults,
            selectedPage,
            totalResults,
        },
    },
});

const requestCityEditorRequestsError = () => ({
    type: CITY_EDITOR_REQUESTS_REQUEST_ERROR,
});

// GRANT
const requestCityEditorRequestsGrant = () => ({
    type: CITY_EDITOR_REQUESTS_GRANT_REQUEST,
});

const requestCityEditorRequestsGrantError = () => ({
    type: CITY_EDITOR_REQUESTS_GRANT_ERROR,
});

// UI ACTIONS

export const fetchCityEditorRequests = (token, selectedPage = 1, searchObj = {}) => {
    return async (dispatch) => {
        dispatch(requestCityEditorRequests());
        try {
            let url = `${API_URL}/city-editor`;

            if (searchObj.sortDirection) {
                url = url.concat(`?sortAsc=${searchObj.sortDirection === 'ascending'}`);
            }

            const { data, headers } = await axios.get(
                url,
                getRequestConfig(token, selectedPage),
            );
            dispatch(receiveCityEditorRequests(
                data,
                headers['max-results'], headers['selected-page'], headers['total-results'],
            ));
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.other && error.response.data.other.errorCode) {
                handleCustomErrors(error.response.data.other.errorCode);
            } else {
                handleServerErrors(error, dispatch, requestCityEditorRequestsError);
            }
        }
    };
};

export const grantCityEditorRequest = (token, sequence, selectedPage, searchObj = {}) => {
    return async (dispatch) => {
        dispatch(requestCityEditorRequestsGrant());
        try {
            await axios.get(`${API_URL}/city-editor/${sequence}/grant`, getRequestConfig(token, null, false));

            dispatch(fetchCityEditorRequests(token, selectedPage, searchObj));

            displayNotification({ type: 'success', message: 'City editor request granted!' });
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.other && error.response.data.other.errorCode) {
                handleCustomErrors(error.response.data.other.errorCode);
            } else {
                handleServerErrors(error, dispatch, requestCityEditorRequestsGrantError);
            }
        }
    };
};

export const denyCityEditorRequest = (token, sequence, selectedPage, searchObj = {}) => {
    return async (dispatch) => {
        dispatch(requestCityEditorRequestsGrant());
        try {
            await axios.get(`${API_URL}/city-editor/${sequence}/deny`, getRequestConfig(token, null, false));

            dispatch(fetchCityEditorRequests(token, selectedPage, searchObj));

            displayNotification({ type: 'success', message: 'City editor request denied!' });
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.other && error.response.data.other.errorCode) {
                handleCustomErrors(error.response.data.other.errorCode);
            } else {
                handleServerErrors(error, dispatch, requestCityEditorRequestsGrantError);
            }
        }
    };
};
