/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';

export class CustomDatePicker extends Component {
    static propTypes = {
        value: PropTypes.number,
        maxDate: PropTypes.object,
        showTime: PropTypes.bool,
        hasError: PropTypes.bool,
        placeholder: PropTypes.string.isRequired,
        handleChange: PropTypes.func.isRequired,
    };

    static defaultProps = {
        value: null,
        maxDate: null,
        showTime: false,
        hasError: false,
    };

    handleChange = date => this.props.handleChange(date.valueOf());

    render() {
        const { placeholder, showTime, value, maxDate, hasError } = this.props;
        return (
            <DatePicker
                dropdownMode="scroll"
                readOnly
                showYearDropdown
                scrollableYearDropdown
                className={`date-picker ${hasError ? 'error' : ''}`}
                placeholderText={placeholder}
                maxDate={maxDate}
                showTimeSelect={showTime}
                dateFormatCalendar="MMMM"
                dateFormat={showTime ? 'LLL' : 'LL'}
                timeFormat="HH:mm"
                yearDropdownItemNumber={50}
                timeIntervals={5}
                selected={value ? moment(value) : null}
                onChange={this.handleChange}
            />
        );
    }
}

