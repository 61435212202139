/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

import axios from 'axios';

import {
    CATEGORIES_REQUEST, CATEGORIES_RECEIVED, CATEGORIES_REQUEST_ERROR,
    CATEGORIES_CREATE_CATEGORY_REQUEST, CATEGORIES_CREATE_CATEGORY_REQUEST_ERROR,
    CATEGORIES_DELETE_CATEGORY_REQUEST, CATEGORIES_DELETE_CATEGORY_REQUEST_ERROR,
} from '../actions/types';

import { displayNotification, getRequestConfig, handleCustomErrors, handleServerErrors } from '../utils';

import { API_URL } from '../settings';

// LIST CATEGORIES
const requestCategories = () => ({
    type: CATEGORIES_REQUEST,
});

const receiveCategories = (categories, maxResults, selectedPage, totalResults) => ({
    type: CATEGORIES_RECEIVED,
    payload: {
        categories,
        paginationObj: {
            maxResults,
            selectedPage,
            totalResults,
        },
    },
});

const requestCategoriesError = () => ({
    type: CATEGORIES_REQUEST_ERROR,
});

// CREATE CATEGORY
const requestCategoriesCreateCategory = () => ({
    type: CATEGORIES_CREATE_CATEGORY_REQUEST,
});

const requestCategoriesCreateCategoryError = () => ({
    type: CATEGORIES_CREATE_CATEGORY_REQUEST_ERROR,
});

// DELETE CATEGORIES
const requestCategoriesDeleteCategory = () => ({
    type: CATEGORIES_DELETE_CATEGORY_REQUEST,
});

const requestCategoriesDeleteCategoryError = () => ({
    type: CATEGORIES_DELETE_CATEGORY_REQUEST_ERROR,
});

// UI ACTIONS

export const fetchCategories = (token, selectedPage = 1, searchObj = {}, isUserCategories = false) => {
    return async (dispatch) => {
        dispatch(requestCategories());
        try {
            let url = `${API_URL}/${isUserCategories ? 'userCategory' : 'category'}`;

            let filtersNum = 0;

            if (searchObj.nameSearch && searchObj.nameSearch.length > 0) {
                url = url.concat(`?name=${searchObj.nameSearch}`);
                filtersNum++;
            }

            if (searchObj.parentNameSearch && searchObj.parentNameSearch.length > 0) {
                let prefix = '?';
                if (filtersNum > 0) {
                    prefix = '&';
                }
                url = url.concat(`${prefix}parentName=${searchObj.parentNameSearch}`);
                filtersNum++;
            }

            if (searchObj.childrenNumberSearch && searchObj.childrenNumberSearch.length > 0) {
                let prefix = '?';
                if (filtersNum > 0) {
                    prefix = '&';
                }
                url = url.concat(`${prefix}childrenNumber=${searchObj.childrenNumberSearch}`);
                filtersNum++;
            }

            if (searchObj.sortColumn && searchObj.sortColumn.length > 0) {
                let prefix = '?';
                if (filtersNum > 0) {
                    prefix = '&';
                }
                url = url.concat(`${prefix}sort=${searchObj.sortColumn}&sortAsc=${searchObj.sortDirection === 'ascending'}`);
            }

            const { data, headers } = await axios.get(
                url,
                getRequestConfig(token, selectedPage),
            );
            dispatch(receiveCategories(
                data,
                headers['max-results'], headers['selected-page'], headers['total-results'],
            ));
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.other && error.response.data.other.errorCode) {
                handleCustomErrors(error.response.data.other.errorCode);
            } else {
                handleServerErrors(error, dispatch, requestCategoriesError);
            }
        }
    };
};

export const createCategory = (token, category, modalClose, isUserCategory = false) => {
    return async (dispatch) => {
        dispatch(requestCategoriesCreateCategory());
        try {
            const formData = new FormData();

            formData.append('iconOn', new Blob([category.iconOn], { type: 'application/octet-stream' }));
            formData.append('iconOff', new Blob([category.iconOff], { type: 'application/octet-stream' }));
            formData.append('name', category.name);
            if (category.parent) formData.append('parent', category.parent);

            await axios.post(`${API_URL}/${isUserCategory ? 'userCategory' : 'category'}`, formData, getRequestConfig(token, null, true));

            dispatch(fetchCategories(token, 1, {}, isUserCategory));

            displayNotification({ type: 'success', message: 'Category created!' });

            modalClose();
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.other && error.response.data.other.errorCode) {
                if (error.response.data.other.errorCode === 22) displayNotification({ type: 'error', message: 'File is too big or invalid' });
                else handleCustomErrors(error.response.data.other.errorCode);
            } else {
                handleServerErrors(error, dispatch, requestCategoriesCreateCategoryError);
            }
        }
    };
};

export const updateCategory = (token, category, modalClose, isUserCategory = false) => {
    return async (dispatch) => {
        dispatch(requestCategoriesCreateCategory());
        try {
            const formData = new FormData();

            if (category.iconOn) formData.append('iconOn', new Blob([category.iconOn], { type: 'application/octet-stream' }));
            if (category.iconOff) formData.append('iconOff', new Blob([category.iconOff], { type: 'application/octet-stream' }));
            formData.append('name', category.name);
            if (category.parent) formData.append('parent', category.parent);

            await axios.put(`${API_URL}/${isUserCategory ? 'userCategory' : 'category'}/${category.sequence}`, formData, getRequestConfig(token, null, false));

            dispatch(fetchCategories(token, 1, {}, isUserCategory));

            displayNotification({ type: 'success', message: 'Category updated!' });

            modalClose();
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.other && error.response.data.other.errorCode) {
                if (error.response.data.other.errorCode === 22) displayNotification({ type: 'error', message: 'File is too big or invalid' });
                else handleCustomErrors(error.response.data.other.errorCode);
            } else {
                handleServerErrors(error, dispatch, requestCategoriesCreateCategoryError);
            }
        }
    };
};

export const deleteCategory = (token, sequence, selectedPage, filtersObj = {}, isUserCategory = false) => {
    return async (dispatch) => {
        try {
            dispatch(requestCategoriesDeleteCategory());
            await axios.delete(`${API_URL}/${isUserCategory ? 'userCategory' : 'category'}/${sequence}`, getRequestConfig(token, selectedPage));
            dispatch(fetchCategories(token, selectedPage, filtersObj, isUserCategory));
            displayNotification({ type: 'success', message: 'Category deleted!' });
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.other && error.response.data.other.errorCode) {
                handleCustomErrors(error.response.data.other.errorCode);
            } else {
                handleServerErrors(error, dispatch, requestCategoriesDeleteCategoryError);
            }
        }
    };
};
