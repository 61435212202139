/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

import React, { Component, Fragment } from 'react';
import {
    EditorState,
    RichUtils,
    DefaultDraftBlockRenderMap,
    getDefaultKeyBinding,
    KeyBindingUtil,
    Modifier,
} from 'draft-js';
import Editor from 'draft-js-plugins-editor';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';
import createStyles from 'draft-js-custom-styles';
import { Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import addLinkPlugin from './editor_link_plugin';
import TextLinkModal from '../modals/text_link_modal';

const KEY_COMMAND_HANDLED = 'handled';
const KEY_COMMAND_NOT_HANDLED = 'not-handled';
const STYLE_ITALIC = 'ITALIC';
const STYLE_BOLD = 'BOLD';
const BLOCK_UNSTYLED = 'unstyled';

const GRAY_COLOR = '#747474';
const WHITE_COLOR = '#FFFFFF';
const YELLOW_COLOR = '#FFC300';

const { styles, customStyleFn, exporter } = createStyles(['font-weight', 'color']);

export class EditorArea extends Component {
    constructor(props) {
        super(props);
        const { initialContent } = props;
        let editorState = EditorState.createEmpty();
        const options = {
            customInlineFn: (element, { Style }) => {
                if (element.style.color) {
                    return Style(`CUSTOM_COLOR_ ${element.style.color}`);
                }
                return null;
            },
        };
        if (initialContent) editorState = EditorState.createWithContent(stateFromHTML(initialContent, options));

        this.state = {
            editorState,
        };

        this.plugins = [addLinkPlugin];
        this.textLinkModalRef = React.createRef();
    }

    onEditorStateChange = (editorState) => {
        const { onChange, textCard } = this.props;

        this.setState({ editorState }, () => {
            if (onChange) {
                const inlineStyles = exporter(this.state.editorState);
                const isEmpty = this.state.editorState.getCurrentContent().getPlainText().trim() === '';
                let html = stateToHTML(this.state.editorState.getCurrentContent(), { inlineStyles });
                html = html.replace(/<p>/g, `<p style="color: ${textCard ? WHITE_COLOR : GRAY_COLOR};">`);
                onChange(isEmpty ? null : html);
            }
        });
    };

    onUndoClick = () => this.onEditorStateChange(EditorState.undo(this.state.editorState));

    onRedoClick = () => this.onEditorStateChange(EditorState.redo(this.state.editorState));

    onItalicClick = () => this.onEditorStateChange(RichUtils.toggleInlineStyle(this.state.editorState, STYLE_ITALIC));

    onBoldClick = () => this.onEditorStateChange(RichUtils.toggleInlineStyle(this.state.editorState, STYLE_BOLD));

    onBoldYellowClick = () => {
        const newEditorState = styles.color.toggle(this.state.editorState, YELLOW_COLOR);
        this.onEditorStateChange(newEditorState);
    }

    onAddLink = (link) => {
        const { editorState } = this.state;
        const selection = editorState.getSelection();
        if (!link) {
            this.onEditorStateChange(RichUtils.toggleLink(editorState, selection, null));
            return 'handled';
        }
        const content = editorState.getCurrentContent();
        const contentWithEntity = content.createEntity('LINK', 'MUTABLE', { url: link, target: '_blank' });
        const newEditorState = EditorState.push(editorState, contentWithEntity, 'create-entity');
        const entityKey = contentWithEntity.getLastCreatedEntityKey();
        return this.onEditorStateChange(RichUtils.toggleLink(newEditorState, selection, entityKey));
    };

    onClearInlineStylesClick = () => {
        const { editorState } = this.state;
        const selection = editorState.getSelection();
        const contentState = editorState.getCurrentContent();
        const inlineStyles = editorState.getCurrentInlineStyle();
        const removeStyles = inlineStyles.reduce((state, style) => Modifier.removeInlineStyle(state, selection, style), contentState);
        const removeBlock = Modifier.setBlockType(removeStyles, selection, BLOCK_UNSTYLED);

        this.setState({
            editorState: EditorState.push(
                editorState,
                removeBlock,
            ),
        }, () => {
            const { editorState: afterState } = this.state;
            if (RichUtils.currentBlockContainsLink(afterState)) {
                this.setState({ editorState: RichUtils.toggleLink(afterState, selection, null) });
            }
        });
    };

    overrideBindings = (e) => {
        if (e.keyCode === 75 && KeyBindingUtil.hasCommandModifier(e)) {
            return 'add-link';
        }
        return getDefaultKeyBinding(e);
    }

    blockRenderer = () => {};

    handleKeyCommand = (command) => {
        const { editorState } = this.state;
        if (command === 'add-link') {
            return this.textLinkModalRef.current.handleOpen();
        }
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            this.onEditorStateChange(newState);
            return KEY_COMMAND_HANDLED;
        }
        return KEY_COMMAND_NOT_HANDLED;
    };

    render() {
        const { editorState } = this.state;
        const { hasError, boldYellow } = this.props;

        return (
            <Fragment>
                <div className="actions-container">
                    <button
                        className="action"
                        onClick={this.onUndoClick}
                    >
                        <Icon name="reply" />
                    </button>
                    <button
                        className="action"
                        onClick={this.onRedoClick}
                    >
                        <Icon name="share" />
                    </button>
                    <button
                        className="action"
                        onClick={this.onItalicClick}
                    >
                        <em>I</em>
                    </button>
                    <button
                        className="action"
                        onClick={this.onBoldClick}
                    >
                        <strong>B</strong>
                    </button>
                    {
                        boldYellow &&
                        <button
                            className="action"
                            onClick={this.onBoldYellowClick}
                        >
                            <span style={{ color: YELLOW_COLOR }}>A</span>
                        </button>
                    }
                    <TextLinkModal
                        forwardedRef={this.textLinkModalRef}
                        title="Add Link"
                        content="Insert the intended link"
                        tooltip="Add Link"
                        icon="linkify"
                        color="gray"
                        cb={this.onAddLink} />
                    <button
                        className="action"
                        onClick={this.onClearInlineStylesClick}
                    >
                        <Icon name="eraser" />
                    </button>
                </div>
                <div className={`editor-container ${hasError ? 'error' : ''}`}>
                    <Editor
                        editorState={editorState}
                        onChange={this.onEditorStateChange}
                        handleKeyCommand={this.handleKeyCommand}
                        blockRendererFn={this.blockRenderer}
                        blockRenderMap={DefaultDraftBlockRenderMap}
                        keyBindingFn={this.overrideBindings}
                        plugins={this.plugins}
                        customStyleFn={customStyleFn}
                    />
                </div>
            </Fragment>
        );
    }
}

EditorArea.propTypes = {
    hasError: PropTypes.bool,
    onChange: PropTypes.func,
    initialContent: PropTypes.string,
};

EditorArea.defaultProps = {
    hasError: false,
    onChange: null,
    initialContent: null,
};
