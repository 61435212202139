/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Grid, Button, Icon, Form } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import Tree, { SHOW_ALL } from 'rc-tree-select';

import { createUser, updateUser, fetchCategoriesTree } from '../../actions';
import { displayFieldsMap, validateTextInputField } from '../../utils';
import { EditorArea, MediaUploader, RowActionButton } from '../elements';
import withModal from '../hocs/with_modal';
import { PHOTO_TYPES } from '../../constants';

const INITIAL_STATE = {
    modalOpen: false,
    formErrors: null,
    categoryOptions: [],
    initialCoverMediaObject: null,
    initialProfileMediaObject: null,
    user: {
        profilePhoto: null,
        coverPhoto: null,
        name: '',
        phone: '',
        website: '',
        facebook: '',
        instagram: '',
        username: '',
        observations: '',
        introduction: '',
        userCategory: null,
    },
};

class CreateUserModal extends Component {
    state = INITIAL_STATE;

    componentDidUpdate(prevProps) {
        const { categoryOptions } = this.props;
        const { categoryOptions: prevCategoryOptions } = prevProps;
        const { categoryOptions: stateCategoryOptions } = this.state;
        if (categoryOptions && categoryOptions !== prevCategoryOptions && stateCategoryOptions.length === 0) {
            this.mapNodes();
        }
    }

    mapNodes = () => {
        const { categoryOptions: categoryTree } = this.props;
        const categoryOptions = [];
        categoryTree.forEach(node => categoryOptions.push(this.mapNode(node)));
        this.setState({ categoryOptions });
    };

    mapNode = (node) => {
        const children = [];
        if (node.children.length > 0) node.children.forEach(child => children.push(this.mapNode(child)));
        return { disabled: false, key: node.sequence, value: node.sequence, title: node.name, isLeaf: node.childrenNumber === 0, children };
    };

    onChangePhoto = (type, file) => {
        this.setState({
            user: {
                ...this.state.user,
                [type]: file,
            },
        });
    };

    onRemovePhoto = (type) => {
        this.setState({
            user: {
                ...this.state.user,
                [type]: null,
            },
        });
    };

    getTrigger = () => {
        if (this.props.user) {
            return <RowActionButton text="Edit" icon="pencil" color="yellow" onClick={this.handleOpen}/>;
        }

        return (
            <Button
                icon
                primary
                style={{ marginBottom: 10 }}
                onClick={this.handleOpen}
                floated="right"
                labelPosition="left"
                size="small">
                <Icon name="tag"/> Create User
            </Button>
        );
    };

    handleOpen = () => {
        const { user, token } = this.props;
        this.props.fetchCategoriesTree(token, true);
        if (user) {
            let initialCoverMediaObject = null;
            if (user.coverPhotoUrl) {
                initialCoverMediaObject = {
                    publicUrls: {
                        mediaMediumUrl: user.coverPhotoUrl || null,
                    },
                };
            }
            let initialProfileMediaObject = null;
            if (user.profilePhotoUrl) {
                initialProfileMediaObject = {
                    publicUrls: {
                        mediaMediumUrl: user.profilePhotoUrl || null,
                    },
                };
            }
            this.setState({
                ...INITIAL_STATE,
                modalOpen: true,
                initialCoverMediaObject,
                initialProfileMediaObject,
                user: {
                    name: user.name || '',
                    username: user.username || '',
                    phone: user.phone || '',
                    website: user.website || '',
                    facebook: user.facebook || '',
                    instagram: user.instagram || '',
                    coverPhoto: initialCoverMediaObject,
                    profilePhoto: initialProfileMediaObject,
                    sequence: user.sequence,
                    introduction: user.introduction || '',
                    observations: user.observations || '',
                    userCategory: user.userCategory || null,
                },
            });
        } else {
            this.setState({ ...INITIAL_STATE, modalOpen: true });
        }
    };

    handleClose = () => this.setState(INITIAL_STATE);

    handleSubmit = () => {
        if (this.validateForm()) {
            if (this.props.user) {
                this.props.updateUser(this.props.token, this.state.user, this.handleClose);
            } else {
                this.props.createUser(this.props.token, this.state.user, this.handleClose);
            }
        }
    };

    handleChange = (e, { name, value }) => {
        this.setState({
            user: {
                ...this.state.user,
                [name]: value,
            },
        });
    };

    handleCategoryChange = (selected) => {
        const { user, categoryOptions } = this.state;

        const findOption = (array) => {
            for (let i = 0; i < array.length; i++) {
                if (array[i].value === selected) {
                    return array[i];
                }
            }
            for (let i = 0; i < array.length; i++) {
                if (array[i].children) {
                    const result = findOption(array[i].children);
                    if (result) {
                        return result;
                    }
                }
            }
            return null;
        };

        const option = findOption(categoryOptions);
        if (!option) return;

        this.setState({
            user: {
                ...user,
                userCategory: {
                    id: option.value,
                },
            },
        });
    }

    validateForm = () => {
        let errors = {};
        Object.keys(this.state.user)
            .forEach((field) => {
                switch (field) {
                    case 'name':
                    case 'phone':
                    case 'username':
                        errors[field] = validateTextInputField(this.state.user[field], field, displayFieldsMap[field], 'CREATE_USER_FORM');
                        if (!errors[field]) delete errors[field];
                        break;
                    default:
                        console.log(`Something went wrong evaluating ${field}.`);
                }
            });

        if (Object.keys(errors).length === 0) errors = null;
        this.setState({ formErrors: errors });
        return errors === null;
    };

    activeMediaSubmission = new Set();

    renderProfilePhotoUploader = () => {
        const { token, user } = this.props;
        const { formErrors, initialProfileMediaObject } = this.state;
        const isEditing = !!user;

        return (
            <MediaUploader
                mediaOnly
                token={token}
                initialData={(isEditing && initialProfileMediaObject) || null}
                formErrors={this.activeMediaSubmission.has(PHOTO_TYPES.PROFILE) ? formErrors : null}
                onSavePress={data => this.onChangePhoto(PHOTO_TYPES.PROFILE, data)}
                onRemoveMediaClick={() => this.onRemovePhoto(PHOTO_TYPES.PROFILE)}
                title="Profile Media"
            />
        );
    };

    renderCoverPhotoUploader = () => {
        const { token, user } = this.props;
        const { formErrors, initialCoverMediaObject } = this.state;
        const isEditing = !!user;

        return (
            <MediaUploader
                mediaOnly
                token={token}
                width={16}
                initialData={(isEditing && initialCoverMediaObject) || null}
                formErrors={this.activeMediaSubmission.has(PHOTO_TYPES.COVER) ? formErrors : null}
                onSavePress={data => this.onChangePhoto(PHOTO_TYPES.COVER, data)}
                onRemoveMediaClick={() => this.onRemovePhoto(PHOTO_TYPES.COVER)}
                title="Cover Media"
            />
        );
    };

    render() {
        const { fixBody } = this.props;
        const { modalOpen, user, formErrors, categoryOptions } = this.state;
        const {
            username, name, introduction, observations,
            phone, website, facebook, instagram,
        } = user;
        const isEditing = !!this.props.user;

        if (!modalOpen) return this.getTrigger();

        return (
            <Modal
                closeIcon
                className="create-user-modal"
                onClose={this.handleClose}
                onUnmount={fixBody}
                closeOnDimmerClick={false}
                closeOnEscape={false}
                open={modalOpen}
                size="tiny"
                trigger={this.getTrigger()}>
                <Modal.Header>{isEditing ? `Edit User - ${this.props.user.name}` : 'Create User'}</Modal.Header>
                <Modal.Content>
                    <Form>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <Form.Input
                                        fluid
                                        disabled={isEditing}
                                        placeholder="Email"
                                        label={`Email ${(formErrors && formErrors.username && formErrors.username.message) || ''}`}
                                        error={formErrors && !!formErrors.username}
                                        name="username"
                                        value={username || ''}
                                        onChange={this.handleChange}/>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <Form.Input
                                        fluid
                                        placeholder="Name"
                                        label={`Name ${(formErrors && formErrors.name && formErrors.name.message) || ''}`}
                                        error={formErrors && !!formErrors.name}
                                        name="name"
                                        value={name || ''}
                                        onChange={this.handleChange}/>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <div className="field">
                                        <label>Category</label>
                                        <Tree
                                            treeCheckStrictly
                                            className="tree-selector"
                                            treeData={categoryOptions}
                                            placeholder="Choose category"
                                            showCheckedStrategy={SHOW_ALL}
                                            onChange={this.handleCategoryChange}
                                            value={user.userCategory ? [user.userCategory.id] : []}
                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto', zIndex: 1500 }}
                                        />
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <div className={`field ${formErrors && !!formErrors.introduction ? 'error' : ''}`}>
                                        <label>
                                            {`Introduction ${(formErrors && formErrors.introduction && formErrors.introduction.message) || ''}`}
                                        </label>
                                        <EditorArea
                                            hasError={formErrors && !!formErrors.introduction}
                                            initialContent={introduction}
                                            onChange={introductionHtml => this.handleChange(null, { name: 'introduction', value: introductionHtml })}
                                        />
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <div className={`field ${formErrors && !!formErrors.observations ? 'error' : ''}`}>
                                        <label>{`Observations ${(formErrors && formErrors.observations && formErrors.observations.message) || ''}`}</label>
                                        <EditorArea
                                            hasError={formErrors && !!formErrors.observations}
                                            initialContent={observations}
                                            onChange={observationsHtml => this.handleChange(null, { name: 'observations', value: observationsHtml })}
                                        />
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <Form.Input
                                        fluid
                                        placeholder="Phone"
                                        label={`Phone ${(formErrors && formErrors.phone && formErrors.phone.message) || ''}`}
                                        error={formErrors && !!formErrors.phone}
                                        name="phone"
                                        value={phone || ''}
                                        onChange={this.handleChange}/>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <Form.Input
                                        fluid
                                        placeholder="Website"
                                        label={`Website ${(formErrors && formErrors.website && formErrors.website.message) || ''}`}
                                        error={formErrors && !!formErrors.website}
                                        name="website"
                                        value={website || ''}
                                        onChange={this.handleChange}/>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <Form.Input
                                        fluid
                                        placeholder="Facebook"
                                        label={`Facebook ${(formErrors && formErrors.facebook && formErrors.facebook.message) || ''}`}
                                        error={formErrors && !!formErrors.facebook}
                                        name="facebook"
                                        value={facebook || ''}
                                        onChange={this.handleChange}/>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <Form.Input
                                        fluid
                                        placeholder="Instagram"
                                        label={`Instagram ${(formErrors && formErrors.instagram && formErrors.instagram.message) || ''}`}
                                        error={formErrors && !!formErrors.instagram}
                                        name="instagram"
                                        value={instagram || ''}
                                        onChange={this.handleChange}/>
                                </Grid.Column>
                            </Grid.Row>
                            {this.renderProfilePhotoUploader()}
                            {this.renderCoverPhotoUploader()}
                        </Grid>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        icon
                        onClick={this.handleSubmit}
                        labelPosition="right"
                        color="green"
                    >
                        Save <Icon name="save"/>
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

CreateUserModal.propTypes = {
    user: PropTypes.object,
    fixBody: PropTypes.func,
    createUser: PropTypes.func,
    updateUser: PropTypes.func,
};

CreateUserModal.defaultProps = {
    user: null,
    fixBody: () => {},
    createUser: () => {},
    updateUser: () => {},
};

const mapStateToProps = ({ auth, categoriesTree }) => {
    const { token } = auth;
    const { categoriesTree: categoryOptions } = categoriesTree;
    return { token, categoryOptions };
};

export default connect(mapStateToProps, {
    createUser,
    updateUser,
    fetchCategoriesTree,
})(withModal(CreateUserModal));
