/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

// GENERAL
export const LOGOUT = 'LOGOUT';

// AUTH
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

// SESSION
export const SESSION_UPDATE = 'SESSION_UPDATE';

// EVENTS
export const COUNTRIES_REQUEST = 'COUNTRIES_REQUEST';
export const COUNTRIES_RECEIVED = 'COUNTRIES_RECEIVED';
export const COUNTRIES_REQUEST_ERROR = 'COUNTRIES_REQUEST_ERROR';
export const COUNTRIES_CREATE_COUNTRY_REQUEST = 'COUNTRIES_CREATE_COUNTRY_REQUEST';
export const COUNTRIES_CREATE_COUNTRY_REQUEST_ERROR = 'COUNTRIES_CREATE_COUNTRY_REQUEST_ERROR';
export const COUNTRIES_DELETE_COUNTRY_REQUEST = 'COUNTRIES_DELETE_COUNTRY_REQUEST';
export const COUNTRIES_DELETE_COUNTRY_REQUEST_ERROR = 'COUNTRIES_DELETE_COUNTRY_REQUEST_ERROR';

// CITIES
export const CITIES_REQUEST = 'CITIES_REQUEST';
export const CITIES_RECEIVED = 'CITIES_RECEIVED';
export const CITIES_REQUEST_ERROR = 'CITIES_REQUEST_ERROR';
export const CITIES_CREATE_CITY_REQUEST = 'CITIES_CREATE_CITY_REQUEST';
export const CITIES_CREATE_CITY_REQUEST_ERROR = 'CITIES_CREATE_CITY_REQUEST_ERROR';
export const CITIES_DELETE_CITY_REQUEST = 'CITIES_DELETE_CITY_REQUEST';
export const CITIES_DELETE_CITY_REQUEST_ERROR = 'CITIES_DELETE_CITY_REQUEST_ERROR';

// MEDIA
export const MEDIA_REQUEST = 'MEDIA_REQUEST';
export const MEDIA_RECEIVED = 'MEDIA_RECEIVED';
export const MEDIA_REQUEST_ERROR = 'MEDIA_REQUEST_ERROR';
export const MEDIA_CREATE_MEDIA_REQUEST = 'MEDIA_CREATE_MEDIA_REQUEST';
export const MEDIA_CREATE_MEDIA_REQUEST_ERROR = 'MEDIA_CREATE_MEDIA_REQUEST_ERROR';
export const MEDIA_CREATE_MEDIA_REQUEST_SUCCESS = 'MEDIA_CREATE_MEDIA_REQUEST_SUCCESS';
export const MEDIA_DELETE_MEDIA_REQUEST = 'MEDIA_DELETE_MEDIA_REQUEST';
export const MEDIA_DELETE_MEDIA_REQUEST_ERROR = 'MEDIA_DELETE_MEDIA_REQUEST_ERROR';

// TAGS
export const TAGS_REQUEST = 'TAGS_REQUEST';
export const TAGS_RECEIVED = 'TAGS_RECEIVED';
export const TAGS_REQUEST_ERROR = 'TAGS_REQUEST_ERROR';
export const TAGS_CREATE_TAG_REQUEST = 'TAGS_CREATE_TAG_REQUEST';
export const TAGS_CREATE_TAG_REQUEST_ERROR = 'TAGS_CREATE_TAG_REQUEST_ERROR';
export const TAGS_DELETE_TAG_REQUEST = 'TAGS_DELETE_TAG_REQUEST';
export const TAGS_DELETE_TAG_REQUEST_ERROR = 'TAGS_DELETE_TAG_REQUEST_ERROR';

// CATEGORIES
export const CATEGORIES_REQUEST = 'CATEGORIES_REQUEST';
export const CATEGORIES_RECEIVED = 'CATEGORIES_RECEIVED';
export const CATEGORIES_REQUEST_ERROR = 'CATEGORIES_REQUEST_ERROR';
export const CATEGORIES_CREATE_CATEGORY_REQUEST = 'CATEGORIES_CREATE_CATEGORY_REQUEST';
export const CATEGORIES_CREATE_CATEGORY_REQUEST_ERROR = 'CATEGORIES_CREATE_CATEGORY_REQUEST_ERROR';
export const CATEGORIES_DELETE_CATEGORY_REQUEST = 'CATEGORIES_DELETE_CATEGORY_REQUEST';
export const CATEGORIES_DELETE_CATEGORY_REQUEST_ERROR = 'CATEGORIES_DELETE_CATEGORY_REQUEST_ERROR';

// CATEGORIES TREE
export const CATEGORIES_TREE_REQUEST = 'CATEGORIES_TREE_REQUEST';
export const CATEGORIES_TREE_RECEIVED = 'CATEGORIES_TREE_RECEIVED';
export const CATEGORIES_TREE_REQUEST_ERROR = 'CATEGORIES_TREE_REQUEST_ERROR';

// CITY EDITOR REQUESTS
export const CITY_EDITOR_REQUESTS_REQUEST = 'CITY_EDITOR_REQUESTS_REQUEST';
export const CITY_EDITOR_REQUESTS_RECEIVED = 'CITY_EDITOR_REQUESTS_RECEIVED';
export const CITY_EDITOR_REQUESTS_REQUEST_ERROR = 'CITY_EDITOR_REQUESTS_REQUEST_ERROR';
export const CITY_EDITOR_REQUESTS_GRANT_REQUEST = 'CITY_EDITOR_REQUESTS_GRANT_REQUEST';
export const CITY_EDITOR_REQUESTS_GRANT_ERROR = 'CITY_EDITOR_REQUESTS_GRANT_ERROR';
export const CITY_EDITOR_REQUESTS_DENY_REQUEST = 'CITY_EDITOR_REQUESTS_DENY_REQUEST';
export const CITY_EDITOR_REQUESTS_DENY_REQUEST_ERROR = 'CITY_EDITOR_REQUESTS_DENY_REQUEST_ERROR';

// PLACES
export const PLACES_REQUEST = 'PLACES_REQUEST';
export const PLACES_RECEIVED = 'PLACES_RECEIVED';
export const PLACES_REQUEST_ERROR = 'PLACES_REQUEST_ERROR';
export const PLACES_CREATE_PLACE_REQUEST = 'PLACES_CREATE_PLACE_REQUEST';
export const PLACES_CREATE_PLACE_REQUEST_ERROR = 'PLACES_CREATE_PLACE_REQUEST_ERROR';
export const PLACES_DELETE_PLACE_REQUEST = 'PLACES_DELETE_PLACE_REQUEST';
export const PLACES_DELETE_PLACE_REQUEST_ERROR = 'PLACES_DELETE_PLACE_REQUEST_ERROR';

// STORIES
export const STORIES_REQUEST = 'STORIES_REQUEST';
export const STORIES_RECEIVED = 'STORIES_RECEIVED';
export const STORIES_REQUEST_ERROR = 'STORIES_REQUEST_ERROR';
export const STORIES_CREATE_STORY_REQUEST = 'STORIES_CREATE_STORY_REQUEST';
export const STORIES_CREATE_STORY_REQUEST_ERROR = 'STORIES_CREATE_STORY_REQUEST_ERROR';
export const STORIES_DELETE_STORY_REQUEST = 'STORIES_DELETE_STORY_REQUEST';
export const STORIES_DELETE_STORY_REQUEST_ERROR = 'STORIES_DELETE_STORY_REQUEST_ERROR';

// USERS
export const USERS_REQUEST = 'USERS_REQUEST';
export const USERS_RECEIVED = 'USERS_RECEIVED';
export const USERS_REQUEST_ERROR = 'USERS_REQUEST_ERROR';
export const USERS_CREATE_USER_REQUEST = 'USERS_CREATE_USER_REQUEST';
export const USERS_CREATE_USER_REQUEST_ERROR = 'USERS_CREATE_USER_REQUEST_ERROR';
export const USERS_DELETE_USER_REQUEST = 'USERS_DELETE_USER_REQUEST';
export const USERS_DELETE_USER_REQUEST_ERROR = 'USERS_DELETE_USER_REQUEST_ERROR';
export const USERS_RESET_USER_PASSWORD_REQUEST = 'USERS_RESET_USER_PASSWORD_REQUEST';
export const USERS_RESET_USER_PASSWORD_REQUEST_ERROR = 'USERS_RESET_USER_PASSWORD_REQUEST_ERROR';
