/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

import { REHYDRATE } from 'redux-persist';

import {
    PLACES_REQUEST, PLACES_RECEIVED, PLACES_REQUEST_ERROR,
    PLACES_CREATE_PLACE_REQUEST, PLACES_CREATE_PLACE_REQUEST_ERROR,
    PLACES_DELETE_PLACE_REQUEST, PLACES_DELETE_PLACE_REQUEST_ERROR,
} from '../actions/types';

const INITIAL_STATE = {
    places: [],
    isFetching: false,
    fetchingMessage: '',
    paginationObj: null,
    formErrors: null,
};

export default (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case REHYDRATE:
            if (payload && payload.places) {
                return {
                    ...payload.places,
                    isFetching: false,
                    fetchingMessage: '',
                    formErrors: null,
                };
            }
            return state;
        case PLACES_REQUEST:
            return {
                ...state,
                isFetching: true,
                fetchingMessage: 'Fetching places...',
            };
        case PLACES_RECEIVED:
            return {
                ...state,
                ...payload,
                isFetching: false,
            };
        case PLACES_REQUEST_ERROR:
            return {
                ...state,
                isFetching: false,
            };
        case PLACES_CREATE_PLACE_REQUEST:
            return {
                ...state,
                isFetching: true,
                fetchingMessage: 'Saving place...',
            };
        case PLACES_CREATE_PLACE_REQUEST_ERROR:
            return {
                ...state,
                isFetching: false,
            };
        case PLACES_DELETE_PLACE_REQUEST:
            return {
                ...state,
                isFetching: true,
                fetchingMessage: 'Deleting place...',
            };
        case PLACES_DELETE_PLACE_REQUEST_ERROR:
            return {
                ...state,
                isFetching: false,
            };
        default:
            return state;
    }
};
