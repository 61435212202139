/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

import { Component } from 'react';

import withNavigate from '../hocs/with_navigate';
import { LOGIN_ROUTE, PLACES_ROUTE } from '../../constants';

class IndexScreen extends Component {
    componentWillMount() {
        if (this.props.session) {
            if (this.props.session.isLogged) {
                this.props.navigateTo(PLACES_ROUTE);
            } else {
                this.props.navigateTo(LOGIN_ROUTE);
            }
        } else {
            this.props.navigateTo(LOGIN_ROUTE);
        }
    }

    componentWillUpdate(nextProps) {
        if (nextProps.session) {
            if (nextProps.session.isLogged) {
                this.props.navigateTo(PLACES_ROUTE);
            } else {
                this.props.navigateTo(LOGIN_ROUTE);
            }
        } else {
            this.props.navigateTo(LOGIN_ROUTE);
        }
    }

    render() {
        return null;
    }
}

export default withNavigate(IndexScreen);
