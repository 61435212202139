/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { withRouter } from 'react-router';

export default function (WrappedComponent) {
    class WithModal extends Component {
        constructor(props) {
            super(props);
            const funcsToBind = [
                'fixBody',
            ];
            funcsToBind.forEach((f) => {
                this[f] = this[f].bind(this);
            });
        }

        fixBody() {
            const anotherModal = document.getElementsByClassName('ui page modals').length;
            if (anotherModal > 0) document.body.classList.add('scrolling', 'dimmable', 'dimmed');
        }

        render() {
            const { forwardedRef, ...rest } = this.props;
            return (
                <WrappedComponent
                    fixBody={this.fixBody}
                    ref={forwardedRef}
                    {...rest}/>
            );
        }
    }

    return withRouter(WithModal);
}
