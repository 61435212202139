/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

import axios from 'axios';

import {
    CATEGORIES_TREE_REQUEST, CATEGORIES_TREE_RECEIVED, CATEGORIES_TREE_REQUEST_ERROR,
} from '../actions/types';

import { handleCustomErrors, handleServerErrors } from '../utils';

import { API_URL } from '../settings';

// LIST CATEGORIES
const requestCategoriesTree = () => ({
    type: CATEGORIES_TREE_REQUEST,
});

const receiveCategoriesTree = (categoriesTree, maxResults, selectedPage, totalResults) => ({
    type: CATEGORIES_TREE_RECEIVED,
    payload: {
        categoriesTree,
        paginationObj: {
            maxResults,
            selectedPage,
            totalResults,
        },
    },
});

const requestCategoriesTreeError = () => ({
    type: CATEGORIES_TREE_REQUEST_ERROR,
});

// UI ACTIONS

export const fetchCategoriesTree = (token, isUserCategories = false) => {
    return async (dispatch) => {
        dispatch(requestCategoriesTree());
        try {
            const url = `${API_URL}/${isUserCategories ? 'userCategory' : 'category'}/tree`;

            const { data, headers } = await axios.get(url);
            dispatch(receiveCategoriesTree(
                data,
                headers['max-results'], headers['selected-page'], headers['total-results'],
            ));
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.other && error.response.data.other.errorCode) {
                handleCustomErrors(error.response.data.other.errorCode);
            } else {
                handleServerErrors(error, dispatch, requestCategoriesTreeError);
            }
        }
    };
};
