/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

import React, { PureComponent } from 'react';
import { compose, withProps, withHandlers } from 'recompose';
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import PropTypes from 'prop-types';

import { DEFAULT_MAP_CENTER, DEFAULT_MAP_MARKER, MAP_STYLE } from '../../constants';

const googleMapDefaultOptions = {
    styles: MAP_STYLE,
    disableDefaultUI: true,
    disableDoubleClickZoom: true,
};

const MyMapComponent = compose(
    withProps({
        loadingElement: <div style={{ height: '100%' }} />,
        containerElement: <div style={{ height: '200px' }} />,
        mapElement: <div style={{ height: '100%' }} />,
    }),
    withHandlers(() => {
        const refs = { map: undefined };

        return {
            onMapMounted: ({ onMapMounted }) => (ref) => {
                refs.map = ref;
                onMapMounted(refs.map);
            },
        };
    }),
    withGoogleMap,
)((props) => {
    const {
        initialCenter, isMarkerShown,
        onMarkerDragEnd, markerPosition,
        onMapMounted,
    } = props;

    return (
        <GoogleMap
            defaultZoom={13}
            ref={onMapMounted}
            defaultOptions={googleMapDefaultOptions}
            defaultCenter={initialCenter}
        >
            {
                isMarkerShown && (
                    <Marker
                        draggable
                        position={markerPosition}
                        onDragEnd={onMarkerDragEnd}
                    />
                )
            }
        </GoogleMap>
    );
});

export class MapElement extends PureComponent {
    render() {
        const {
            isMarkerShown, initialCenter,
            onMapMounted, markerPosition,
            onMarkerDragEnd,
        } = this.props;

        return (
            <MyMapComponent
                isMarkerShown={isMarkerShown}
                initialCenter={initialCenter}
                onMapMounted={onMapMounted}
                markerPosition={markerPosition}
                onMarkerDragEnd={onMarkerDragEnd}
            />
        );
    }
}

MapElement.propTypes = {
    isMarkerShown: PropTypes.bool,
    initialCenter: PropTypes.shape({
        lat: PropTypes.number,
        lng: PropTypes.number,
    }),
    markerPosition: PropTypes.shape({
        lat: PropTypes.number,
        lng: PropTypes.number,
    }),
    onMapMounted: PropTypes.func,
    onMarkerDragEnd: PropTypes.func,
};

MapElement.defaultProps = {
    isMarkerShown: true,
    initialCenter: DEFAULT_MAP_CENTER,
    markerPosition: DEFAULT_MAP_MARKER,
    onMapMounted: () => {},
    onMarkerDragEnd: () => {},
};
