/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

import { createStore, compose, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';
import { persistStore, persistCombineReducers } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import auth from './reducers/auth_reducer';
import session from './reducers/session_reducer';
import countries from './reducers/countries_reducer';
import cities from './reducers/cities_reducer';
import media from './reducers/media_reducer';
import tags from './reducers/tags_reducer';
import categories from './reducers/categories_reducer';
import categoriesTree from './reducers/categories_tree_reducer';
import cityEditorRequests from './reducers/city_editor_requests_reducer';
import places from './reducers/places_reducer';
import stories from './reducers/stories_reducer';
import users from './reducers/users_reducer';

const isDevVersion = process.env.NODE_ENV === 'development';
const middlewares = [ReduxThunk];

const config = {
    key: 'root',
    storage,
    debug: isDevVersion,
    blacklist: [],
};

const reducers = persistCombineReducers(config, {
    auth,
    session,
    countries,
    cities,
    media,
    tags,
    categories,
    categoriesTree,
    cityEditorRequests,
    places,
    stories,
    users,
});

export const configureStore = () => {
    const store = createStore(
        reducers,
        isDevVersion && window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
        compose(applyMiddleware(...middlewares)),
    );
    const persistor = persistStore(store, null);
    return { persistor, store };
};
