/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Modal, Grid, Button, Icon, Card, Form, Divider } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import {
    createStory,
    updateStory,
    createMedia,
    updateMedia,
} from '../../actions';
import {
    buildStoryObject,
    displayFieldsMap,
    displayNotification,
    getMedia,
    getPlace,
    getPlaces,
    getStory,
    getStoryTypes,
    validateDateInputField,
    validateTextInputField,
} from '../../utils';
import {
    CustomDatePicker,
    EditorArea,
    MediaUploader,
    RowActionButton,
    UsersAutosuggest,
} from '../elements';
import withModal from '../hocs/with_modal';
import { PHOTO_TYPES } from '../../constants';

const STORY_CARD_TYPES = [
    { key: 'TEXT', text: 'Text', value: 'TEXT' },
    { key: 'PLACE', text: 'Place', value: 'PLACE' },
    { key: 'PHOTO', text: 'Photo', value: 'PHOTO' },
    { key: 'SEPARATOR', text: 'Separator', value: 'SEPARATOR' },
];
const INITIAL_STATE = {
    modalOpen: false,
    formErrors: null,
    types: [],
    userObject: null,
    editingCoverPhoto: true,
    initialCoverMediaObject: null,
    story: {
        // media elements
        cover: null,
        photoSequence: null,
        // simple data elements
        title: '',
        introText: '',
        storyDate: null,
        storyCards: [],
        userSequence: null,
        storyTypeSequence: null,
    },
};

class CreateStoryModal extends Component {
    constructor(props) {
        super(props);
        const { userName, userSequence, userProfilePhotoUrl, userUserCategory } = props;
        this.state = {
            ...INITIAL_STATE,
            userObject: {
                name: userName,
                sequence: userSequence,
                category: userUserCategory,
                profilePhoto: userProfilePhotoUrl,
            },
        };
    }

    onSaveCoverClick = (type, data, uploaderLocked) => {
        switch (type) {
            case PHOTO_TYPES.COVER:
                if (this.validateCover(type, data, uploaderLocked)) {
                    const { token } = this.props;
                    const { story } = this.state;
                    if (uploaderLocked) {
                        this.props.updateMedia(token, data, story.photoSequence, () => {
                            this.setState({ editingCoverPhoto: false });
                        });
                    } else {
                        this.props.createMedia(token, data, (photoSequence) => {
                            this.setState({
                                story: {
                                    ...this.state.story,
                                    cover: photoSequence,
                                    photoSequence,
                                },
                                editingCoverPhoto: false,
                            });
                        });
                    }
                }
                break;
            default:
        }
    };

    onEditCoverClick = type => this.setState({ [type]: true });

    onRemoveCoverClick = (type) => {
        switch (type) {
            case 'editingCoverPhoto':
                this.setState({ story: { ...this.state.story, photoSequence: null } });
                break;
            default:
        }
        this.setState({ [type]: true });
    };

    onSaveCardPhotoClick = (index, data, uploaderLocked) => {
        const { token } = this.props;
        const { story } = this.state;
        const { storyCards } = story;

        if (this.validateCardMedia(index, data, uploaderLocked)) {
            if (uploaderLocked) {
                this.props.updateMedia(token, data, storyCards[index].photoSequence, () => {
                    const storyCardsClone = [...storyCards];
                    storyCardsClone[index].editing = false;
                    this.setState({
                        story: {
                            ...this.state.story,
                            storyCards: storyCardsClone,
                        },
                    });
                });
            } else {
                this.props.createMedia(token, data, (photoSequence) => {
                    const storyCardsClone = [...storyCards];
                    storyCardsClone[index].editing = false;
                    storyCardsClone[index].photoSequence = photoSequence;
                    this.setState({
                        story: {
                            ...this.state.story,
                            storyCards: storyCardsClone,
                        },
                    });
                });
            }
        }
    };

    onEditCardMediaClick = (index) => {
        const { story } = this.state;
        const { storyCards } = story;
        const storyCardsClone = [...storyCards];
        storyCardsClone[index].editing = true;
        this.setState({
            story: {
                ...this.state.story,
                storyCards: storyCardsClone,
            },
        });
    };

    onRemoveCardMediaClick = (index) => {
        const { story } = this.state;
        const { storyCards } = story;
        const storyCardsClone = [...storyCards];
        storyCardsClone[index].editing = true;
        storyCardsClone[index].photoSequence = null;
        this.setState({
            story: {
                ...this.state.story,
                storyCards: storyCardsClone,
            },
        });
    };

    onUserChange = userObject => this.setState({ userObject });

    getTrigger = () => {
        if (this.props.story) {
            return <RowActionButton text="Edit" icon="pencil" color="yellow" onClick={this.handleOpen}/>;
        }

        return (
            <Button
                icon
                primary
                style={{ marginBottom: 10 }}
                onClick={this.handleOpen}
                floated="right"
                labelPosition="left"
                size="small">
                <Icon name="book"/> Create Story
            </Button>
        );
    };

    handleOpen = () => {
        const { token } = this.props;
        Promise.resolve(getStoryTypes(token)).then((types) => {
            const parsedTypes = types.map((type) => {
                return {
                    key: type.sequence,
                    text: type.name,
                    value: type.sequence,
                };
            });
            if (this.props.story) {
                Promise.all([
                    getStory(token, this.props.story.sequence),
                    getMedia(token, this.props.story.photoSequence),
                ]).then(([{ story: storyDetails, storyCards, cover: storyCover }, { data: coverMedia }]) => {
                    let initialCoverMediaObject = null;
                    if (coverMedia) initialCoverMediaObject = coverMedia;
                    const promises = [];
                    const mediaPromises = [];
                    storyCards.forEach((card) => {
                        if (card.storyCardType === 'PLACE') {
                            promises.push(getPlace(token, card.placeSequence));
                        } else if (card.storyCardType === 'PHOTO') {
                            mediaPromises.push(getMedia(token, card.photoSequence));
                        }
                    });
                    Promise.all(promises).then((places) => {
                        Promise.all(mediaPromises).then((medias) => {
                            if (places) {
                                places.forEach((place, index) => {
                                    if (place !== null) {
                                        storyCards.filter(s => s.storyCardType === 'PLACE')[index].places = [{
                                            key: place.place.sequence,
                                            text: place.place.name,
                                            value: place.place.sequence,
                                        }];
                                    }
                                });
                            }
                            if (medias) {
                                medias.forEach(({ data: media }, index) => {
                                    if (media) {
                                        storyCards.filter(s => s.storyCardType === 'PHOTO')[index].initialData = media;
                                        storyCards.filter(s => s.storyCardType === 'PHOTO')[index].editing = false;
                                    }
                                });
                            }
                            const parsedStory = buildStoryObject(storyDetails, storyCover, storyCards);
                            this.setState({
                                ...INITIAL_STATE,
                                types: parsedTypes,
                                editingCoverPhoto: !coverMedia,
                                initialCoverMediaObject,
                                userObject: {
                                    name: storyDetails.userName,
                                    sequence: storyDetails.userSequence,
                                    category: storyDetails.userUserCategory,
                                    profilePhoto: storyDetails.userProfilePhotoUrl,
                                },
                                story: parsedStory,
                                modalOpen: true,
                            });
                        });
                    });
                });
            } else {
                this.setState({
                    types: parsedTypes,
                    modalOpen: true,
                });
            }
        });
    };

    handleClose = () => this.setState(INITIAL_STATE);

    handleSubmit = () => {
        if (this.validateForm()) {
            const parsedStory = { ...this.state.story };
            if (this.state.userObject) {
                parsedStory.userName = this.state.userObject.name;
                parsedStory.userSequence = this.state.userObject.sequence;
                parsedStory.userProfilePhotoUrl = this.state.userObject.profilePhoto;
                parsedStory.userUserCategory = this.state.userObject.category;
            }
            if (this.props.story) {
                parsedStory.sequence = this.props.story.sequence;
                this.props.updateStory(this.props.token, parsedStory, this.handleClose);
            } else {
                this.props.createStory(this.props.token, parsedStory, this.handleClose);
            }
        }
    };

    handleChange = (e, { name, value }) => {
        this.setState({
            ...this.state,
            story: {
                ...this.state.story,
                [name]: value,
            },
        });
    };

    handleChangeCard = (index, field, value) => {
        const { story } = this.state;
        const { storyCards } = story;

        storyCards[index][field] = value;

        this.setState({
            ...this.state,
            story: {
                ...this.state.story,
                storyCards,
            },
        });
    };

    addStoryCard = () => {
        const { token } = this.props;
        const { story } = this.state;
        const { storyCards } = story;

        Promise.resolve(getPlaces(token, null)).then((places) => {
            const parsedPlaces = places.map((place) => {
                return { key: place.sequence, text: place.name, value: place.sequence };
            });

            storyCards.push({
                storyCardType: null,
                places: parsedPlaces,
                textContent: '',
                textLink: '',
                photoSequence: '',
                placeSequence: '',
            });

            this.setState({
                ...this.state,
                storyCards,
            });
        });
    };

    removeStoryCard = (index) => {
        const { story } = this.state;
        const { storyCards } = story;

        storyCards.splice(index, 1);

        this.setState({
            ...this.state,
            story: {
                ...story,
                storyCards,
            },
        });
    };

    moveUpStoryCard = (index) => {
        const { story } = this.state;
        const { storyCards } = story;

        const aux = storyCards[index - 1];
        storyCards[index - 1] = storyCards[index];
        storyCards[index] = aux;

        this.setState({
            ...this.state,
            story: {
                ...story,
                storyCards,
            },
        });
    };

    moveDownStoryCard = (index) => {
        const { story } = this.state;
        const { storyCards } = story;

        const aux = storyCards[index + 1];
        storyCards[index + 1] = storyCards[index];
        storyCards[index] = aux;

        this.setState({
            ...this.state,
            story: {
                ...story,
                storyCards,
            },
        });
    };

    searchChange = (index, searchQuery) => {
        const { token } = this.props;
        const { story } = this.state;
        const { storyCards } = story;

        Promise.resolve(getPlaces(token, searchQuery)).then((results) => {
            storyCards[index].places = results.map((place) => {
                return { key: place.sequence, text: place.name, value: place.sequence };
            });

            this.setState({
                ...this.state,
                story: {
                    ...story,
                    storyCards,
                },
            });
        });
    };

    validateCardMedia = (index, data, uploaderLocked) => {
        let errors = {};
        Object.keys(data).forEach((key) => {
            switch (key) {
                case 'mediaRaw':
                    if (!uploaderLocked) {
                        errors[key] = validateTextInputField(data[key], key, displayFieldsMap[key], 'CREATE_STORY_FORM');
                        if (!errors[key]) delete errors[key];
                    }
                    break;
                default:
                    console.log(`Something went wrong evaluating ${key}.`);
            }
            if (key === 'mediaMeta') {
                Object.keys(data[key]).forEach((metaKey) => {
                    switch (metaKey) {
                        case 'captionDate':
                        case 'userObject':
                            errors[metaKey] = validateTextInputField(data[key][metaKey], metaKey, displayFieldsMap[metaKey], 'CREATE_STORY_FORM');
                            if (!errors[metaKey]) delete errors[metaKey];
                            break;
                        default:
                            console.log(`Something went wrong evaluating ${metaKey}.`);
                    }
                });
            }
        });

        const noErrors = Object.keys(errors).length === 0;
        if (noErrors) {
            errors = null;
            this.activeMediaCardSubmission.delete(index);
        } else this.activeMediaCardSubmission.add(index);
        this.setState({ formErrors: errors });
        return errors === null;
    };

    validateCover = (type, data, uploaderLocked) => {
        let errors = {};
        Object.keys(data).forEach((key) => {
            switch (key) {
                case 'mediaRaw':
                    if (!uploaderLocked) {
                        errors[key] = validateTextInputField(data[key], key, displayFieldsMap[key], 'CREATE_STORY_FORM');
                        if (!errors[key]) delete errors[key];
                    }
                    break;
                default:
                    console.log(`Something went wrong evaluating ${key}.`);
            }
            if (key === 'mediaMeta') {
                Object.keys(data[key]).forEach((metaKey) => {
                    switch (metaKey) {
                        case 'captionDate':
                        case 'userObject':
                            errors[metaKey] = validateTextInputField(data[key][metaKey], metaKey, displayFieldsMap[metaKey], 'CREATE_STORY_FORM');
                            if (!errors[metaKey]) delete errors[metaKey];
                            break;
                        default:
                            console.log(`Something went wrong evaluating ${metaKey}.`);
                    }
                });
            }
        });

        const noErrors = Object.keys(errors).length === 0;
        if (noErrors) {
            errors = null;
            this.activeMediaSubmission.delete(type);
        } else this.activeMediaSubmission.add(type);
        this.setState({ formErrors: errors });
        return errors === null;
    };

    validateForm = () => {
        let errors = {};
        Object.keys(this.state.story).forEach((field) => {
            switch (field) {
                case 'title':
                case 'cover':
                case 'storyCards':
                case 'storyTypeSequence':
                // case 'photoSequence':
                    errors[field] = validateTextInputField(this.state.story[field], field, displayFieldsMap[field], 'CREATE_STORY_FORM');
                    if (!errors[field]) delete errors[field];
                    break;
                case 'storyDate':
                    errors[field] = validateDateInputField(this.state.story[field], field, displayFieldsMap[field], 'CREATE_STORY_FORM');
                    if (!errors[field]) delete errors[field];
                    break;
                default:
                    console.log(`Something went wrong evaluating ${field}.`);
            }
        });

        Object.keys(errors).forEach((key) => {
            if (key === 'cover') {
                this.activeMediaSubmission.add(PHOTO_TYPES.COVER);
                errors.mediaRaw = { message: errors.coverSequence };
            }
        });

        let placeCardCount = 0;
        this.state.story.storyCards.forEach((card) => {
            if (card.storyCardType === 'PLACE') placeCardCount++;
        });
        if (placeCardCount === 0) {
            displayNotification({ type: 'error', message: 'A story should have at least one Place card' });
            errors.storyCards = { message: 'A story should have at least one Place card' };
        }
        this.state.story.storyCards.forEach((card) => {
            if (card.storyCardType === 'PHOTO' && !card.photoSequence) {
                displayNotification({ type: 'error', message: 'A story card that is marked as Photo does not have any media associated with it' });
                errors.storyCards = { message: 'A story card that is marked as Photo does not have any media associated with it' };
            }
        });

        // TODO

        if (Object.keys(errors).length === 0) errors = null;
        this.setState({ formErrors: errors });
        return errors === null;
    };

    activeMediaSubmission = new Set();

    activeMediaCardSubmission = new Set();

    renderCoverPhotoUploader = () => {
        const { token, story } = this.props;
        const { formErrors, editingCoverPhoto, initialCoverMediaObject } = this.state;
        const isEditing = !!story;

        return (
            <MediaUploader
                mandatory
                token={token}
                initialData={(isEditing && initialCoverMediaObject) || null}
                acceptedMimeTypes={['image/png', 'image/jpeg', 'image/svg+xml', 'video/mp4']}
                isEditing={editingCoverPhoto}
                formErrors={this.activeMediaSubmission.has(PHOTO_TYPES.COVER) ? formErrors : null}
                onSavePress={(data, uploaderLocked) => this.onSaveCoverClick(PHOTO_TYPES.COVER, data, uploaderLocked)}
                onEditMediaClick={() => this.onEditCoverClick('editingCoverPhoto')}
                onRemoveMediaClick={() => this.onRemoveCoverClick('editingCoverPhoto')}
                saveButtonLabel="Save cover media"
                title="Cover Media"
            />
        );
    };

    renderStoryCards = () => {
        const { token } = this.props;
        const { story, formErrors } = this.state;
        const { storyCards } = story;
        const isEditing = !!this.props.story;

        if (!storyCards) return null;
        return (
            storyCards.map((card, index) => {
                const {
                    sequence, placeSequence, places,
                    storyCardType, textContent,
                    textLink, editing, initialData,
                } = card;
                return (
                    <Card
                        fluid
                        key={sequence || index}
                    >
                        <Grid style={{ margin: '10px' }}>
                            <Grid.Column width={13}/>
                            <Grid.Column width={3} textAlign="right">
                                <Button
                                    icon
                                    color="red"
                                    onClick={() => this.removeStoryCard(index)}
                                >
                                    <Icon name="trash" />
                                </Button>
                                <Button
                                    icon
                                    color="blue"
                                    disabled={index === 0}
                                    onClick={() => this.moveUpStoryCard(index)}
                                >
                                    <Icon name="arrow up" />
                                </Button>
                                <Button
                                    icon
                                    color="blue"
                                    disabled={index === storyCards.length - 1}
                                    onClick={() => this.moveDownStoryCard(index)}
                                >
                                    <Icon name="arrow down" />
                                </Button>
                            </Grid.Column>
                            <Grid.Column width={16}>
                                <Form.Dropdown
                                    label="Card Type"
                                    placeholder="Select an option"
                                    fluid
                                    selection
                                    value={storyCardType}
                                    onChange={(e, { value }) => this.handleChangeCard(index, 'storyCardType', value)}
                                    options={STORY_CARD_TYPES}
                                />
                            </Grid.Column>
                            {
                                storyCardType === 'PLACE' && (
                                    <Fragment>
                                        <Grid.Column width={16}>
                                            <Form.Dropdown
                                                label="Place"
                                                placeholder="Select an option"
                                                search
                                                selection
                                                value={placeSequence}
                                                options={places}
                                                onSearchChange={(e, { searchQuery }) => { this.searchChange(index, searchQuery); }}
                                                onChange={(e, { value }) => this.handleChangeCard(index, 'placeSequence', value)}
                                            />
                                        </Grid.Column>
                                    </Fragment>
                                )
                            }
                            {
                                storyCardType === 'TEXT' && (
                                    <Fragment>
                                        <Grid.Column width={16}>
                                            <div className="field">
                                                <label>Text</label>
                                                <EditorArea
                                                    textCard
                                                    boldYellow
                                                    initialContent={textContent || ''}
                                                    onChange={textHtml => this.handleChangeCard(index, 'textContent', textHtml)}
                                                />
                                            </div>
                                        </Grid.Column>
                                        <Grid.Column width={16}>
                                            <Form.Input
                                                fluid
                                                placeholder="Link"
                                                name="textLink"
                                                label="Link"
                                                value={textLink || ''}
                                                onChange={(e, { value }) => this.handleChangeCard(index, 'textLink', value)}
                                            />
                                        </Grid.Column>
                                    </Fragment>
                                )
                            }
                            {
                                storyCardType === 'PHOTO' && (
                                    <MediaUploader
                                        mandatory
                                        noBorders
                                        token={token}
                                        initialData={(isEditing && initialData) || null}
                                        acceptedMimeTypes={['image/png', 'image/jpeg', 'image/svg+xml', 'video/mp4']}
                                        isEditing={editing}
                                        formErrors={this.activeMediaCardSubmission.has(index) ? formErrors : null}
                                        onSavePress={(data, uploaderLocked) => this.onSaveCardPhotoClick(index, data, uploaderLocked)}
                                        onEditMediaClick={() => this.onEditCardMediaClick(index)}
                                        onRemoveMediaClick={() => this.onRemoveCardMediaClick(index)}
                                        saveButtonLabel="Save media"
                                        title="Media"
                                    />
                                )
                            }
                        </Grid>
                    </Card>
                );
            })
        );
    };

    handleChangeIntro = (introHtml) => {
        this.setState({
            ...this.state,
            story: {
                ...this.state.story,
                introText: introHtml,
            },
        });
    };

    render() {
        const { token, fixBody } = this.props;
        const {
            modalOpen, story,
            types, formErrors,
            userObject,
        } = this.state;
        let username = null;
        let userCategory = null;
        let userProfilePhoto = null;
        const { title, storyTypeSequence, storyDate, userName, introText } = story;
        const isEditing = !!this.props.story;
        if (userName) username = userName;
        else if (userObject && userObject.name) username = userObject.name;
        if (userObject && userObject.profilePhoto) ({ profilePhoto: userProfilePhoto } = userObject);
        if (userObject && userObject.category) ({ category: userCategory } = userObject);

        if (!modalOpen) return this.getTrigger();

        return (
            <Modal
                closeIcon
                className="create-story-modal"
                onClose={this.handleClose}
                onUnmount={fixBody}
                closeOnDimmerClick={false}
                closeOnEscape={false}
                open={modalOpen}
                size="large"
                trigger={this.getTrigger()}
            >
                <Modal.Header>{isEditing ? `Edit Story - ${this.props.story.title}` : 'Create Story'}</Modal.Header>
                <Modal.Content>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={8}>
                                <Form.Input
                                    fluid
                                    placeholder="Title"
                                    name="title"
                                    label={`Title ${(formErrors && formErrors.title && formErrors.title.message) || ''}`}
                                    error={formErrors && !!formErrors.title}
                                    value={title || ''}
                                    onChange={this.handleChange}
                                />
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <div className={`field ${formErrors && formErrors.userObject ? 'error' : ''}`}>
                                    <label>{`User ${(formErrors && formErrors.userObject && formErrors.userObject.message) || ''}`}</label>
                                    <UsersAutosuggest
                                        token={token}
                                        hasError={formErrors && !!formErrors.userObject}
                                        initialPhoto={userProfilePhoto}
                                        initialCategory={userCategory}
                                        initialValue={username}
                                        onUserSelected={this.onUserChange}
                                    />
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={8}>
                                <Form.Dropdown
                                    fluid
                                    selection
                                    label={`Story Type ${(formErrors && formErrors.storyTypeSequence && formErrors.storyTypeSequence.message) || ''}`}
                                    error={formErrors && !!formErrors.storyTypeSequence}
                                    placeholder="Select an option"
                                    name="storyTypeSequence"
                                    value={storyTypeSequence}
                                    onChange={this.handleChange}
                                    options={types}
                                />
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <Form.Input
                                    fluid
                                    label={`Date ${(formErrors && formErrors.storyDate && formErrors.storyDate.message) || ''}`}
                                    error={formErrors && !!formErrors.storyDate}
                                    name="storyDate"
                                    className="date-picker-container"
                                    input={
                                        <CustomDatePicker
                                            hasError={formErrors && !!formErrors.storyDate}
                                            placeholder="Date"
                                            value={storyDate}
                                            handleChange={date => this.handleChange(null, { name: 'storyDate', value: date })}
                                        />
                                    }
                                    style={{ zIndex: 4 }}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16} className="field">
                                <div className="field">
                                    <label>Introduction</label>
                                    <EditorArea
                                        boldYellow
                                        hasError={formErrors && !!formErrors.introText}
                                        onChange={this.handleChangeIntro}
                                        initialContent={introText || ''}
                                    />
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                        {this.renderCoverPhotoUploader()}
                        <Divider />
                        <Grid.Row>
                            <Grid.Column width={16}>
                                {this.renderStoryCards()}
                                <Button
                                    fluid
                                    onClick={this.addStoryCard}
                                    color="green"
                                    style={{ marginBottom: '30px' }}>
                                    Add Story Card
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        icon
                        onClick={this.handleSubmit}
                        labelPosition="right"
                        color="green"
                    >
                        Save <Icon name="save"/>
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

CreateStoryModal.propTypes = {
    story: PropTypes.object,
    userName: PropTypes.string,
    userSequence: PropTypes.number,
    fixBody: PropTypes.func,
    createStory: PropTypes.func,
    updateStory: PropTypes.func,
    createMedia: PropTypes.func,
    updateMedia: PropTypes.func,
};

CreateStoryModal.defaultProps = {
    story: null,
    userName: null,
    userSequence: null,
    fixBody: () => {},
    createStory: () => {},
    updateStory: () => {},
    createMedia: () => {},
    updateMedia: () => {},
};

const mapStateToProps = ({ auth, session }) => {
    const { token } = auth;
    const { user: { user: { name, sequence } } } = session;
    return {
        token,
        userName: name,
        userSequence: sequence,
    };
};

export default connect(mapStateToProps, {
    createStory,
    updateStory,
    createMedia,
    updateMedia,
})(withModal(CreateStoryModal));
