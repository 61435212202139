/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';

import { MainLayout } from '../elements';
import { CountriesTable } from '../elements/tables';

// HOCS
import withAuth from '../hocs/with_auth';
import withNavigate from '../hocs/with_navigate';

class CountriesScreen extends Component {
    render() {
        const {
            user,
            logoutUser,
        } = this.props;

        if (!user) return null;
        return (
            <MainLayout user={user} logout={logoutUser}>
                <CountriesTable/>
            </MainLayout>
        );
    }
}

function mapStateToProps({ auth, session }) {
    const { token } = auth;
    const { user } = session;

    return {
        token,
        user,
    };
}

export default connect(mapStateToProps)(withAuth(withNavigate(CountriesScreen)));

