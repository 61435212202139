/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

import React from 'react';
import { KeyBindingUtil } from 'draft-js';

export const linkStrategy = (contentBlock, callback, contentState) => {
    contentBlock.findEntityRanges((character) => {
        const entityKey = character.getEntity();
        return (
            entityKey !== null &&
            contentState.getEntity(entityKey).getType() === 'LINK'
        );
    }, callback);
};

export const Link = (props) => {
    const { contentState, entityKey } = props;
    const { url } = contentState.getEntity(entityKey).getData();
    return (
        <a
            className="link"
            href={url}
            rel="noopener noreferrer"
            target="_blank"
            aria-label={url}
        >
            {props.children}
        </a>
    );
};

const addLinkPluginPlugin = {
    keyBindingFn(event) {
        if (KeyBindingUtil.hasCommandModifier(event) && event.which === 75) {
            return 'add-link';
        }
        return null;
    },

    handleKeyCommand(command) {
        if (command !== 'add-link') {
            return 'not-handled';
        }
        return 'handled';
    },
    decorators: [
        {
            strategy: linkStrategy,
            component: Link,
        },
    ],
};

export default addLinkPluginPlugin;
