/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

export const PHOTO_TYPES = {
    COVER: 'coverPhoto',
    TRANSPORTS: 'transportsPhoto',
    OVERVIEW: 'overviewPhoto',
    EXPLORED: 'exploredPhoto',
    PROFILE: 'profilePhoto',
    STATION_LOGO: 'stationLogo',
    STATION_EXIT: 'stationExit',
    STATION_LINES: 'stationLines',
};
