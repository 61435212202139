/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

import React, { Component, Fragment } from 'react';
import { Button } from 'semantic-ui-react';
import Autosuggest from 'react-autosuggest';
import AutosuggestHighlightMatch from 'autosuggest-highlight/match';
import AutosuggestHighlightParse from 'autosuggest-highlight/parse';

import { getCountriesList } from '../../utils';

export class CountriesAutosuggest extends Component {
    static propTypes = {};
    static defaultProps = {};

    constructor(props) {
        super(props);

        const funcsToBind = [
            'onChange',
            'onSuggestionSelected',
            'onSuggestionsFetchRequested',
            'onSuggestionsClearRequested',
            'getSuggestionValue',
            'renderSuggestion',
            'clearSearch',
        ];
        funcsToBind.forEach((f) => {
            this[f] = this[f].bind(this);
        });

        this.state = {
            value: '',
            suggestions: [],
        };
    }

    onChange = (event, { newValue }) => {
        this.setState({
            ...this.state,
            value: newValue,
        });
    };

    onSuggestionsFetchRequested = async ({ value }) => {
        const suggestions = getCountriesList();

        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        this.setState({
            value,
            suggestions: inputLength === 0 ? [] : suggestions.filter(country => country.text.toLowerCase()
                .slice(0, inputLength) === inputValue),
        });
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: [],
        });
    };

    onSuggestionSelected(event, { suggestion }) {
        this.props.handleChange(null, { value: suggestion.value });
        this.setState({
            ...this.state,
            value: suggestion.text,
        });
    }

    getSuggestionValue(suggestion) {
        return `${suggestion.text}`;
    }

    clearSearch() {
        this.props.clearSearch();
        this.setState({
            value: '',
            suggestions: [],
        });
    }

    renderSuggestion(suggestion, { query }) {
        const suggestionText = suggestion.text;
        const matches = AutosuggestHighlightMatch(suggestionText, query);
        const parts = AutosuggestHighlightParse(suggestionText, matches);

        return (
            <span>
                {
                    parts.map((part, index) => {
                        const className = part.highlight ? 'highlight' : null;
                        return (
                            <span className={className} key={index}>{part.text}</span>
                        );
                    })
                }
            </span>
        );
    }

    render() {
        const { value, suggestions } = this.state;
        const inputProps = {
            placeholder: 'Search',
            value,
            onChange: this.onChange,
            onBlur: this.onBlur,
        };

        return (
            <Fragment>
                <Autosuggest
                    id="places_autosuggest"
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                    getSuggestionValue={this.getSuggestionValue}
                    renderSuggestion={this.renderSuggestion}
                    onSuggestionSelected={this.onSuggestionSelected}
                    inputProps={inputProps}
                />
                <Button
                    negative
                    icon="close"
                    size="small"
                    onClick={this.clearSearch}
                />
            </Fragment>
        );
    }
}

