/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

export const DEFAULT_MEDIA_TYPES = {
    CITY: 'defaultCityCover',
    FEED: 'defaultFeedCover',
    PLACE: 'defaultPlaceCover',
    USER: 'defaultUserCover',
};
