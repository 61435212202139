/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

import { REHYDRATE } from 'redux-persist';

import {
    CITY_EDITOR_REQUESTS_REQUEST, CITY_EDITOR_REQUESTS_RECEIVED, CITY_EDITOR_REQUESTS_REQUEST_ERROR,
    CITY_EDITOR_REQUESTS_GRANT_REQUEST, CITY_EDITOR_REQUESTS_GRANT_ERROR,
    CITY_EDITOR_REQUESTS_DENY_REQUEST, CITY_EDITOR_REQUESTS_DENY_REQUEST_ERROR,
} from '../actions/types';

const INITIAL_STATE = {
    cityEditorRequests: [],
    isFetching: false,
    fetchingMessage: '',
    paginationObj: null,
    formErrors: null,
};

export default (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case REHYDRATE:
            if (payload && payload.cityEditorRequests) {
                return {
                    ...payload.cityEditorRequests,
                    isFetching: false,
                    fetchingMessage: '',
                    formErrors: null,
                };
            }
            return state;
        case CITY_EDITOR_REQUESTS_REQUEST:
            return {
                ...state,
                isFetching: true,
                fetchingMessage: 'Fetching city editor requests...',
            };
        case CITY_EDITOR_REQUESTS_RECEIVED:
            return {
                ...state,
                ...payload,
                isFetching: false,
            };
        case CITY_EDITOR_REQUESTS_REQUEST_ERROR:
            return {
                ...state,
                isFetching: false,
            };
        case CITY_EDITOR_REQUESTS_GRANT_REQUEST:
            return {
                ...state,
                isFetching: true,
                fetchingMessage: 'Granting city editor request...',
            };
        case CITY_EDITOR_REQUESTS_GRANT_ERROR:
            return {
                ...state,
                isFetching: false,
            };
        case CITY_EDITOR_REQUESTS_DENY_REQUEST:
            return {
                ...state,
                isFetching: true,
                fetchingMessage: 'Denying city editor request...',
            };
        case CITY_EDITOR_REQUESTS_DENY_REQUEST_ERROR:
            return {
                ...state,
                isFetching: false,
            };
        default:
            return state;
    }
};
