/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

import moment from 'moment';

const NOT_BLANK = 'NotBlank';
const LENGTH = 'Length';
const NOT_EMPTY = 'NotEmpty';
const REGEX = 'Regex';
const NOT_BEFORE = 'NotBefore';
const NOT_AFTER = 'NotAfter';

// REGEX
const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const formValidationsMapper = {
    LOGIN_FORM: {
        username: {
            validations: [NOT_BLANK, REGEX],
            regex: emailRegex,
        },
        password: {
            validations: [NOT_BLANK],
        },
    },
    CREATE_COUNTRY_FORM: {
        countryCode: {
            validations: [NOT_BLANK],
        },
    },
    CREATE_CITY_FORM: {
        name: {
            validations: [NOT_BLANK],
        },
        mediaRaw: {
            validations: [NOT_BLANK, NOT_EMPTY],
        },
        captionDate: {
            validations: [NOT_BLANK, NOT_EMPTY],
        },
        userObject: {
            validations: [NOT_BLANK, NOT_EMPTY],
        },
        coverSequence: {
            validations: [NOT_BLANK, NOT_EMPTY],
        },
        exploredPhotoSequence: {
            validations: [NOT_BLANK, NOT_EMPTY],
        },
        country: {
            validations: [NOT_BLANK, NOT_EMPTY],
        },
    },
    CREATE_TAG_FORM: {
        name: {
            validations: [NOT_BLANK],
        },
        iconOn: {
            validations: [NOT_BLANK],
        },
        iconOff: {
            validations: [NOT_BLANK],
        },
    },
    CREATE_CATEGORY_FORM: {
        name: {
            validations: [NOT_BLANK],
        },
        iconOn: {
            validations: [NOT_BLANK],
        },
        iconOff: {
            validations: [NOT_BLANK],
        },
    },
    CREATE_PLACE_FORM: {
        name: {
            validations: [NOT_BLANK],
        },
        description: {
            validations: [NOT_BLANK],
        },
        citySequence: {
            validations: [NOT_BLANK],
        },
        mainCategorySequence: {
            validations: [NOT_BLANK],
        },
        mediaRaw: {
            validations: [NOT_BLANK, NOT_EMPTY],
        },
        captionDate: {
            validations: [NOT_BLANK, NOT_EMPTY],
        },
        userObject: {
            validations: [NOT_BLANK, NOT_EMPTY],
        },
        cover: {
            validations: [NOT_BLANK, NOT_EMPTY],
        },
        media: {
            validations: [NOT_BLANK, NOT_EMPTY],
        },
        listCovers: {
            validations: [NOT_BLANK, NOT_EMPTY],
        },
    },
    CREATE_STORY_FORM: {
        title: {
            validations: [NOT_BLANK],
        },
        storyTypeSequence: {
            validations: [NOT_BLANK],
        },
        userSequence: {
            validations: [NOT_BLANK],
        },
        photoSequence: {
            validations: [NOT_BLANK],
        },
        storyDate: {
            validations: [NOT_BLANK],
        },
        storyCards: {
            validations: [NOT_EMPTY],
        },
        mediaRaw: {
            validations: [NOT_BLANK, NOT_EMPTY],
        },
        captionDate: {
            validations: [NOT_BLANK, NOT_EMPTY],
        },
        userObject: {
            validations: [NOT_BLANK, NOT_EMPTY],
        },
    },
    CREATE_USER_FORM: {
        name: {
            validations: [NOT_BLANK],
        },
        username: {
            validations: [NOT_BLANK, REGEX],
            regex: emailRegex,
        },
        introduction: {
            validations: [NOT_BLANK],
        },
        mediaRaw: {
            validations: [NOT_BLANK, NOT_EMPTY],
        },
        captionDate: {
            validations: [NOT_BLANK, NOT_EMPTY],
        },
        userObject: {
            validations: [NOT_BLANK, NOT_EMPTY],
        },
        phone: {
            validations: [LENGTH],
            lengthLimit: [3, 254],
        },
        userCategory: {
            validations: [NOT_BLANK, LENGTH],
            lengthLimit: [1, 254],
        },
    },
    NO_CONTENT_FORM: {
        mediaRaw: {
            validations: [NOT_BLANK, NOT_EMPTY],
        },
        captionDate: {
            validations: [NOT_BLANK, NOT_EMPTY],
        },
        userObject: {
            validations: [NOT_BLANK, NOT_EMPTY],
        },
    },
};

export const validateTextInputField = (value, fieldName, displayName, formName) => {
    let error = null;

    if (!formValidationsMapper[formName][fieldName]) return error;

    const validationsToCheck = formValidationsMapper[formName][fieldName].validations;
    if (validationsToCheck.includes(NOT_BLANK) && (value === null || value.toString().trim() === '' || value.toString().trim() === '<p><br></p>')) {
        error = { message: ' can\'t be empty!' };
    } else if (validationsToCheck.includes(LENGTH)
        && value.length !== 0 && (value.length < formValidationsMapper[formName][fieldName].lengthLimit[0] ||
            value.length > formValidationsMapper[formName][fieldName].lengthLimit[1])) {
        const [lowerLimit, upperLimit] = formValidationsMapper[formName][fieldName].lengthLimit;
        error = { message: ` has to be between ${lowerLimit} and ${upperLimit} characters!` };
    } else if (validationsToCheck.includes(REGEX) && !formValidationsMapper[formName][fieldName].regex.exec(value)) {
        error = { message: ' format not valid!' };
    }

    if (validationsToCheck.includes(NOT_EMPTY) && Array.isArray(value) && value.length === 0) {
        error = {
            message: `${displayName} needs at least 1 selection`,
        };
    }

    return error;
};

export const validateNumberInputField = (value, fieldName, displayName, formName) => {
    let error = null;

    if (!formValidationsMapper[formName][fieldName]) return error;

    const numberRegex = /^[0-9]+[.]?[0-9]{0,2}$/;

    const validationsToCheck = formValidationsMapper[formName][fieldName].validations;
    if (validationsToCheck.includes(NOT_BLANK) && (value === null || value.toString().trim() === '')) {
        error = { message: ' can`t be empty!' };
    } else if (!numberRegex.test(value) && value !== null && value !== '') {
        error = { message: ' must be a number!' };
    }

    if (validationsToCheck.includes(NOT_EMPTY) && Array.isArray(value) && value.length === 0) {
        error = {
            message: `${displayName} needs at least 1 selection`,
        };
    }

    return error;
};

export const validateDateInputField = (value, fieldName, displayName, formName, otherDate, otherDateLabel) => {
    let error = null;

    if (!formValidationsMapper[formName][fieldName]) return error;

    const validationsToCheck = formValidationsMapper[formName][fieldName].validations;
    const valueMoment = moment(value);
    const otherMoment = moment(otherDate);
    if (value === null || (validationsToCheck.includes(NOT_BLANK) && value.toString().trim() === '')) {
        error = { message: 'can`t be empty!' };
    } else if (validationsToCheck.includes(NOT_BEFORE) && otherDate !== null && valueMoment.unix() <= otherMoment.unix()) {
        error = { message: `can't be before ${otherDateLabel}!` };
    } else if (validationsToCheck.includes(NOT_AFTER) && otherDate !== null && valueMoment.unix() >= otherMoment.unix()) {
        error = { message: `can't be after ${otherDateLabel}!` };
    }

    return error;
};

export const validateDropdownInputField = (value, fieldName, displayName, formName) => {
    let error = null;

    if (!formValidationsMapper[formName][fieldName]) return error;

    const validationsToCheck = formValidationsMapper[formName][fieldName].validations;
    if (value === null || (validationsToCheck.includes(NOT_BLANK) && value.toString().trim() === '')) {
        error = { message: 'can`t be empty!' };
    }

    return error;
};

export const validateImageField = (value, fieldName, displayName, formName) => {
    let error = null;

    if (!formValidationsMapper[formName][fieldName]) return error;

    const validationsToCheck = formValidationsMapper[formName][fieldName].validations;
    if (validationsToCheck.includes(NOT_BLANK) && (value === null || value === '')) {
        error = { message: 'Must upload an image!' };
    }

    return error;
};
