/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Menu, Image, Icon, Grid, Divider } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import withNavigate from '../hocs/with_navigate';
import withAuth from '../hocs/with_auth';
import {
    CITIES_ROUTE,
    COUNTRIES_ROUTE,
    TAGS_ROUTE,
    CATEGORIES_ROUTE,
    CATEGORIES_TREE_ROUTE,
    CITY_EDITOR_REQUESTS_ROUTE,
    PLACES_ROUTE,
    STORIES_ROUTE,
    USERS_ROUTE,
    USER_CATEGORIES_ROUTE,
    NO_CONTENT_ROUTE,
    STATION_ROUTE,
    STATIONS_DISABLED,
} from '../../constants';

import logo from '../../assets/img/logo.png';
import voidLogo from '../../assets/img/madeinvoid.png';

const SidebarBottomFooter = (
    <footer className="sidebar--footer">
        <Image
            centered
            src={voidLogo}
            as="a"
            href="http://void.pt"
            target="_blank"/>
    </footer>
);

class AppSidebarClass extends Component {
    static propTypes = {
        user: PropTypes.object.isRequired,
    };

    hasProfile(profile, secondProfile) {
        const { user } = this.props;
        if (!user) return false;
        const { profiles } = user.user;
        if (secondProfile) {
            return profiles.includes(profile) || profiles.includes(secondProfile);
        }
        return profiles.includes(profile);
    }

    render() {
        const { location } = this.props;
        const { pathname } = location;
        return (
            <div className="sidebar">
                <Grid.Row className="sidebar--logo-box">
                    <Image
                        centered
                        src={logo}
                        style={{ paddingTop: 15, width: 'auto', height: 70 }}/>
                </Grid.Row>
                <Grid.Row className="sidebar--menu-box">
                    <Menu fluid pointing secondary vertical size="huge" inverted style={{ border: 'none' }}>
                        <Menu.Item active={pathname.includes(COUNTRIES_ROUTE)}>
                            <Icon name="globe"/> <Link to={COUNTRIES_ROUTE} href={COUNTRIES_ROUTE}>Countries</Link>
                        </Menu.Item>
                        <Menu.Item active={pathname.includes(CITIES_ROUTE)}>
                            <Icon name="building"/> <Link to={CITIES_ROUTE} href={CITIES_ROUTE}>Cities</Link>
                        </Menu.Item>
                        <Divider/>
                        <Menu.Item active={pathname.includes(PLACES_ROUTE)}>
                            <Icon name="marker"/> <Link to={PLACES_ROUTE} href={PLACES_ROUTE}>Places</Link>
                        </Menu.Item>
                        <Menu.Item active={pathname.includes(CATEGORIES_ROUTE)}>
                            <Icon name="info circle"/> <Link to={CATEGORIES_ROUTE} href={CATEGORIES_ROUTE}>Place Categories</Link>
                        </Menu.Item>
                        {!STATIONS_DISABLED && (
                            <Menu.Item active={pathname.includes(STATION_ROUTE)}>
                                <Icon name="train"/> <Link to={STATION_ROUTE} href={STATION_ROUTE}>Stations</Link>
                            </Menu.Item>
                        )}
                        <Menu.Item active={pathname.includes(CATEGORIES_TREE_ROUTE)}>
                            <Icon name="list"/> <Link to={CATEGORIES_TREE_ROUTE} href={CATEGORIES_TREE_ROUTE}>Place Categories Tree</Link>
                        </Menu.Item>
                        <Menu.Item active={pathname.includes(STORIES_ROUTE)}>
                            <Icon name="book"/> <Link to={STORIES_ROUTE} href={STORIES_ROUTE}>Stories</Link>
                        </Menu.Item>
                        <Menu.Item active={pathname.includes(TAGS_ROUTE)}>
                            <Icon name="tags"/> <Link to={TAGS_ROUTE} href={TAGS_ROUTE}>Tags</Link>
                        </Menu.Item>
                        <Divider/>
                        <Menu.Item active={pathname.includes(USERS_ROUTE)}>
                            <Icon name="user"/>
                            <Link to={USERS_ROUTE} href={USERS_ROUTE}>
                                Users
                            </Link>
                        </Menu.Item>
                        <Menu.Item active={pathname.includes(USER_CATEGORIES_ROUTE)}>
                            <Icon name="info circle"/> <Link to={USER_CATEGORIES_ROUTE} href={USER_CATEGORIES_ROUTE}>User Categories</Link>
                        </Menu.Item>
                        <Menu.Item active={pathname.includes(CITY_EDITOR_REQUESTS_ROUTE)}>
                            <Icon name="user plus"/>
                            <Link to={CITY_EDITOR_REQUESTS_ROUTE} href={CITY_EDITOR_REQUESTS_ROUTE}>
                                City Editor Requests
                            </Link>
                        </Menu.Item>
                        <Menu.Item active={pathname.includes(NO_CONTENT_ROUTE)}>
                            <Icon name="ban"/>
                            <Link to={NO_CONTENT_ROUTE} href={NO_CONTENT_ROUTE}>
                               No Content
                            </Link>
                        </Menu.Item>
                    </Menu>
                </Grid.Row>
                <Grid.Row style={{ height: 75 }}>
                    {SidebarBottomFooter}
                </Grid.Row>
            </div>
        );
    }
}

export const AppSidebar = withAuth(withNavigate(AppSidebarClass));

