/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Icon, Image, Grid, Form, Dropdown } from 'semantic-ui-react';
import Dropzone from 'react-dropzone';
import { Document, Page, pdfjs } from 'react-pdf';
import PropTypes from 'prop-types';
import moment from 'moment';

import { CustomDatePicker } from './date_picker';
import { EditorArea } from './editor';
import { UsersAutosuggest } from './users_autosuggest';
import { getApiImage, getApiPdf, getUserDetails } from '../../utils';
import DefaultAvatarImg from '../../assets/img/avatar-default.png';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const INITIAL_STATE = {
    mediaRaw: null,
    mediaPreview: null,
    mediaMeta: {
        caption: null,
        captionUrl: null,
        captionDate: Date.now(),
        userObject: null,
    },
    lockMediaUpload: false,
};

class MediaUploaderClass extends Component {
    constructor(props) {
        super(props);
        const {
            initialData, mediaOnly,
            userName: sessionUserName,
            userSequence: sessionUserSequence,
            userCategory: sessionUserCategory,
            userProfilePhotoUrl: sessionUserProfilePhotoUrl,
        } = this.props;
        if (initialData) {
            const {
                caption,
                captionUrl,
                mediaType,
                captionDate,
                publicUrls: { mediaVideoUrl, mediaMediumUrl, mediaPdfUrl },
                userName,
                userSequence,
                userUserCategory,
                userProfilePhotoUrl,
            } = initialData;
            this.state = {
                ...INITIAL_STATE,
                mediaPreview: mediaMediumUrl ? getApiImage(mediaMediumUrl) : (getApiPdf(mediaPdfUrl) || null),
                mediaMeta: {
                    caption,
                    captionUrl: captionUrl && captionUrl !== 'null' ? captionUrl : null,
                    captionDate,
                    mediaType,
                    userObject: {
                        name: userName || sessionUserName,
                        sequence: userSequence || sessionUserSequence,
                        category: userUserCategory || sessionUserCategory,
                        profilePhoto: userProfilePhotoUrl || sessionUserProfilePhotoUrl,
                    },
                },
                lockMediaUpload: !mediaOnly,
            };
            this.getMediaUploaderName(sessionUserSequence);
            if (mediaVideoUrl) this.processVideoFileFromServer(getApiImage(mediaVideoUrl));
        } else {
            this.state = {
                ...INITIAL_STATE,
                mediaMeta: {
                    ...INITIAL_STATE.mediaMeta,
                },
            };
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const { initialData } = this.props;
        const {
            initialData: nextInitialData,
            mediaOnly, userName: sessionUserName,
            userSequence: sessionUserSequence,
            userCategory: sessionUserCategory,
            userProfilePhotoUrl: sessionUserProfilePhotoUrl,
        } = nextProps;
        if (nextInitialData && nextInitialData !== initialData) {
            const {
                caption, captionUrl, captionDate,
                publicUrls: { mediaVideoUrl, mediaMediumUrl, mediaPdfUrl },
                userName, userSequence,
                userUserCategory, userProfilePhotoUrl,
                mediaType,
            } = nextInitialData;
            this.setState({
                mediaPreview: mediaMediumUrl ? getApiImage(mediaMediumUrl) : (getApiPdf(mediaPdfUrl) || null),
                mediaMeta: {
                    caption,
                    captionUrl: captionUrl && captionUrl !== 'null' ? captionUrl : null,
                    captionDate,
                    mediaType,
                    userObject: {
                        name: userName || sessionUserName,
                        sequence: userSequence || sessionUserSequence,
                        category: userUserCategory || sessionUserCategory,
                        profilePhoto: userProfilePhotoUrl || sessionUserProfilePhotoUrl,
                    },
                },
                lockMediaUpload: !mediaOnly,
            }, () => {
                this.getMediaUploaderName(userSequence);
            });
            if (mediaVideoUrl) this.processVideoFileFromServer(getApiImage(mediaVideoUrl));
        }
    }

    componentWillUnmount() {
        const { mediaRaw } = this.state;
        if (mediaRaw) URL.revokeObjectURL(mediaRaw.preview);
    }

    onPhotoChange = ([file]) => {
        const { mediaOnly, onSavePress } = this.props;
        if (file) {
            const videoRegex = /video\/mp4$/;
            if (videoRegex.test(file.type)) this.processVideoFile(file);
            else this.processImageFile(file);
            if (mediaOnly) onSavePress(file);
        }
    };

    onRemovePhotoClick = (e) => {
        e.stopPropagation();
        const { mediaOnly, onRemoveMediaClick } = this.props;
        this.setState({ mediaRaw: null, mediaPreview: null });
        if (mediaOnly) onRemoveMediaClick();
    };

    onSavePhotoClick = () => {
        const { onSavePress } = this.props;
        const { lockMediaUpload } = this.state;
        onSavePress(this.state, lockMediaUpload);
    };

    onPhotoMetaChange = (field, value) => this.setState({ mediaMeta: { ...this.state.mediaMeta, [field]: value } });

    onEditMediaClick = () => {
        const { onEditMediaClick } = this.props;
        this.setState({ lockMediaUpload: true });
        onEditMediaClick();
    };

    onRemoveMediaClick = () => {
        const { onRemoveMediaClick } = this.props;
        this.setState({ ...INITIAL_STATE });
        onRemoveMediaClick();
    };

    onUserChange = (userObject) => {
        if (userObject) {
            this.setState({
                mediaMeta: {
                    ...this.state.mediaMeta,
                    userObject: {
                        name: userObject.name,
                        sequence: userObject.sequence,
                        category: userObject.userCategory,
                        profilePhoto: userObject.profilePhotoUrl,
                    },
                },
            });
        } else this.setState({ mediaMeta: { ...this.state.mediaMeta, userObject: null } });
    };

    getMediaUploaderName = (sequence) => {
        const { sessionUsername, token } = this.props;
        const { mediaMeta } = this.state;
        let userName = sessionUsername;

        getUserDetails(token, sequence).then((res) => {
            userName = res.data.name;

            this.setState({
                mediaMeta: {
                    ...mediaMeta,
                    userObject: {
                        ...mediaMeta.userObject,
                        name: userName,
                    },
                },
            });
        });
    }

    snapVideoImage = (file, url, videoEl) => {
        const canvas = document.createElement('canvas');
        canvas.width = videoEl.videoWidth;
        canvas.height = videoEl.videoHeight;
        canvas.getContext('2d').drawImage(videoEl, 0, 0, canvas.width, canvas.height);
        const image = canvas.toDataURL();
        const success = image.length > 100000;
        if (success) {
            this.setState({
                mediaRaw: file,
                mediaPreview: image,
            }, () => URL.revokeObjectURL(url));
        }
        return success;
    };

    snapVideoImageFromServer = (url, videoEl) => {
        const canvas = document.createElement('canvas');
        canvas.width = videoEl.videoWidth;
        canvas.height = videoEl.videoHeight;
        canvas.getContext('2d').drawImage(videoEl, 0, 0, canvas.width, canvas.height);
        const image = canvas.toDataURL();
        const success = image.length > 100000;
        if (success) this.setState({ mediaPreview: image });
        return success;
    };

    videoTimeupdate = (file, url, videoEl) => {
        if (this.snapVideoImage(file, url, videoEl)) {
            videoEl.removeEventListener('timeupdate', this.videoTimeupdate);
            videoEl.pause();
        }
    };

    videoTimeupdateFromServer = (url, videoEl) => {
        if (this.snapVideoImageFromServer(url, videoEl)) {
            videoEl.removeEventListener('timeupdate', this.videoTimeupdateFromServer);
            videoEl.pause();
        }
    };

    processVideoFileFromServer = (url) => {
        const videoEl = document.createElement('video');
        videoEl.addEventListener('loadeddata', () => {
            if (this.snapVideoImageFromServer(url, videoEl)) videoEl.removeEventListener('timeupdate', this.videoTimeupdateFromServer);
        });
        videoEl.addEventListener('timeupdate', () => this.videoTimeupdateFromServer(url, videoEl));
        videoEl.preload = 'metadata';
        videoEl.crossOrigin = 'anonymous';
        videoEl.src = url;
        videoEl.muted = true;
        videoEl.playsInline = true;
        videoEl.play();
    };

    processVideoFile = (file) => {
        const url = URL.createObjectURL(file);
        const videoEl = document.createElement('video');
        videoEl.addEventListener('loadeddata', () => {
            if (this.snapVideoImage(file, url, videoEl)) videoEl.removeEventListener('timeupdate', this.videoTimeupdate);
        });
        videoEl.addEventListener('timeupdate', () => this.videoTimeupdate(file, url, videoEl));
        videoEl.preload = 'metadata';
        videoEl.src = url;
        videoEl.muted = true;
        videoEl.playsInline = true;
        videoEl.play();
    };

    processImageFile = (file) => {
        this.setState({
            mediaRaw: file,
            mediaPreview: URL.createObjectURL(file),
        });
    };

    renderEditForm = () => {
        const { title, noBorders, options, selectedOption, onOptionChange } = this.props;
        const { mediaPreview, mediaMeta, mediaRaw } = this.state;
        const { caption, captionUrl, captionDate, userObject } = mediaMeta;
        let userName = null;
        let userCategory = null;
        let userProfilePhoto = null;
        if (userObject) ({ name: userName } = userObject);
        if (userObject && userObject.profilePhoto) ({ profilePhoto: userProfilePhoto } = userObject);
        if (userObject && userObject.category) ({ category: userCategory } = userObject);

        return (
            <Grid.Row className={`media-row ${noBorders ? 'borderless' : ''}`}>
                <Grid.Column width={16} className="media-row-action-container">
                    <h3 className="media-row-action-container-title">{title}</h3>
                    <div>
                        <Button
                            icon
                            color="red"
                            labelPosition="right"
                            className="media-row-action-container-action"
                            onClick={this.onRemoveMediaClick}
                        >
                            Remove <Icon name="trash"/>
                        </Button>
                        <Button
                            icon
                            labelPosition="right"
                            className="media-row-action-container-action"
                            onClick={this.onEditMediaClick}
                        >
                            Edit <Icon name="pencil"/>
                        </Button>
                    </div>
                </Grid.Column>
                <Grid.Column width={4}>
                    <div className="media-row-image-container">
                        {mediaPreview &&
                            (mediaMeta.mediaType === 'application/pdf' ||
                            (mediaRaw || {}).type === 'application/pdf') ? (
                                <Document
                                    className="uploader"
                                    file={mediaPreview}
                                    // onLoadSuccess={this.onDocumentLoadSuccess}
                                >
                                    <Page pageNumber={1} />
                                </Document>
                            ) : (
                                <Fragment>
                                    {mediaPreview ? (
                                        <img
                                            src={`${mediaPreview}${
                                                !mediaRaw ? `?d=${Date.now()}` : ''
                                            }`}
                                            alt={title}
                                        />
                                    ) : (
                                        <p className="media-row-image-container-hint">
                                            No media uploaded
                                        </p>
                                    )}
                                </Fragment>
                            )}
                    </div>
                </Grid.Column>
                <Grid.Column width={12}>
                    <div className="media-row-user-info-container">
                        <img
                            src={
                                userProfilePhoto
                                    ? getApiImage(userProfilePhoto)
                                    : DefaultAvatarImg
                            }
                            alt="user"
                        />
                        <h2>{userName}</h2>
                        <span>{userCategory ? userCategory.name : ''}</span>
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: caption }} />
                    <p className="url">{captionUrl || ''}</p>
                    <p className="date">
                        {captionDate && moment(captionDate).format('DD MMMM YYYY')}
                    </p>
                    {
                        options && (
                            <Dropdown
                                selection
                                onChange={(_, { value }) => onOptionChange(value)}
                                value={selectedOption}
                                placeholder="Select Transport"
                                options={options}
                            />
                        )
                    }
                </Grid.Column>
            </Grid.Row>
        );
    };

    renderCreateMediaUploader = () => {
        const { mediaPreview, lockMediaUpload, mediaRaw, mediaMeta } = this.state;
        const { title, formErrors, acceptedMimeTypes, mandatory } = this.props;
        if (lockMediaUpload) {
            return (
                <div className="media-row-image-container">
                    {mediaPreview &&
                        (mediaMeta.mediaType === 'application/pdf' ||
                        (mediaRaw || {}).type === 'application/pdf') ? (
                            <Document
                                className="uploader"
                                file={mediaPreview}
                            >
                                <Page pageNumber={1} />
                            </Document>
                        ) : (
                            <Fragment>
                                {mediaPreview ? (
                                    <img src={mediaPreview} alt={title} />
                                ) : (
                                    <p className="media-row-image-container-hint">
                                        No media uploaded
                                    </p>
                                )}
                            </Fragment>
                        )}


                </div>
            );
        }
        return (
            <Dropzone
                accept={acceptedMimeTypes}
                onDrop={this.onPhotoChange}
                multiple={false}
                className={`uploader ${
                    formErrors && !!formErrors.mediaRaw ? 'uploader-rejected' : ''
                }`}
                activeClassName="uploader-active"
                rejectClassName="uploader-rejected"
            >
                {!mediaPreview && (
                    <p>
                        {`Please drag and drop a file or click here to open file selection dialog${
                            mandatory ? ' (mandatory)' : ''
                        }.`}
                    </p>
                )}
                {mediaPreview && (
                    <Fragment>
                        <button
                            className="clear-action"
                            onClick={this.onRemovePhotoClick}
                        >
                            Clear
                        </button>
                        {(mediaRaw || {}).type === 'application/pdf' ? (
                            <Document
                                file={mediaPreview}
                                // onLoadSuccess={this.onDocumentLoadSuccess}
                            >
                                <Page pageNumber={1} />
                            </Document>
                        ) : (
                            <Image src={mediaPreview} />
                        )}
                    </Fragment>
                )}
            </Dropzone>
        );
    };

    renderCreateForm = () => {
        const { mediaMeta } = this.state;
        const { mediaOnly, title, saveButtonLabel, formErrors, token, noBorders, width, options, selectedOption, onOptionChange } = this.props;
        const { caption, captionUrl, captionDate, userObject } = mediaMeta;

        let userName = null;
        let userCategory = null;
        let userProfilePhoto = null;
        if (userObject) ({ name: userName } = userObject);
        if (userObject && userObject.profilePhoto) ({ profilePhoto: userProfilePhoto } = userObject);
        if (userObject && userObject.category) ({ category: userCategory } = userObject);

        return (
            <Grid.Row className={`media-row ${noBorders ? 'borderless' : ''}`}>
                <Grid.Column
                    width={16}
                    className="media-row-action-container"
                >
                    <h3 className="media-row-action-container-title">{title}</h3>
                </Grid.Column>
                <Grid.Column width={mediaOnly ? width || 8 : 4}>
                    {this.renderCreateMediaUploader()}
                </Grid.Column>
                {
                    options && (
                        <Grid.Row className={`media-row ${noBorders ? 'borderless' : ''}`}>
                            <Dropdown
                                selection
                                onChange={(_, { value }) => onOptionChange(value)}
                                value={selectedOption}
                                placeholder="Select Transport"
                                options={options}
                            />
                        </Grid.Row>
                    )
                }
                {
                    !mediaOnly && (
                        <Fragment>
                            <Grid.Column width={6}>
                                <Form.Input
                                    fluid
                                    placeholder="Enter Caption Date"
                                    label={`Caption Date * ${(formErrors && formErrors.captionDate && formErrors.captionDate.message) || ''}`}
                                    error={formErrors && !!formErrors.captionDate}
                                    name="captionDate"
                                    className="date-picker-container"
                                    input={
                                        <CustomDatePicker
                                            hasError={formErrors && !!formErrors.captionDate}
                                            placeholder="Caption Date"
                                            value={captionDate}
                                            handleChange={date => this.onPhotoMetaChange('captionDate', date)}
                                        />
                                    }
                                />
                                <div className={`field ${formErrors && formErrors.userObject ? 'error' : ''}`} style={{ marginTop: '20px', marginBottom: '20px' }}>
                                    <label>{`User * ${(formErrors && formErrors.userObject && formErrors.userObject.message) || ''}`}</label>
                                    <UsersAutosuggest
                                        token={token}
                                        hasError={formErrors && !!formErrors.userObject}
                                        initialPhoto={userProfilePhoto}
                                        initialCategory={userCategory}
                                        initialValue={userName}
                                        onUserSelected={this.onUserChange}
                                    />
                                </div>
                                <Form.Input
                                    fluid
                                    placeholder="Enter Caption URL"
                                    label={`Caption URL ${(formErrors && formErrors.captionUrl && formErrors.captionUrl.message) || ''}`}
                                    error={formErrors && !!formErrors.captionUrl}
                                    name="captionUrl"
                                    value={captionUrl || ''}
                                    onChange={(e, { value }) => this.onPhotoMetaChange('captionUrl', value)}
                                />
                            </Grid.Column>
                            <Grid.Column width={6}>
                                <div className={`field ${formErrors && !!formErrors.caption ? 'error' : ''}`}>
                                    <label>{`Caption ${(formErrors && formErrors.caption && formErrors.caption.message) || ''}`}</label>
                                    <EditorArea
                                        hasError={formErrors && !!formErrors.caption}
                                        initialContent={caption}
                                        onChange={captionHtml => this.onPhotoMetaChange('caption', captionHtml)}
                                    />
                                </div>
                            </Grid.Column>
                        </Fragment>
                    )
                }
                <Grid.Column
                    width={16}
                >
                    {
                        !mediaOnly && (
                            <Button
                                icon
                                color="green"
                                labelPosition="right"
                                onClick={this.onSavePhotoClick}
                                floated="right"
                            >
                                {saveButtonLabel} <Icon name="save"/>
                            </Button>
                        )
                    }
                </Grid.Column>
            </Grid.Row>
        );
    };

    render() {
        const { isEditing } = this.props;

        if (isEditing) return this.renderCreateForm();
        return this.renderEditForm();
    }
}

MediaUploaderClass.propTypes = {
    token: PropTypes.string,
    title: PropTypes.string,
    width: PropTypes.number,
    mediaOnly: PropTypes.bool,
    noBorders: PropTypes.bool,
    mandatory: PropTypes.bool,
    isEditing: PropTypes.bool,
    formErrors: PropTypes.object,
    initialData: PropTypes.object,
    userName: PropTypes.string,
    userSequence: PropTypes.number,
    userCategory: PropTypes.string,
    userProfilePhotoUrl: PropTypes.string,
    onSavePress: PropTypes.func,
    saveButtonLabel: PropTypes.string,
    onEditMediaClick: PropTypes.func,
    onRemoveMediaClick: PropTypes.func,
    acceptedMimeTypes: PropTypes.array,
    options: PropTypes.array,
    selectedOption: PropTypes.string,
    onOptionChange: PropTypes.func,
};

MediaUploaderClass.defaultProps = {
    token: null,
    title: null,
    width: null,
    mediaOnly: false,
    noBorders: false,
    mandatory: false,
    isEditing: true,
    formErrors: null,
    initialData: null,
    userName: null,
    userSequence: null,
    userCategory: null,
    userProfilePhotoUrl: null,
    onSavePress: () => {},
    saveButtonLabel: null,
    onEditMediaClick: () => {},
    onRemoveMediaClick: () => {},
    acceptedMimeTypes: ['image/png', 'image/jpeg', 'image/svg+xml'],
    options: null,
    selectedOption: '',
    onOptionChange: () => {},
};

const mapStateToProps = ({ session }) => {
    const { user: { user: { name, sequence, userCategory, profilePhotoUrl } } } = session;
    return {
        userName: name,
        userSequence: sequence,
        userCategory,
        userProfilePhotoUrl: profilePhotoUrl,
    };
};

export const MediaUploader = connect(mapStateToProps)(MediaUploaderClass);
