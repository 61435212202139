/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

import { toast } from 'react-toastify';

export const displayNotification = ({ type, message }) => {
    switch (type) {
        case 'success':
            toast.success(message);
            break;
        case 'error':
            toast.error(message);
            break;
        case 'case3':

            break;
        default:
            console.log(`Something went wrong evaluating ${type}.`);
    }
};
