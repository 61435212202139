/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { Message } from 'semantic-ui-react';
import PropTypes from 'prop-types';

export class FormErrorMessage extends Component {
    static propTypes = {
        icon: PropTypes.string,
        header: PropTypes.string,
        content: PropTypes.string,
        formErrors: PropTypes.object,
    };

    static defaultProps = {
        icon: 'warning sign',
        header: 'Error',
        content: 'The form contains errors. Please Review the fields!',
        formErrors: null,
    };

    render() {
        const { icon, header, content, formErrors } = this.props;

        if (!formErrors) return null;

        return (
            <Message
                style={{ textAlign: 'left' }}
                size="small"
                error
                icon={icon}
                header={header}
                content={(formErrors && formErrors.other && formErrors.other.message) || content}/>
        );
    }
}

