/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';

export const CustomGrid = (props) => {
    const { style, children } = props;

    return <Grid style={style}>{children}</Grid>;
};

CustomGrid.propTypes = { style: PropTypes.object };

CustomGrid.defaultProps = { style: { width: '100%', margin: 0 } };

export const CustomGridLeftColumn = (props) => {
    const { style, children } = props;

    return (
        <Grid.Column
            className="sidebar"
            only="tablet computer"
            tablet={4}
            computer={3}
            largeScreen={2}
            widescreen={2}
            style={style}>
            {children}
        </Grid.Column>
    );
};

CustomGridLeftColumn.propTypes = { style: PropTypes.object };

CustomGridLeftColumn.defaultProps = { style: { padding: 0, zIndex: 200 } };

export const CustomGridRightColumn = (props) => {
    const { style, children } = props;

    return (
        <Grid.Column
            width={16}
            style={style}>
            {children}
        </Grid.Column>
    );
};

CustomGridRightColumn.propTypes = { style: PropTypes.object };

CustomGridRightColumn.defaultProps = { style: { padding: 0, paddingTop: 85 } };

