/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

export const displayFieldsMap = {
    username: 'Username',
    password: 'Password',
};
