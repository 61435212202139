/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

import { REHYDRATE } from 'redux-persist';

import {
    CATEGORIES_REQUEST, CATEGORIES_RECEIVED, CATEGORIES_REQUEST_ERROR,
    CATEGORIES_CREATE_CATEGORY_REQUEST, CATEGORIES_CREATE_CATEGORY_REQUEST_ERROR,
    CATEGORIES_DELETE_CATEGORY_REQUEST, CATEGORIES_DELETE_CATEGORY_REQUEST_ERROR,
} from '../actions/types';

const INITIAL_STATE = {
    categories: [],
    isFetching: false,
    fetchingMessage: '',
    paginationObj: null,
    formErrors: null,
};

export default (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case REHYDRATE:
            if (payload && payload.categories) {
                return {
                    ...payload.categories,
                    isFetching: false,
                    fetchingMessage: '',
                    formErrors: null,
                };
            }
            return state;
        case CATEGORIES_REQUEST:
            return {
                ...state,
                isFetching: true,
                fetchingMessage: 'Fetching categories...',
            };
        case CATEGORIES_RECEIVED:
            return {
                ...state,
                ...payload,
                isFetching: false,
            };
        case CATEGORIES_REQUEST_ERROR:
            return {
                ...state,
                isFetching: false,
            };
        case CATEGORIES_CREATE_CATEGORY_REQUEST:
            return {
                ...state,
                isFetching: true,
                fetchingMessage: 'Saving category...',
            };
        case CATEGORIES_CREATE_CATEGORY_REQUEST_ERROR:
            return {
                ...state,
                isFetching: false,
            };
        case CATEGORIES_DELETE_CATEGORY_REQUEST:
            return {
                ...state,
                isFetching: true,
                fetchingMessage: 'Deleting category...',
            };
        case CATEGORIES_DELETE_CATEGORY_REQUEST_ERROR:
            return {
                ...state,
                isFetching: false,
            };
        default:
            return state;
    }
};
