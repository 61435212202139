/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

import { uniqueId, throttle } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import UploadMediaModal from '../modals/upload_media_modal';
import RemoveMediaModal from '../modals/remove_media_modal';
import { getApiImage, getMedia } from '../../utils';

const INITIAL_STATE = {
    mediaData: null,
};

export class MediaUploaderListItem extends Component {
    constructor(props) {
        super(props);
        this.throttledFetchMediaData = throttle(this.fetchMediaData, 500);
    }

    state = INITIAL_STATE;

    componentDidMount() {
        this.fetchMediaData();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const { updateMediaWithSequence, onMediaUpdated } = nextProps;
        let mediaDataSequence = null;
        const { mediaData } = this.state;
        if (mediaData) ({ sequence: mediaDataSequence } = mediaData);
        if (mediaDataSequence && updateMediaWithSequence && mediaDataSequence === updateMediaWithSequence) {
            this.fetchMediaData(onMediaUpdated);
        }
    }

    fetchMediaData = async (callback = null) => {
        const { token, mediaSequence } = this.props;
        const { data: mediaData } = await getMedia(token, mediaSequence);
        this.setState({ mediaData }, () => {
            if (callback && typeof callback === 'function') callback();
        });
    };

    render() {
        const {
            token, onSaveMediaPress, uploaderTitle,
            formErrors, uploaderSaveButtonLabel,
            clearFormErrors, onRemoveMediaPress,
            addMediaTitle,
        } = this.props;
        const { mediaData } = this.state;
        let mediaUrl = null;
        let mediaSequence = null;
        if (mediaData && mediaData.publicUrls) mediaUrl = getApiImage(mediaData.publicUrls.mediaMediumUrl);
        if (mediaData && mediaData.sequence) mediaSequence = mediaData.sequence;

        return (
            <div className="photo-grid-list-item">
                {
                    mediaUrl && (
                        <img
                            key={mediaSequence || uniqueId()}
                            src={`${mediaUrl}?d=${Date.now()}`}
                            alt="preview"
                            decoding="async"
                            onError={this.throttledFetchMediaData}
                        />
                    )
                }
                <div className="photo-grid-list-item-action-container">
                    <UploadMediaModal
                        media={mediaData}
                        token={token}
                        clearFormErrors={clearFormErrors}
                        formErrors={formErrors}
                        addMediaTitle={addMediaTitle}
                        uploaderTitle={uploaderTitle}
                        onSaveMediaPress={onSaveMediaPress}
                        uploaderSaveButtonLabel={uploaderSaveButtonLabel}
                    />
                    <RemoveMediaModal
                        media={mediaData}
                        onRemoveMediaPress={onRemoveMediaPress}
                    />
                </div>
            </div>
        );
    }
}

MediaUploaderListItem.propTypes = {
    token: PropTypes.string,
    formErrors: PropTypes.object,
    addMediaTitle: PropTypes.string,
    uploaderTitle: PropTypes.string,
    mediaSequence: PropTypes.number,
    onMediaUpdated: PropTypes.func,
    clearFormErrors: PropTypes.func,
    onSaveMediaPress: PropTypes.func,
    onRemoveMediaPress: PropTypes.func,
    updateMediaWithSequence: PropTypes.number,
    uploaderSaveButtonLabel: PropTypes.string,
};

MediaUploaderListItem.defaultProps = {
    token: null,
    formErrors: null,
    addMediaTitle: null,
    uploaderTitle: null,
    mediaSequence: null,
    onMediaUpdated: () => {},
    clearFormErrors: () => {},
    onSaveMediaPress: () => {},
    onRemoveMediaPress: () => {},
    updateMediaWithSequence: null,
    uploaderSaveButtonLabel: null,
};
