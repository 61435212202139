/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

import { get } from 'axios';

import { getRequestConfig } from './miscellaneous';
import { API_URL } from '../settings';

export const getAllCategories = async (token, isUserCategories = false) => {
    try {
        const { data } = await get(`${API_URL}/${isUserCategories ? 'userCategory' : 'category'}/all`, getRequestConfig(token));
        return data;
    } catch (error) {
        console.log('error ', error);
        return error;
    }
};

export const getAllTags = async (token) => {
    try {
        const { data } = await get(`${API_URL}/tag/all`, getRequestConfig(token));
        return data;
    } catch (error) {
        console.log('error ', error);
        return error;
    }
};

export const getAllCities = async (token) => {
    try {
        const { data } = await get(`${API_URL}/city/all`, getRequestConfig(token));
        return data;
    } catch (error) {
        console.log('error ', error);
        return error;
    }
};

export const getStoryTypes = async (token) => {
    try {
        const { data } = await get(`${API_URL}/storytype`, getRequestConfig(token));
        return data;
    } catch (error) {
        console.log('error ', error);
        return error;
    }
};

export const getPlaces = async (token, search) => {
    try {
        let url = `${API_URL}/place`;
        if (search && search.length > 0) {
            url = url.concat(`?name=${search}`);
        }
        const { data } = await get(url, { headers: { Authorization: token, 'max-results': 30 } });
        return data;
    } catch (error) {
        console.log('error ', error);
        return error;
    }
};

export const getStory = async (token, sequence) => {
    try {
        const { data } = await get(`${API_URL}/story/${sequence}`, getRequestConfig(token));
        return data;
    } catch (error) {
        console.log('error ', error);
        return error;
    }
};

export const getPlace = async (token, sequence) => {
    try {
        const { data } = await get(`${API_URL}/place/${sequence}`, getRequestConfig(token));
        return data;
    } catch (error) {
        console.log('error ', error);
        return error;
    }
};

export const getPlaceMedia = async (token, sequence) => {
    try {
        const { data } = await get(`${API_URL}/place/${sequence}/media`, getRequestConfig(token));
        return data;
    } catch (error) {
        console.log('error ', error);
        return error;
    }
};

export const getPlaceDetails = async (token, sequence) => {
    try {
        const { data } = await get(`${API_URL}/place/${sequence}/bo`, getRequestConfig(token));
        return data;
    } catch (error) {
        console.log('error ', error);
        return error;
    }
};

export const getUsers = (token, query) => get(`${API_URL}/user/avatar?query=${query}`, getRequestConfig(token));

export const getMedia = (token, sequence) => get(`${API_URL}/media/${sequence}`, getRequestConfig(token));

export const getUserDetails = (token, sequence) => get(`${API_URL}/user/${sequence}`, getRequestConfig(token));

export const getDefaultMedias = token => get(`${API_URL}/media/default-medias`, getRequestConfig(token));
