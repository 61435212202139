/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

import { REHYDRATE } from 'redux-persist';

import {
    USERS_REQUEST, USERS_RECEIVED, USERS_REQUEST_ERROR,
    USERS_CREATE_USER_REQUEST, USERS_CREATE_USER_REQUEST_ERROR,
    USERS_DELETE_USER_REQUEST, USERS_DELETE_USER_REQUEST_ERROR,
    USERS_RESET_USER_PASSWORD_REQUEST, USERS_RESET_USER_PASSWORD_REQUEST_ERROR,
} from '../actions/types';

const INITIAL_STATE = {
    users: [],
    isFetching: false,
    fetchingMessage: '',
    paginationObj: null,
    formErrors: null,
};

export default (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case REHYDRATE:
            if (payload && payload.tags) {
                return {
                    ...payload.users,
                    isFetching: false,
                    fetchingMessage: '',
                    formErrors: null,
                };
            }
            return state;
        case USERS_REQUEST:
            return {
                ...state,
                isFetching: true,
                fetchingMessage: 'Fetching users...',
            };
        case USERS_RECEIVED:
            return {
                ...state,
                ...payload,
                isFetching: false,
            };
        case USERS_REQUEST_ERROR:
            return {
                ...state,
                isFetching: false,
            };
        case USERS_CREATE_USER_REQUEST:
            return {
                ...state,
                isFetching: true,
                fetchingMessage: 'Saving user...',
            };
        case USERS_CREATE_USER_REQUEST_ERROR:
            return {
                ...state,
                isFetching: false,
            };
        case USERS_DELETE_USER_REQUEST:
            return {
                ...state,
                isFetching: true,
                fetchingMessage: 'Deleting user...',
            };
        case USERS_DELETE_USER_REQUEST_ERROR:
            return {
                ...state,
                isFetching: false,
            };
        case USERS_RESET_USER_PASSWORD_REQUEST:
            return {
                ...state,
                isFetching: true,
                fetchingMessage: 'Resetting user password...',
            };
        case USERS_RESET_USER_PASSWORD_REQUEST_ERROR:
            return {
                ...state,
                isFetching: false,
            };
        default:
            return state;
    }
};
