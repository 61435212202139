/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

import axios from 'axios';

import {
    PLACES_REQUEST,
    PLACES_RECEIVED,
    PLACES_REQUEST_ERROR,
    PLACES_CREATE_PLACE_REQUEST,
    PLACES_CREATE_PLACE_REQUEST_ERROR,
    PLACES_DELETE_PLACE_REQUEST,
    PLACES_DELETE_PLACE_REQUEST_ERROR,
} from '../actions/types';


import { displayNotification, getRequestConfig, handleCustomErrors, handleServerErrors } from '../utils';

import { API_URL } from '../settings';

// LIST PLACES
const requestPlaces = () => ({
    type: PLACES_REQUEST,
});

const receivePlaces = (places, maxResults, selectedPage, totalResults) => ({
    type: PLACES_RECEIVED,
    payload: {
        places,
        paginationObj: {
            maxResults,
            selectedPage,
            totalResults,
        },
    },
});

const requestPlacesError = () => ({
    type: PLACES_REQUEST_ERROR,
});

// CREATE PLACE
const requestPlacesCreatePlace = () => ({
    type: PLACES_CREATE_PLACE_REQUEST,
});

const requestPlacesCreatePlaceError = () => ({
    type: PLACES_CREATE_PLACE_REQUEST_ERROR,
});

// DELETE PLACE
const requestPlacesDeletePlace = () => ({
    type: PLACES_DELETE_PLACE_REQUEST,
});

const requestPlacesDeletePlaceError = () => ({
    type: PLACES_DELETE_PLACE_REQUEST_ERROR,
});

// UI ACTIONS

export const fetchPlaces = (token, selectedPage = 1, searchObj = {}, type = 'GENERAL') => {
    return async (dispatch) => {
        dispatch(requestPlaces());
        try {
            let url = `${API_URL}/place`;

            let filtersNum = 0;

            if (searchObj.countrySearch && searchObj.countrySearch.length > 0) {
                url = url.concat(`?country=${searchObj.countrySearch}`);
                filtersNum++;
            }

            if (searchObj.categoriesSearch && searchObj.categoriesSearch.length > 0) {
                let prefix = '?';
                if (filtersNum > 0) {
                    prefix = '&';
                }
                url = url.concat(`${prefix}categoriesNumber=${searchObj.categoriesSearch}`);
                filtersNum++;
            }

            if (searchObj.citySearch && searchObj.citySearch.length > 0) {
                let prefix = '?';
                if (filtersNum > 0) {
                    prefix = '&';
                }
                url = url.concat(`${prefix}city=${searchObj.citySearch}`);
                filtersNum++;
            }

            if (searchObj.mainCategorySearch && searchObj.mainCategorySearch.length > 0) {
                let prefix = '?';
                if (filtersNum > 0) {
                    prefix = '&';
                }
                url = url.concat(`${prefix}mainPlaceCategoryName=${searchObj.mainCategorySearch}`);
                filtersNum++;
            }

            if (searchObj.titleSearch && searchObj.titleSearch.length > 0) {
                let prefix = '?';
                if (filtersNum > 0) {
                    prefix = '&';
                }
                url = url.concat(`${prefix}title=${searchObj.titleSearch}`);
                filtersNum++;
            }

            if (searchObj.photosSearch && searchObj.photosSearch.length > 0) {
                let prefix = '?';
                if (filtersNum > 0) {
                    prefix = '&';
                }
                url = url.concat(`${prefix}photosNumber=${searchObj.photosSearch}`);
                filtersNum++;
            }

            if (searchObj.tagsSearch && searchObj.tagsSearch.length > 0) {
                let prefix = '?';
                if (filtersNum > 0) {
                    prefix = '&';
                }
                url = url.concat(`${prefix}tagsNumber=${searchObj.tagsSearch}`);
                filtersNum++;
            }

            if (searchObj.sortColumn && searchObj.sortColumn.length > 0) {
                let prefix = '?';
                if (filtersNum > 0) {
                    prefix = '&';
                }
                url = url.concat(`${prefix}sort=${searchObj.sortColumn}&sortAsc=${searchObj.sortDirection === 'ascending'}`);
                filtersNum++;
            }

            let prefix = '?';
            if (filtersNum > 0) {
                prefix = '&';
            }

            url = url.concat(`${prefix}type=${type}`);

            const { data, headers } = await axios.get(
                url,
                getRequestConfig(token, selectedPage),
            );
            dispatch(receivePlaces(
                data,
                headers['max-results'], headers['selected-page'], headers['total-results'],
            ));
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.other && error.response.data.other.errorCode) {
                handleCustomErrors(error.response.data.other.errorCode);
            } else {
                handleServerErrors(error, dispatch, requestPlacesError);
            }
        }
    };
};

export const createPlace = (token, place, modalClose, isStation) => {
    return async (dispatch) => {
        dispatch(requestPlacesCreatePlace());
        try {
            const parsedPlace = {
                ...place,
                instagramHashTags: place.instagramHashTags ? place.instagramHashTags.split(',')
                    .map(item => item.trim()) : null,
                twitterHashTags: place.twitterHashTags ? place.twitterHashTags.split(',')
                    .map(item => item.trim()) : null,
            };

            await axios.post(`${API_URL}/place${isStation ? '/station' : ''}`, parsedPlace, getRequestConfig(token, null, false));
            if (isStation) {
                dispatch(fetchPlaces(token, 0, {}, 'STATION'));
            } else {
                dispatch(fetchPlaces(token));
            }
            displayNotification({ type: 'success', message: 'Place created!' });
            modalClose();
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.other && error.response.data.other.errorCode) {
                handleCustomErrors(error.response.data.other.errorCode);
            } else {
                handleServerErrors(error, dispatch, requestPlacesCreatePlaceError);
            }
        }
    };
};

export const updatePlace = (token, place, modalClose, isStation) => {
    return async (dispatch) => {
        dispatch(requestPlacesCreatePlace());
        try {
            const parsedPlace = {
                ...place,
                instagramHashTags: place.instagramHashTags ? place.instagramHashTags.split(',')
                    .map(item => item.trim()) : null,
                twitterHashTags: place.twitterHashTags ? place.twitterHashTags.split(',')
                    .map(item => item.trim()) : null,
            };

            await axios.put(`${API_URL}/place/${isStation ? 'station/' : ''}${place.sequence}`, parsedPlace, getRequestConfig(token, null, false));
            if (isStation) {
                dispatch(fetchPlaces(token, 0, {}, 'STATION'));
            } else {
                dispatch(fetchPlaces(token));
            }
            displayNotification({ type: 'success', message: 'Place updated!' });
            modalClose();
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.other && error.response.data.other.errorCode) {
                handleCustomErrors(error.response.data.other.errorCode);
            } else {
                handleServerErrors(error, dispatch, requestPlacesCreatePlaceError);
            }
        }
    };
};

export const deletePlace = (token, sequence, selectedPage, filtersObj = {}, isStation) => {
    return async (dispatch) => {
        try {
            dispatch(requestPlacesDeletePlace());
            await axios.delete(`${API_URL}/place/${sequence}`, getRequestConfig(token, selectedPage));
            if (isStation) {
                dispatch(fetchPlaces(token, selectedPage, filtersObj, 'STATION'));
            } else {
                dispatch(fetchPlaces(token, selectedPage, filtersObj));
            }
            displayNotification({ type: 'success', message: 'Place deleted!' });
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.other && error.response.data.other.errorCode) {
                handleCustomErrors(error.response.data.other.errorCode);
            } else {
                handleServerErrors(error, dispatch, requestPlacesDeletePlaceError);
            }
        }
    };
};
