/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { Button, Header, Modal, Popup } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import withModal from '../hocs/with_modal';

import { RowActionButton } from '../elements';

const initialState = { modalOpen: false };

class ModalDeleteConfirmationModal extends Component {
    static propTypes = {
        cb: PropTypes.func.isRequired,
        sequence: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,
        icon: PropTypes.string,
        tooltip: PropTypes.string,
        disabled: PropTypes.bool,
        color: PropTypes.string,
    };

    static defaultProps = {
        icon: 'delete',
        tooltip: 'delete',
        disabled: false,
        color: 'red',
    };

    constructor(props) {
        super(props);
        const funcsToBind = [
            'handleOpen',
            'handleCancel',
            'handleSubmit',
        ];
        funcsToBind.forEach((f) => {
            this[f] = this[f].bind(this);
        });

        this.state = initialState;
    }

    handleOpen() {
        this.setState({ modalOpen: true });
    }

    handleCancel() {
        this.setState(initialState);
    }

    handleSubmit() {
        this.props.cb(this.props.sequence);
        this.setState({ modalOpen: false });
    }

    render() {
        const { title, content, icon, tooltip, color } = this.props;

        return (
            <Modal
                onUnmount={this.props.fixBody}
                open={this.state.modalOpen}
                onClose={this.handleClose}
                size="tiny"
                trigger={
                    <Popup
                        trigger={<RowActionButton
                            disabled={this.props.disabled}
                            text={title}
                            icon={icon}
                            onClick={this.handleOpen}
                            color={color}/>}
                        flowing
                        hoverable
                        inverted
                        content={tooltip}
                        position="top center"
                    />}>
                <Header icon={icon} content={title}/>
                <Modal.Content>
                    <h4>{content}</h4>
                    <p>This cannot be undone!</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button.Group>
                        <Button negative content="Cancel" onClick={this.handleCancel}/>
                        <Button.Or/>
                        <Button positive icon="checkmark" labelPosition="right" content={title} onClick={this.handleSubmit}/>
                    </Button.Group>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default withModal(ModalDeleteConfirmationModal);
