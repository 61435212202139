/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

import React, { Component, Fragment } from 'react';
import { Grid, Table, Pagination, Responsive } from 'semantic-ui-react';
import PropTypes from 'prop-types';

export class TablePaginationFooter extends Component {
    static propTypes = {
        paginationObj: PropTypes.object,
        handleChangePage: PropTypes.func.isRequired,
        colSpan: PropTypes.number,
        searchObj: PropTypes.object,
    };

    static defaultProps = {
        paginationObj: null,
        colSpan: 4,
        searchObj: {},
    };

    constructor(props) {
        super(props);
        this.handlePaginationChange = this.handlePaginationChange.bind(this);
    }

    handlePaginationChange(e, { activePage }) {
        const { searchObj } = this.props;
        this.props.handleChangePage(activePage, searchObj);
    }

    render() {
        const { paginationObj, colSpan } = this.props;

        if (!paginationObj) return null;
        const { maxResults, selectedPage, totalResults } = paginationObj;
        const pages = (Math.ceil(totalResults / maxResults));
        const resultsString = ` Page ${selectedPage} of ${pages} (${totalResults} total results)`;

        return (
            <Table.Footer fullWidth>
                <Table.Row>
                    <Table.HeaderCell colSpan={colSpan}>
                        <Grid id="pagination-grid" stackable>
                            <Grid.Column tablet={10} computer={12} largeScreen={9} widescreen={9}>
                                <span style={{ paddingTop: 15, display: 'inline-block' }}>{resultsString}</span>
                            </Grid.Column>
                            {totalResults > maxResults &&
                            <Fragment>
                                <Responsive
                                    as={Grid.Column}
                                    minWidth={Responsive.onlyMobile.maxWidth}
                                    tablet={6}
                                    computer={4}
                                    largeScreen={7}
                                    widescreen={7}
                                    style={{ textAlign: 'right' }}>
                                    <Pagination
                                        onPageChange={this.handlePaginationChange}
                                        boundaryRange={1}
                                        activePage={selectedPage}
                                        totalPages={pages}
                                        firstItem={pages > 2 ? undefined : null}
                                        lastItem={pages > 2 ? undefined : null}
                                        prevItem={pages > 2 ? undefined : null}
                                        nextItem={pages > 2 ? undefined : null}/>
                                </Responsive>
                                <Responsive
                                    as={Grid.Column}
                                    {...Responsive.onlyMobile}
                                    style={{ textAlign: 'center' }}>
                                    <Pagination
                                        pointing
                                        secondary
                                        onPageChange={this.handlePaginationChange}
                                        siblingRange={0}
                                        activePage={selectedPage}
                                        totalPages={pages}
                                        firstItem={null}
                                        lastItem={null}/>
                                </Responsive>
                            </Fragment>
                            }
                        </Grid>
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Footer>
        );
    }
}

