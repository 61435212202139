/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Accordion, Table } from 'semantic-ui-react';
import { throttle } from 'lodash';

// ELEMENTS
import { TablePaginationFooter, TableHeader, SearchBar } from '..';
// HOCS
import withPagination from '../../hocs/with_pagination';
// ACTIONS
import { fetchCategories, deleteCategory } from '../../../actions';
// MODALS
import CreateUserCategoryModal from '../../modals/create_user_category_modal';
import DeleteConfirmationModal from '../../modals/general_delete_confirmation_modal';

class UserCategoriesTableClass extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nameSearch: '',
            parentNameSearch: '',
            childrenNumberSearch: '',
            sortColumn: '',
            sortDirection: '',
            searchOpen: false,
        };
        this.throttledFetch = throttle(props.fetchListData, 500);
    }

    getRows = () => {
        const { data } = this.props;
        if (data.length === 0) return (<Table.Row key="no_results"><Table.Cell>No results</Table.Cell></Table.Row>);

        return data.map((category) => {
            const { sequence, name, childrenNumber, parentName } = category;

            return (
                <Table.Row key={sequence}>
                    <Table.Cell>{parentName}</Table.Cell>
                    <Table.Cell>{name}</Table.Cell>
                    <Table.Cell>{childrenNumber}</Table.Cell>
                    <Table.Cell textAlign="right">
                        <CreateUserCategoryModal category={category}/>
                        {name !== 'Explorer' && <DeleteConfirmationModal
                            title="Delete Category"
                            content={`Are you sure you want to delete ${name}?`}
                            icon="trash outline"
                            tooltip="Delete Category"
                            sequence={sequence}
                            cb={this.deleteCategory}/>}
                    </Table.Cell>
                </Table.Row>
            );
        });
    };

    deleteCategory = (tagSequence) => {
        const { token, paginationObj } = this.props;
        const { nameSearch, parentNameSearch, childrenNumberSearch, sortColumn, sortDirection } = this.state;
        this.props.deleteCategory(
            token,
            tagSequence,
            paginationObj.selectedPage,
            { nameSearch, parentNameSearch, childrenNumberSearch, sortColumn, sortDirection },
            true,
        );
    };

    handleSearchName = (event, { value }) => {
        const { token } = this.props;
        const { parentNameSearch, childrenNumberSearch, sortColumn, sortDirection } = this.state;
        this.setState({
            ...this.state,
            nameSearch: value,
        });
        this.throttledFetch(token, 1, { nameSearch: value, parentNameSearch, childrenNumberSearch, sortColumn, sortDirection }, true);
    };

    clearSearchName = () => {
        this.handleSearchName(null, { value: '' });
    };

    handleSearchParentName = (event, { value }) => {
        const { token } = this.props;
        const { nameSearch, childrenNumberSearch, sortColumn, sortDirection } = this.state;
        this.setState({
            ...this.state,
            parentNameSearch: value,
        });
        this.throttledFetch(token, 1, { nameSearch, parentNameSearch: value, childrenNumberSearch, sortColumn, sortDirection }, true);
    };

    clearSearchParentName = () => {
        this.handleSearchParentName(null, { value: '' });
    };

    handleSearchChildrenNumber = (event, { value }) => {
        const { token } = this.props;
        const { nameSearch, parentNameSearch, sortColumn, sortDirection } = this.state;
        this.setState({
            ...this.state,
            childrenNumberSearch: value,
        });
        this.throttledFetch(token, 1, { nameSearch, parentNameSearch, childrenNumberSearch: value, sortColumn, sortDirection }, true);
    };

    clearSearchChildrenNumber = () => {
        this.handleSearchChildrenNumber(null, { value: '' });
    };

    handleSort = (clickedColumn) => {
        const { sortColumn, sortDirection, nameSearch } = this.state;
        const { paginationObj, token } = this.props;

        let newColumn = clickedColumn;
        let newDirection = 'ascending';

        if (sortColumn === newColumn) {
            if (sortDirection === 'ascending') {
                newDirection = 'descending';
            } else {
                newColumn = null;
                newDirection = null;
            }
        }

        this.setState({
            sortColumn: newColumn,
            sortDirection: newDirection,
        });

        this.props.fetchListData(
            token,
            paginationObj.selectedPage,
            { sortColumn: newColumn, sortDirection: newDirection, nameSearch },
            true,
        );
    };

    render() {
        const { paginationObj, paginateChangePage } = this.props;
        const { nameSearch, parentNameSearch, childrenNumberSearch, sortColumn, sortDirection, searchOpen } = this.state;
        if (paginationObj === null) return null;

        return (
            <div>
                <TableHeader title="User Categories" icon="info circle" content="Manage Categories"/>
                <div>
                    <CreateUserCategoryModal/>
                    <br/><br/>
                    <Accordion styled style={{ width: '100%' }}>
                        <Accordion.Title
                            active={searchOpen}
                            onClick={() => this.setState({ ...this.state, searchOpen: !searchOpen })}>
                            Search
                        </Accordion.Title>
                        <Accordion.Content active={searchOpen}>
                            <div style={{ width: '300px', display: 'inline-block' }}>
                                Parent<br/>
                                <SearchBar
                                    searchFunc={this.handleSearchParentName}
                                    clearSearchFunc={this.clearSearchParentName}
                                    value={parentNameSearch}/>
                            </div>
                            <div style={{ width: '300px', display: 'inline-block' }}>
                                Name<br/>
                                <SearchBar
                                    searchFunc={this.handleSearchName}
                                    clearSearchFunc={this.clearSearchName}
                                    value={nameSearch}/>
                            </div>
                            <div style={{ width: '300px', display: 'inline-block' }}>
                                Number of Children<br/>
                                <SearchBar
                                    searchFunc={this.handleSearchChildrenNumber}
                                    clearSearchFunc={this.clearSearchChildrenNumber}
                                    value={childrenNumberSearch}
                                    numeric/>
                            </div>
                        </Accordion.Content>
                    </Accordion>
                    <Table striped sortable fixed singleLine>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell
                                    width={6}
                                    sorted={sortColumn === 'PARENT_NAME' ? sortDirection : null}
                                    onClick={() => { this.handleSort('PARENT_NAME'); }}>
                                    Parent
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    width={6}
                                    sorted={sortColumn === 'NAME' ? sortDirection : null}
                                    onClick={() => { this.handleSort('NAME'); }}>
                                    Name
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    width={2}
                                    sorted={sortColumn === 'CHILDREN_NUMBER' ? sortDirection : null}
                                    onClick={() => { this.handleSort('CHILDREN_NUMBER'); }}>
                                    Children Number
                                </Table.HeaderCell>
                                <Table.HeaderCell width={2}>
                                    Options
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {this.getRows()}
                        </Table.Body>
                        <TablePaginationFooter
                            paginationObj={paginationObj}
                            colSpan={10}
                            handleChangePage={paginateChangePage}
                            searchObj={this.state}
                        />
                    </Table>
                </div>
            </div>
        );
    }
}

function mapStateToProps({ auth, categories }) {
    const { token } = auth;
    const { categories: data, paginationObj } = categories;

    return {
        token,
        data,
        paginationObj,
        type: true,
    };
}

export const UserCategoriesTable = connect(mapStateToProps, {
    fetchListData: fetchCategories,
    deleteCategory,
})(withPagination(UserCategoriesTableClass));

