/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/es/integration/react';

import Router from './components/router';

import { configureStore } from './configure_store';

const { persistor, store } = configureStore();

class App extends Component {
    render() {
        return (
            <PersistGate
                persistor={persistor}>
                <Provider store={store}>
                    <Router/>
                </Provider>
            </PersistGate>
        );
    }
}

export default App;
