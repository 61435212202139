/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Accordion, Image, Table } from 'semantic-ui-react';

// ELEMENTS
import { TablePaginationFooter, TableHeader, SearchBar } from '../../elements';
// HOCS
import withPagination from '../../hocs/with_pagination';
// ACTIONS
import { fetchTags, deleteTag } from '../../../actions';
// MODALS
import CreateTagModal from '../../modals/create_tag_modal';
import DeleteConfirmationModal from '../../modals/general_delete_confirmation_modal';
import { getApiImage } from '../../../utils';

class TagsTableClass extends Component {
    state = {
        nameSearch: '',
        sortColumn: '',
        sortDirection: '',
        searchOpen: false,
    };

    getRows = () => {
        const { data } = this.props;
        if (data.length === 0) return (<Table.Row key="no_results"><Table.Cell>No results</Table.Cell></Table.Row>);

        return data.map((tag) => {
            const { sequence, name, iconOnURL, iconOffURL } = tag;

            return (
                <Table.Row key={sequence}>
                    <Table.Cell>
                        <Image src={getApiImage(iconOnURL)} style={{ width: '35px', height: '35px' }}/>
                    </Table.Cell>
                    <Table.Cell>
                        <Image src={getApiImage(iconOffURL)} style={{ width: '35px', height: '35px' }}/>
                    </Table.Cell>
                    <Table.Cell>{name}</Table.Cell>
                    <Table.Cell textAlign="right">
                        <CreateTagModal tag={tag}/>
                        <DeleteConfirmationModal
                            title="Delete Tag"
                            content={`Are you sure you want to delete ${name}?`}
                            icon="trash outline"
                            tooltip="Delete Tag"
                            sequence={sequence}
                            cb={this.deleteTag}/>
                    </Table.Cell>
                </Table.Row>
            );
        });
    };

    deleteTag = (tagSequence) => {
        const { token, paginationObj } = this.props;
        const { nameSearch, sortColumn, sortDirection } = this.state;
        this.props.deleteTag(token, tagSequence, paginationObj.selectedPage, { nameSearch, sortColumn, sortDirection });
    };

    handleSearchName = (event, { value }) => {
        const { token } = this.props;
        const { sortColumn, sortDirection } = this.state;
        clearTimeout(this.timeout);
        this.setState({
            ...this.state,
            nameSearch: value,
        });
        this.timeout = setTimeout(() => {
            this.props.fetchListData(token, 1, { nameSearch: value, sortColumn, sortDirection });
        }, 500);
    };

    clearSearchName = () => {
        this.handleSearchName(null, { value: '' });
    };

    handleSort = (clickedColumn) => {
        const { sortColumn, sortDirection, nameSearch } = this.state;
        const { paginationObj, token } = this.props;

        let newColumn = clickedColumn;
        let newDirection = 'ascending';

        if (sortColumn === newColumn) {
            if (sortDirection === 'ascending') {
                newDirection = 'descending';
            } else {
                newColumn = null;
                newDirection = null;
            }
        }

        this.setState({
            sortColumn: newColumn,
            sortDirection: newDirection,
        });

        this.props.fetchListData(
            token,
            paginationObj.selectedPage,
            { sortColumn: newColumn, sortDirection: newDirection, nameSearch },
        );
    };

    render() {
        const { paginationObj, paginateChangePage } = this.props;
        const { nameSearch, sortColumn, sortDirection, searchOpen } = this.state;
        if (paginationObj === null) return null;

        return (
            <div>
                <TableHeader title="Tags" icon="tags" content="Manage Tags"/>
                <div>
                    <CreateTagModal/>
                    <br/><br/>
                    <Accordion styled style={{ width: '100%' }}>
                        <Accordion.Title
                            active={searchOpen}
                            onClick={() => this.setState({ ...this.state, searchOpen: !searchOpen })}>
                            Search
                        </Accordion.Title>
                        <Accordion.Content active={searchOpen}>
                            <div style={{ width: '300px', display: 'inline-block' }}>
                                Name<br/>
                                <SearchBar
                                    searchFunc={this.handleSearchName}
                                    clearSearchFunc={this.clearSearchName}
                                    value={nameSearch}/>
                            </div>
                        </Accordion.Content>
                    </Accordion>
                    <Table striped sortable fixed singleLine>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell width={3}>
                                    Icon On
                                </Table.HeaderCell>
                                <Table.HeaderCell width={3}>
                                    Icon Off
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    width={8}
                                    sorted={sortColumn === 'NAME' ? sortDirection : null}
                                    onClick={() => { this.handleSort('NAME'); }}>
                                    Name
                                </Table.HeaderCell>
                                <Table.HeaderCell width={2}>
                                    Options
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {this.getRows()}
                        </Table.Body>
                        <TablePaginationFooter
                            paginationObj={paginationObj}
                            colSpan={10}
                            handleChangePage={paginateChangePage}
                            searchObj={this.state}
                        />
                    </Table>
                </div>
            </div>
        );
    }
}

function mapStateToProps({ auth, tags }) {
    const { token } = auth;
    const { tags: data, paginationObj } = tags;

    return {
        token,
        data,
        paginationObj,
    };
}

export const TagsTable = connect(mapStateToProps, {
    fetchListData: fetchTags,
    deleteTag,
})(withPagination(TagsTableClass));

