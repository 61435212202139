/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Icon, Form } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { RowActionButton } from '../elements';

// HOCS
import withModal from '../hocs/with_modal';

const INITIAL_STATE = {
    modalOpen: false,
    formErrors: null,
    profiles: [],
};

class ChangeUserProfilesModal extends Component {
    static propTypes = {
        user: PropTypes.object.isRequired,
        cb: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        const funcsToBind = [
            'getTrigger',
            'handleOpen',
            'handleClose',
            'handleSubmit',
            'handleChange',
        ];
        funcsToBind.forEach((f) => {
            this[f] = this[f].bind(this);
        });
    }

    state = INITIAL_STATE;

    getTrigger() {
        return <RowActionButton text="Change Profiles" icon="user plus" color="teal" onClick={this.handleOpen}/>;
    }

    handleOpen() {
        this.setState({ ...INITIAL_STATE, profiles: this.props.user.profiles, modalOpen: true });
    }

    handleClose() {
        this.setState(INITIAL_STATE);
    }

    handleSubmit() {
        this.props.cb(this.props.user.sequence, this.state.profiles, this.handleClose);
    }

    handleChange(e, { value }) {
        this.setState({
            ...this.state,
            profiles: value,
        });
    }

    render() {
        const { modalOpen, profiles } = this.state;
        const { name } = this.props.user;

        if (!modalOpen) return this.getTrigger();

        const profileOptions = [
            { key: 'ARGO_ADMINISTRATOR', text: 'Administrator', value: 'ARGO_ADMINISTRATOR' },
            { key: 'ARGO_TEAM', text: 'Argo Team', value: 'ARGO_TEAM' },
            { key: 'CITY_EDITOR', text: 'City Editor', value: 'CITY_EDITOR' },
            { key: 'AVATAR', text: 'Avatar', value: 'AVATAR' },
        ];

        return (
            <Modal
                closeIcon
                className="change-user-profiles-modal"
                onClose={this.handleClose}
                onUnmount={this.props.fixBody}
                closeOnDimmerClick={false}
                closeOnEscape={false}
                open={modalOpen}
                size="mini"
                trigger={this.getTrigger()}>
                <Modal.Header>{`Change Profiles - ${name}`}</Modal.Header>
                <Modal.Content>
                    <Form.Dropdown
                        selection
                        multiple
                        fluid
                        label="Parent"
                        placeholder="Select Category"
                        value={profiles}
                        onChange={this.handleChange}
                        options={profileOptions} />
                </Modal.Content>
                <Modal.Actions>
                    <Button icon onClick={this.handleSubmit} labelPosition="right" color="green">Save<Icon name="save"/></Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

function mapStateToProps({ auth, tags }) {
    const { token } = auth;
    const { isFetching, formErrors } = tags;

    return {
        token,
        isFetching,
        formErrors,
    };
}

export default connect(mapStateToProps, {})(withModal(ChangeUserProfilesModal));
