/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

import { REHYDRATE } from 'redux-persist';

import {
    CITIES_REQUEST, CITIES_RECEIVED, CITIES_REQUEST_ERROR,
    CITIES_CREATE_CITY_REQUEST, CITIES_CREATE_CITY_REQUEST_ERROR,
    CITIES_DELETE_CITY_REQUEST, CITIES_DELETE_CITY_REQUEST_ERROR,
} from '../actions/types';

const INITIAL_STATE = {
    cities: [],
    isFetching: false,
    fetchingMessage: '',
    paginationObj: null,
    formErrors: null,
};

export default (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case REHYDRATE:
            if (payload && payload.cities) {
                return {
                    ...payload.cities,
                    isFetching: false,
                    fetchingMessage: '',
                    formErrors: null,
                };
            }
            return state;
        case CITIES_REQUEST:
            return {
                ...state,
                isFetching: true,
                fetchingMessage: 'Fetching cities...',
            };
        case CITIES_RECEIVED:
            return {
                ...state,
                ...payload,
                isFetching: false,
            };
        case CITIES_REQUEST_ERROR:
            return {
                ...state,
                isFetching: false,
            };
        case CITIES_CREATE_CITY_REQUEST:
            return {
                ...state,
                isFetching: true,
                fetchingMessage: 'Saving city...',
            };
        case CITIES_CREATE_CITY_REQUEST_ERROR:
            return {
                ...state,
                isFetching: false,
            };
        case CITIES_DELETE_CITY_REQUEST:
            return {
                ...state,
                isFetching: true,
                fetchingMessage: 'Deleting city...',
            };
        case CITIES_DELETE_CITY_REQUEST_ERROR:
            return {
                ...state,
                isFetching: false,
            };
        default:
            return state;
    }
};
