/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Table } from 'semantic-ui-react';

// ELEMENTS
import { TablePaginationFooter, TableHeader } from '../../elements';
// HOCS
import withPagination from '../../hocs/with_pagination';
// ACTIONS
import { fetchCityEditorRequests, grantCityEditorRequest, denyCityEditorRequest } from '../../../actions';

class CityEditorRequestsTableClass extends Component {
    state = {
        sortColumn: '',
        sortDirection: '',
    };

    getRows = () => {
        const { data } = this.props;
        if (data.length === 0) return (<Table.Row key="no_results"><Table.Cell>No results</Table.Cell></Table.Row>);

        return data.map((request) => {
            const { sequence, userUsername, userName } = request;

            return (
                <Table.Row key={sequence}>
                    <Table.Cell>{userUsername}</Table.Cell>
                    <Table.Cell>{userName}</Table.Cell>
                    <Table.Cell textAlign="right">
                        <Button positive basic icon="check" onClick={() => { this.grantRequest(sequence); }}/>
                        <Button negative basic icon="close" onClick={() => { this.denyRequest(sequence); }}/>
                    </Table.Cell>
                </Table.Row>
            );
        });
    };

    grantRequest = (citySequence) => {
        const { token, paginationObj } = this.props;
        const { sortColumn, sortDirection } = this.state;
        this.props.grantCityEditorRequest(token, citySequence, paginationObj.selectedPage, { sortColumn, sortDirection });
    };

    denyRequest = (citySequence) => {
        const { token, paginationObj } = this.props;
        const { sortColumn, sortDirection } = this.state;
        this.props.denyCityEditorRequest(token, citySequence, paginationObj.selectedPage, { sortColumn, sortDirection });
    };

    handleSort = (clickedColumn) => {
        const { sortColumn, sortDirection } = this.state;
        const { paginationObj, token } = this.props;

        let newColumn = clickedColumn;
        let newDirection = 'ascending';

        if (sortColumn === newColumn) {
            if (sortDirection === 'ascending') {
                newDirection = 'descending';
            } else {
                newColumn = null;
                newDirection = null;
            }
        }

        this.setState({
            sortColumn: newColumn,
            sortDirection: newDirection,
        });

        this.props.fetchListData(
            token,
            paginationObj.selectedPage,
            { sortColumn: newColumn, sortDirection: newDirection },
        );
    };

    render() {
        const { paginationObj, paginateChangePage } = this.props;
        const { sortColumn, sortDirection } = this.state;
        if (paginationObj === null) return null;

        return (
            <div>
                <TableHeader title="City Editor Requests" icon="user plus" content="Manage Requests"/>
                <div>
                    <Table striped sortable fixed singleLine>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell width={7}>
                                    Email
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    width={7}
                                    sorted={sortColumn === 'NAME' ? sortDirection : null}
                                    onClick={() => { this.handleSort('NAME'); }}>
                                    Name
                                </Table.HeaderCell>
                                <Table.HeaderCell width={2}>
                                    Options
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {this.getRows()}
                        </Table.Body>
                        <TablePaginationFooter
                            paginationObj={paginationObj}
                            colSpan={10}
                            handleChangePage={paginateChangePage}
                            searchObj={this.state}
                        />
                    </Table>
                </div>
            </div>
        );
    }
}

function mapStateToProps({ auth, cityEditorRequests }) {
    const { token } = auth;
    const { cityEditorRequests: data, paginationObj } = cityEditorRequests;

    return {
        token,
        data,
        paginationObj,
    };
}

export const CityEditorRequestsTable = connect(mapStateToProps, {
    fetchListData: fetchCityEditorRequests,
    grantCityEditorRequest,
    denyCityEditorRequest,
})(withPagination(CityEditorRequestsTableClass));

