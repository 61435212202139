/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Button, Input } from 'semantic-ui-react';

export class SearchBar extends React.Component {
    static propTypes = {
        searchFunc: PropTypes.func.isRequired,
        clearSearchFunc: PropTypes.func.isRequired,
        value: PropTypes.string.isRequired,
        numeric: PropTypes.bool,
    };

    static defaultProps = {
        numeric: false,
    };

    render() {
        const { searchFunc, clearSearchFunc, value, numeric } = this.props;
        if (numeric) {
            return (
                <Fragment>
                    <Input
                        style={{ width: '80%' }}
                        placeholder="Search"
                        type="number"
                        value={value}
                        onChange={searchFunc}
                    />
                    <Button
                        negative
                        icon="close"
                        size="small"
                        onClick={clearSearchFunc}
                    />
                </Fragment>
            );
        }
        return (
            <Fragment>
                <Input
                    style={{ width: '80%' }}
                    placeholder="Search"
                    value={value}
                    onChange={searchFunc}
                />
                <Button
                    negative
                    icon="close"
                    size="small"
                    onClick={clearSearchFunc}
                />
            </Fragment>
        );
    }
}
