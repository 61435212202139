/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

import { uniqueId, throttle } from 'lodash';
import React, { Component, Fragment } from 'react';
import { Button } from 'semantic-ui-react';
import Autosuggest from 'react-autosuggest';
import AutosuggestHighlightMatch from 'autosuggest-highlight/match';
import AutosuggestHighlightParse from 'autosuggest-highlight/parse';
import PropTypes from 'prop-types';

import { getApiImage, getUsers } from '../../utils';
import AvatarDefaultImg from '../../assets/img/avatar-default.png';

export class UsersAutosuggest extends Component {
    constructor(props) {
        super(props);
        this.throttledOnSuggestionsFetchRequested = throttle(this.onSuggestionsFetchRequested, 500);
        const { initialValue, initialPhoto, initialCategory } = props;
        this.state = {
            value: initialValue || '',
            photo: initialPhoto,
            category: initialCategory,
            suggestions: [],
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { initialValue } = this.props;

        if (prevProps.initialValue !== initialValue) {
            this.updateValue(initialValue);
        }
    }

    onChange = (event, { newValue }) => {
        this.setState({
            ...this.state,
            value: newValue,
        });
    };

    onSuggestionsFetchRequested = async ({ value }) => {
        const { token } = this.props;
        this.setState({ suggestions: [] }, async () => {
            const { data: users } = await getUsers(token, value);
            this.setState({ suggestions: [...users] });
        });
    };

    onSuggestionsClearRequested = () => this.setState({ suggestions: [] });

    onClearSearchField = () => {
        const { onUserSelected } = this.props;
        onUserSelected(null);
        this.setState({
            value: null,
            photo: null,
            category: null,
        });
    };

    onSuggestionSelected = (event, { suggestion }) => {
        const { name, profilePhotoUrl, userCategory } = suggestion;
        const { onUserSelected } = this.props;
        onUserSelected(suggestion);
        this.setState({
            ...this.state,
            value: name,
            photo: profilePhotoUrl,
            category: userCategory,
        });
    };

    getSuggestionValue = ({ name }) => name;

    updateValue = (value) => {
        this.setState({
            ...this.state,
            value,
        });
    }

    renderSuggestion = (suggestion, { query }) => {
        const suggestionText = suggestion.name;
        const suggestionAvatar = suggestion.profilePhotoUrl;
        const suggestionCategory = suggestion.userCategory;
        const matches = AutosuggestHighlightMatch(suggestionText, query);
        const parts = AutosuggestHighlightParse(suggestionText, matches);

        return (
            <div>
                {
                    parts.map((part, index) => {
                        const className = part.highlight ? 'highlight' : null;
                        return (
                            <Fragment key={uniqueId()}>
                                {
                                    index === 0 && (
                                        <img
                                            className="user-avatar"
                                            src={suggestionAvatar ? getApiImage(suggestionAvatar) : AvatarDefaultImg}
                                            alt="avatar"
                                        />
                                    )
                                }
                                <span className={className}>
                                    {part.text}
                                </span>
                                {index === parts.length - 1 && <span className="user-category">{suggestionCategory.name}</span>}
                            </Fragment>
                        );
                    })
                }
            </div>
        );
    };

    render() {
        const { hasError } = this.props;
        const { value, photo, category, suggestions } = this.state;
        const inputProps = {
            placeholder: 'Select user',
            value: value || '',
            onChange: this.onChange,
        };

        return (
            <div className={`users-autocomplete ${hasError ? 'error' : ''}`}>
                {
                    value && (
                        <img
                            className="user-photo"
                            src={photo ? getApiImage(photo) : AvatarDefaultImg}
                            alt="user"
                        />
                    )
                }
                <Autosuggest
                    id="users_autosuggest"
                    highlightFirstSuggestion
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={this.throttledOnSuggestionsFetchRequested}
                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                    onSuggestionSelected={this.onSuggestionSelected}
                    getSuggestionValue={this.getSuggestionValue}
                    renderSuggestion={this.renderSuggestion}
                    inputProps={inputProps}
                />
                {
                    category && (
                        <span className="user-category-preview">
                            {category.name || category}
                        </span>
                    )
                }
                {
                    value && (
                        <Button
                            circular
                            className="clear-button"
                            icon="close"
                            size="mini"
                            onClick={this.onClearSearchField}
                        />
                    )
                }
            </div>
        );
    }
}

UsersAutosuggest.propTypes = {
    token: PropTypes.string,
    hasError: PropTypes.bool,
    initialValue: PropTypes.string,
    initialPhoto: PropTypes.string,
    onUserSelected: PropTypes.func,
    initialCategory: PropTypes.string,
};

UsersAutosuggest.defaultProps = {
    token: null,
    hasError: false,
    initialValue: null,
    initialPhoto: null,
    onUserSelected: () => {},
    initialCategory: null,
};
