/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

import moment from 'moment';

import { API_URL } from '../settings';
import { DAYS_WEEK, MAP_WEEKS_TO_INDEX } from '../constants/week_days';


export const getRequestConfig = (token, selectedPage, isMultipart = false) => {
    if (isMultipart) {
        return {
            headers: {
                Authorization: token,
                'content-type': 'multipart/form-data',
            },
        };
    }

    if (!selectedPage) {
        return {
            headers: {
                Authorization: token,
            },
        };
    }

    return {
        headers: {
            Authorization: token,
            'selected-page': selectedPage,
            'max-results': 10,
        },
    };
};

export const formatDate = (timestamp = null, format = 'DD-MM-YYYY, HH:mm') => {
    if (timestamp === null) return '';
    return moment(timestamp).format(format);
};

export const escapeRegexCharacters = str => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

export const getApiImage = (imageAddress) => {
    return imageAddress ? `${API_URL}${imageAddress}` : null;
};

export const getApiPdf = (pdfAddress) => {
    return pdfAddress ? `${API_URL}${pdfAddress}` : null;
};

export const buildStoryObject = (storyDetails, storyCover, storyCards) => {
    return {
        // media elements
        cover: storyCover || null,
        photoSequence: storyCover || null,
        // simple data elements
        title: storyDetails.title || '',
        introText: storyDetails.introText || '',
        storyDate: storyDetails.storyDate || null,
        storyCards,
        userSequence: storyDetails.userSequence || null,
        storyTypeSequence: storyDetails.storyType.sequence || null,
    };
};

// 1 min -> 60 000 milliseconds
// 1 hour -> 3 600 000 milliseconds
export const millis = (hour, min) => {
    const hourMillis = hour * 3600000;
    const minuteMillis = min * 60000;

    return hourMillis + minuteMillis;
};

export const millisToTime = (mill) => {
    let hourMillis = mill / 3600000;
    hourMillis |= 0; // cast

    let minuteMillis = (mill - (hourMillis * 3600000)) / 60000;
    minuteMillis |= 0; // cast

    return [hourMillis, minuteMillis];
};


// same format as google api
// correct format to send(backend)
export const openingHoursCorrectFormatToSend = (openingHours) => {
    const openingHoursResult = [];
    for (let i = 0; i < openingHours.length; i++) {
        const period = openingHours[i];

        if (!period) continue;
        if (!period.open) continue;
        if (!period.close) continue;


        const openTimeSplit = period.open.split(':');
        if (openTimeSplit.length !== 2) continue;


        const openHours = openTimeSplit[0];
        const openMins = openTimeSplit[1];

        const closeTimeSplit = period.close.split(':');
        if (closeTimeSplit.length !== 2) continue;

        const closeHours = closeTimeSplit[0];
        const closeMins = closeTimeSplit[1];


        const openMillis = millis(openHours, openMins);
        const closeMillis = millis(closeHours, closeMins);

        // one day after
        let closeDay = MAP_WEEKS_TO_INDEX(period.day);
        if (openMillis >= closeMillis) {
            if (closeDay + 1 > 6) {
                closeDay = 0;
            } else {
                closeDay += 1;
            }
        }

        openingHoursResult.push({
            open: openMillis,
            close: closeMillis,
            openDay: MAP_WEEKS_TO_INDEX(period.day),
            closeDay,
        });
    }

    return openingHoursResult;
};

//  correct format to send(backend)
export const linkPlacesCorrectFormatToSend = (linkPlaces) => {
    const result = { parents: [], children: [] };

    linkPlaces.forEach((linkPlace) => {
        if (linkPlace.linkPlaceRelation === 'parent') {
            if (linkPlace.linkPlaceSequence) {
                result.parents.push(linkPlace.linkPlaceSequence);
            }
        }
        if (linkPlace.linkPlaceRelation === 'child') {
            if (linkPlace.linkPlaceSequence) {
                result.children.push(linkPlace.linkPlaceSequence);
            }
        }
    });

    return result;
};

export const parseLinkPlacesResponse = (parents, children) => {
    const result = [];

    result.push(parents.map(parent => ({
        text: `${parent.title}, ${parent.cityName}`,
        linkPlaceSequence: parent.sequence,
        linkPlaceRelation: 'parent',
    })));

    result.push(children.map(child => ({
        text: `${child.title}, ${child.cityName}`,
        linkPlaceSequence: child.sequence,
        linkPlaceRelation: 'child',
    })));

    return result.flat(Infinity);
};

//  correct format to send(backend)
export const markerCoordinatesCorrectFormat = (markerCoordinates) => {
    return markerCoordinates.map((coordinates) => {
        return {
            longitude: coordinates.lng,
            latitude: coordinates.lat,
        };
    });
};

export const parseCoordinatesResponse = (markerCoordinates) => {
    return markerCoordinates.map((coordinates) => {
        return {
            lng: coordinates.longitude,
            lat: coordinates.latitude,
        };
    });
};

// correct format to handle in front end
export const parseOpeningHoursResponse = (openingHours) => {
    const result = [];

    for (let i = 0; i < openingHours.length; i++) {
        const timeOpen = millisToTime(openingHours[i].open);
        const timeClose = millisToTime(openingHours[i].close);

        const openParsed = moment(`${timeOpen[0]}:${timeOpen[1]}`, 'HH:mm').format('HH:mm');
        const closeParsed = moment(`${timeClose[0]}:${timeClose[1]}`, 'HH:mm').format('HH:mm');

        result.push({
            open: openParsed,
            close: closeParsed,
            day: DAYS_WEEK[openingHours[i].openDay],

        });
    }

    result.sort((a, b) => MAP_WEEKS_TO_INDEX(a.day) - MAP_WEEKS_TO_INDEX(b.day));


    return result;
};


export const buildParsedSelectedCategories = (placeCategories) => {
    return placeCategories.map((category) => {
        if (category.children.length > 0) {
            return [...buildParsedSelectedCategories(category.children), category.sequence];
        }
        return category.sequence;
    }).flat(Infinity);
};


export const buildPlaceObject = (placeDetails, placeCover, placeMedia, placeListCovers, placeCategories, placeTags, openingHours, parents, children, stationLines, stationIcon) => {
    const parsedSelectedCategories = placeCategories && placeCategories.length > 0 ?
        buildParsedSelectedCategories(placeCategories) : [];
    const parsedSelectedTags = placeTags && placeTags.length > 0 ?
        placeTags.map(tag => tag.sequence) : [];
    const openingHoursParsed = parseOpeningHoursResponse(openingHours);

    const markerCoordinates = parseCoordinatesResponse(placeDetails.markerCoordinates);
    if (markerCoordinates.length === 0) {
        markerCoordinates.push({
            lng: placeDetails.longitude,
            lat: placeDetails.latitude,
        });
    }

    const linkPlaces = parseLinkPlacesResponse(parents || [], children || []);
    if (linkPlaces.length === 0) linkPlaces.push({});

    return {
        // media elements
        cover: placeCover || null,
        stationIcon: stationIcon || null,
        stationLines: stationLines || null,
        media: placeMedia && !placeMedia.other ? placeMedia : [],
        listCovers: placeListCovers && !placeListCovers.other ? placeListCovers : [],
        // simple data elements
        name: placeDetails.name || '',
        title: placeDetails.title || '',
        sequence: placeDetails.sequence || null,
        citySequence: placeDetails.citySequence || '',
        mainCategorySequence: placeDetails.mainCategorySequence || '',
        description: placeDetails.description || '',
        tagsSequences: parsedSelectedTags,
        placeCategoriesSequences: parsedSelectedCategories,
        placeAddress: placeDetails.placeAddress || '',
        phone: placeDetails.phone || '',
        email: placeDetails.email || '',
        website: placeDetails.website || '',
        wikipedia: placeDetails.wikipedia || '',
        reservationType: placeDetails.reservationType || null,
        reservationURL: placeDetails.reservationURL || null,
        facebookPlaceId: placeDetails.facebookPlaceId || '',
        facebookPlaceUrl: placeDetails.facebookPlaceUrl || '',
        bookingPlaceId: placeDetails.bookingPlaceId || '',
        bookingPlaceUrl: placeDetails.bookingPlaceUrl || '',
        foursquarePlaceId: placeDetails.foursquarePlaceId || '',
        foursquarePlaceUrl: placeDetails.foursquarePlaceUrl || '',
        googlePlaceId: placeDetails.googlePlaceId || '',
        googlePlaceUrl: placeDetails.googlePlaceUrl || '',
        instagramHashTags:
            placeDetails.instagramHashTags ? placeDetails.instagramHashTags.toString() : '',
        instagramPlaceId: placeDetails.instagramPlaceId || '',
        instagramPlaceUrl: placeDetails.instagramPlaceUrl || '',
        instagramLocationId: placeDetails.instagramLocationId || '',
        instagramLocationUrl: placeDetails.instagramLocationUrl || '',
        tripAdvisorPlaceId: placeDetails.tripAdvisorPlaceId || '',
        tripAdvisorPlaceUrl: placeDetails.tripAdvisorPlaceUrl || '',
        twitterHashTags:
            placeDetails.twitterHashTags ? placeDetails.twitterHashTags.toString() : '',
        twitterPlaceId: placeDetails.twitterPlaceId || '',
        twitterPlaceUrl: placeDetails.twitterPlaceUrl || '',
        yelpPlaceId: placeDetails.yelpPlaceId || '',
        yelpPlaceUrl: placeDetails.yelpPlaceUrl || '',
        zomatoPlaceId: placeDetails.zomatoPlaceId || '',
        zomatoPlaceUrl: placeDetails.zomatoPlaceUrl || '',
        openingHoursNotes: placeDetails.openingHoursNotes || '',
        openingHours: openingHoursParsed || [],
        manualOpeningHours: placeDetails.manualOpeningHours,
        permanentlyClosed: placeDetails.permanentlyClosed,
        alwaysOpen: placeDetails.alwaysOpen,
        openingHoursNoInfo: placeDetails.openingHoursNoInfo,
        latitude: placeDetails.latitude || null,
        longitude: placeDetails.longitude || null,
        markerCoordinates: markerCoordinates || [],
        linkPlaces,
        comingSoon: placeDetails.comingSoon,
    };
};
