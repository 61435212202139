/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

import axios from 'axios';

import {
    USERS_REQUEST, USERS_RECEIVED, USERS_REQUEST_ERROR,
    USERS_CREATE_USER_REQUEST, USERS_CREATE_USER_REQUEST_ERROR,
    USERS_DELETE_USER_REQUEST, USERS_DELETE_USER_REQUEST_ERROR,
    USERS_RESET_USER_PASSWORD_REQUEST, USERS_RESET_USER_PASSWORD_REQUEST_ERROR,
} from '../actions/types';

import { displayNotification, getRequestConfig, handleCustomErrors, handleServerErrors } from '../utils';

import { API_URL } from '../settings';

// LIST USERS
const requestUsers = () => ({
    type: USERS_REQUEST,
});

const receiveUsers = (users, maxResults, selectedPage, totalResults) => ({
    type: USERS_RECEIVED,
    payload: {
        users,
        paginationObj: {
            maxResults,
            selectedPage,
            totalResults,
        },
    },
});

const requestUsersError = () => ({
    type: USERS_REQUEST_ERROR,
});

// EDIT USER
const requestUsersCreateUser = () => ({
    type: USERS_CREATE_USER_REQUEST,
});

const requestUsersCreateUserError = () => ({
    type: USERS_CREATE_USER_REQUEST_ERROR,
});

// DELETE USER
const requestUsersDeleteUser = () => ({
    type: USERS_DELETE_USER_REQUEST,
});

const requestUsersDeleteUserError = () => ({
    type: USERS_DELETE_USER_REQUEST_ERROR,
});

// RESET USER PASSWORD
const requestUsersResetUserPassword = () => ({
    type: USERS_RESET_USER_PASSWORD_REQUEST,
});

const requestUsersResetUserPasswordError = () => ({
    type: USERS_RESET_USER_PASSWORD_REQUEST_ERROR,
});
// UI ACTIONS

export const fetchUsers = (token, selectedPage = 1, searchObj = {}) => {
    return async (dispatch) => {
        dispatch(requestUsers());
        try {
            let url = `${API_URL}/user/bo`;

            let filtersNum = 0;

            if (searchObj.nameSearch && searchObj.nameSearch.length > 0) {
                url = url.concat(`?name=${searchObj.nameSearch}`);
                filtersNum++;
            }

            if (searchObj.usernameSearch && searchObj.usernameSearch.length > 0) {
                let prefix = '?';
                if (filtersNum > 0) {
                    prefix = '&';
                }
                url = url.concat(`${prefix}username=${searchObj.usernameSearch}`);
                filtersNum++;
            }

            if (searchObj.profileSearch && searchObj.profileSearch.length > 0) {
                let prefix = '?';
                if (filtersNum > 0) {
                    prefix = '&';
                }
                url = url.concat(`${prefix}profile=${searchObj.profileSearch}`);
                filtersNum++;
            }

            if (searchObj.sortColumn && searchObj.sortColumn.length > 0) {
                let prefix = '?';
                if (filtersNum > 0) {
                    prefix = '&';
                }
                url = url.concat(`${prefix}userSort=${searchObj.sortColumn}&sortAsc=${searchObj.sortDirection === 'ascending'}`);
            } else {
                let prefix = '?';
                if (filtersNum > 0) {
                    prefix = '&';
                }
                url = url.concat(`${prefix}userSort=NAME&sortAsc=true`);
            }
            const { data, headers } = await axios.get(
                url,
                getRequestConfig(token, selectedPage),
            );
            dispatch(receiveUsers(
                data,
                headers['max-results'], headers['selected-page'], headers['total-results'],
            ));
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.other && error.response.data.other.errorCode) {
                handleCustomErrors(error.response.data.other.errorCode);
            } else {
                handleServerErrors(error, dispatch, requestUsersError);
            }
        }
    };
};

export const createUser = (token, user, modalClose) => {
    return async (dispatch) => {
        dispatch(requestUsersCreateUser());
        try {
            const formData = new FormData();

            const parsedUser = {
                username: user.username,
                name: user.name,
                userCategory: user.userCategory.id,
                introduction: user.introduction,
                observations: user.observations,
                phone: user.phone,
                website: user.website,
                facebook: user.facebook,
                instagram: user.instagram,
            };

            if (user.profilePhoto) formData.append('profilePhoto', new Blob([user.profilePhoto], { type: 'application/octet-stream' }));
            if (user.coverPhoto) formData.append('coverPhoto', new Blob([user.coverPhoto], { type: 'application/octet-stream' }));
            formData.append('user', JSON.stringify(parsedUser));

            await axios.post(`${API_URL}/user`, formData, getRequestConfig(token, null, true));
            dispatch(fetchUsers(token));
            displayNotification({ type: 'success', message: 'User created!' });
            modalClose();
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.other && error.response.data.other.errorCode) {
                if (error.response.data.other.errorCode === 22) displayNotification({ type: 'error', message: 'File is too big or invalid' });
                else handleCustomErrors(error.response.data.other.errorCode);
            } else {
                handleServerErrors(error, dispatch, requestUsersCreateUserError);
            }
        }
    };
};

export const updateUser = (token, user, modalClose) => {
    return async (dispatch) => {
        dispatch(requestUsersCreateUser());
        try {
            const formData = new FormData();

            const parsedUser = {
                name: user.name,
                userCategory: user.userCategory.id,
                introduction: user.introduction,
                observations: user.observations,
                phone: user.phone,
                website: user.website,
                facebook: user.facebook,
                instagram: user.instagram,
            };

            if (user.profilePhoto && !user.profilePhoto.publicUrls) {
                formData.append('profilePhoto', new Blob([user.profilePhoto], { type: 'application/octet-stream' }));
            }
            if (user.coverPhoto && !user.coverPhoto.publicUrls) {
                formData.append('coverPhoto', new Blob([user.coverPhoto], { type: 'application/octet-stream' }));
            }
            formData.append('deleteCoverPhoto', String(!user.coverPhoto));
            formData.append('deleteProfilePhoto', String(!user.profilePhoto));
            formData.append('user', JSON.stringify(parsedUser));

            await axios.put(`${API_URL}/user/${user.sequence}`, formData, getRequestConfig(token, null, true));
            dispatch(fetchUsers(token));
            displayNotification({ type: 'success', message: 'User updated!' });
            modalClose();
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.other && error.response.data.other.errorCode) {
                if (error.response.data.other.errorCode === 22) displayNotification({ type: 'error', message: 'File is too big or invalid' });
                else handleCustomErrors(error.response.data.other.errorCode);
            } else {
                handleServerErrors(error, dispatch, requestUsersCreateUserError);
            }
        }
    };
};

export const deleteUser = (token, sequence, selectedPage, filtersObj = {}) => {
    return async (dispatch) => {
        try {
            dispatch(requestUsersDeleteUser());

            await axios.delete(`${API_URL}/user/${sequence}`, getRequestConfig(token, selectedPage));

            dispatch(fetchUsers(token, selectedPage, filtersObj));

            displayNotification({ type: 'success', message: 'User deleted!' });
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.other && error.response.data.other.errorCode) {
                handleCustomErrors(error.response.data.other.errorCode);
            } else {
                handleServerErrors(error, dispatch, requestUsersDeleteUserError);
            }
        }
    };
};

export const changeUserProfiles = (token, sequence, profiles, selectedPage, filtersObj = {}, modalClose) => {
    return async (dispatch) => {
        try {
            dispatch(requestUsersCreateUser());

            await axios.put(`${API_URL}/user/${sequence}/profiles`, profiles, getRequestConfig(token, selectedPage));

            dispatch(fetchUsers(token, selectedPage, filtersObj));

            displayNotification({ type: 'success', message: 'User profiles changed!' });

            modalClose();
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.other && error.response.data.other.errorCode) {
                handleCustomErrors(error.response.data.other.errorCode);
            } else {
                handleServerErrors(error, dispatch, requestUsersCreateUserError);
            }
        }
    };
};

export const resetUserPassword = (token, sequence, selectedPage, filtersObj = {}) => {
    return async (dispatch) => {
        try {
            dispatch(requestUsersResetUserPassword());

            await axios.get(`${API_URL}/user/${sequence}/lock`, getRequestConfig(token, selectedPage));

            dispatch(fetchUsers(token, selectedPage, filtersObj));

            displayNotification({ type: 'success', message: 'User password has been reset!' });
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.other && error.response.data.other.errorCode) {
                handleCustomErrors(error.response.data.other.errorCode);
            } else {
                handleServerErrors(error, dispatch, requestUsersResetUserPasswordError);
            }
        }
    };
};
