/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

import axios from 'axios';

import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE, SESSION_UPDATE, LOGOUT } from './types';
import { API_URL } from '../settings';
import { handleServerErrors, displayNotification } from '../utils';
import { PLACES_ROUTE } from '../constants';

export const requestLogin = () => ({
    type: LOGIN_REQUEST,
});

export const receiveLogin = token => ({
    type: LOGIN_SUCCESS,
    payload: token,
});

export const loginError = formErrors => ({
    type: LOGIN_FAILURE,
    payload: formErrors,
});

export const requestLogout = () => {
    return {
        type: LOGOUT,
    };
};

const updateSession = user => ({
    type: SESSION_UPDATE,
    payload: {
        user,
    },
});

export const loginUser = ({ username, password }, navigate) => {
    return async (dispatch) => {
        dispatch(requestLogin());
        try {
            const { data, headers } = await axios.post(`${API_URL}/authentication/login-bo`, { username, password });
            const token = `Bearer ${headers.authorization}`;

            dispatch(receiveLogin(token));
            dispatch(updateSession(data));
            navigate(PLACES_ROUTE);
        } catch (error) {
            if (error.response && error.response.status === 400) {
                displayNotification({ type: 'error', message: 'Username or Password invalid!' });
                dispatch(loginError({}));
            } else {
                handleServerErrors(error, dispatch, loginError);
            }
        }
    };
};

export const logoutUser = () => {
    return (dispatch) => {
        dispatch(requestLogout());
    };
};

