/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

import React, { PureComponent } from 'react';
import { compose, withProps } from 'recompose';
import { withGoogleMap, GoogleMap } from 'react-google-maps';
import DrawingManager from 'react-google-maps/lib/components/drawing/DrawingManager';
import PropTypes from 'prop-types';

import { DEFAULT_MAP_CENTER, MAP_STYLE } from '../../constants';

const googleMapDefaultOptions = {
    styles: MAP_STYLE,
    disableDefaultUI: true,
    disableDoubleClickZoom: true,
};

const polygonDefaultOptions = {
    strokeWeight: 1,
    strokeColor: '#ffc300',
    fillColor: '#ffc300',
    fillOpacity: 0.12,
    editable: true,
    dragable: true,
    clickable: true,
};

const MyMapComponent = compose(
    withProps({
        loadingElement: <div style={{ height: '100%' }} />,
        containerElement: <div style={{ height: '200px' }} />,
        mapElement: <div style={{ height: '100%' }} />,
    }),
    withGoogleMap,
)((props) => {
    const {
        initialCenter,
        centerPosition,
        geographicalArea,
        setGeographicalArea,
    } = props;

    const [currentPolygon, setCurrentPolygon] = React.useState(null);
    const [mapRef, setMapRef] = React.useState(null);

    const onPolygonEdit = (polygon) => {
        setGeographicalArea(polygon.getPath().getArray().map((position) => {
            return { latitude: position.lat(), longitude: position.lng() };
        }));
    };

    const onMapMounted = (ref) => {
        if (ref && !mapRef) {
            setMapRef(ref);
            if (geographicalArea.length > 0) {
                const polygon = new window.google.maps.Polygon({
                    paths: geographicalArea.map(point => ({ lat: point.latitude, lng: point.longitude })),
                    ...polygonDefaultOptions,
                });
                setCurrentPolygon(polygon);
                polygon.setMap(ref.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED);
                window.google.maps.event.addListener(polygon.getPath(), 'set_at', () => onPolygonEdit(polygon));
                window.google.maps.event.addListener(polygon.getPath(), 'insert_at', () => onPolygonEdit(polygon));
                window.google.maps.event.addListener(polygon.getPath(), 'remove_at', () => onPolygonEdit(polygon));
                setGeographicalArea(polygon.getPath().getArray().map((position) => {
                    return { latitude: position.lat(), longitude: position.lng() };
                }));
            }
        }
    };

    const onPolygonComplete = (polygon) => {
        window.google.maps.event.addListener(polygon.getPath(), 'set_at', () => onPolygonEdit(polygon));
        window.google.maps.event.addListener(polygon.getPath(), 'insert_at', () => onPolygonEdit(polygon));
        window.google.maps.event.addListener(polygon.getPath(), 'remove_at', () => onPolygonEdit(polygon));

        if (currentPolygon) {
            currentPolygon.setMap(null);
        }

        setCurrentPolygon(polygon);

        setGeographicalArea(polygon.getPath().getArray().map((position) => {
            return { latitude: position.lat(), longitude: position.lng() };
        }));
    };


    return (
        <GoogleMap
            defaultZoom={13}
            defaultOptions={googleMapDefaultOptions}
            defaultCenter={initialCenter}
            center={centerPosition}
            ref={onMapMounted}
        >
            <DrawingManager
                defaultDrawingMode={window.google.maps.drawing.OverlayType.POLYGON}
                defaultOptions={{
                    drawingControl: true,
                    drawingControlOptions: {
                        position: window.google.maps.ControlPosition.TOP_RIGHT,
                        drawingModes: [window.google.maps.drawing.OverlayType.POLYGON],
                    },
                    polygonOptions: polygonDefaultOptions,
                }}
                onPolygonComplete={onPolygonComplete}
            />
        </GoogleMap>
    );
});

export class PolygonMapElement extends PureComponent {
    render() {
        const {
            initialCenter,
            geographicalArea,
            setGeographicalArea,
            centerPosition,
        } = this.props;

        return (
            <MyMapComponent
                initialCenter={initialCenter}
                geographicalArea={geographicalArea}
                setGeographicalArea={setGeographicalArea}
                centerPosition={centerPosition}
            />
        );
    }
}

PolygonMapElement.propTypes = {
    initialCenter: PropTypes.shape({
        lat: PropTypes.number,
        lng: PropTypes.number,
    }),
    setGeographicalArea: PropTypes.func,
    geographicalArea: PropTypes.array,
    centerPosition: PropTypes.shape({
        lat: PropTypes.number,
        lng: PropTypes.number,
    }),
};

PolygonMapElement.defaultProps = {
    initialCenter: DEFAULT_MAP_CENTER,
    setGeographicalArea: () => {},
    geographicalArea: [],
    centerPosition: DEFAULT_MAP_CENTER,
};
