/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Button, Popup } from 'semantic-ui-react';

export const RowActionButton = (props) => {
    const { icon, text, handleOpen, value, displayAsLink, linkContent, color, onClick, disabled, circular } = props;

    if (displayAsLink) return <button onClick={handleOpen}>{linkContent}</button>;

    return (
        <Popup
            trigger={<Button
                circular={circular}
                disabled={disabled}
                onClick={onClick}
                icon={icon}
                value={value}
                color={color}
                size="tiny"
            />}
            flowing
            hoverable
            inverted
            size="tiny"
            content={text}
            position="top center"
        />
    );
};

RowActionButton.propTypes = {
    onClick: PropTypes.func,
    text: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    value: PropTypes.object,
    displayAsLink: PropTypes.bool,
    linkContent: PropTypes.string,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    circular: PropTypes.bool,
};

RowActionButton.defaultProps = {
    displayAsLink: false,
    linkContent: '',
    value: null,
    color: 'blue',
    onClick: null,
    disabled: false,
    circular: true,
};

