/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Modal, Grid, Button, Icon, Form, Image } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';

import { createCategory, updateCategory } from '../../actions';
import {
    displayFieldsMap, getAllCategories,
    getApiImage, validateTextInputField,
} from '../../utils';
import { RowActionButton } from '../elements';
import withModal from '../hocs/with_modal';

const INITIAL_STATE = {
    modalOpen: false,
    formErrors: null,
    categories: [],
    category: {
        name: '',
        parent: null,
        iconOn: null,
        iconOff: null,
    },
};

class CreateCategoryModal extends Component {
    state = INITIAL_STATE;

    onDropIconOn = (acceptedFiles) => {
        this.setState({
            ...this.state,
            category: {
                ...this.state.category,
                iconOn: acceptedFiles[0],
            },
        });
    };

    onDropIconOff = (acceptedFiles) => {
        this.setState({
            ...this.state,
            category: {
                ...this.state.category,
                iconOff: acceptedFiles[0],
            },
        });
    };

    getTrigger = () => {
        if (this.props.category) {
            return <RowActionButton text="Edit" icon="pencil" color="yellow" onClick={this.handleOpen}/>;
        }

        return (
            <Button
                icon
                primary
                style={{ marginBottom: 10 }}
                onClick={this.handleOpen}
                floated="right"
                labelPosition="left"
                size="small">
                <Icon name="info circle"/> Create Category
            </Button>
        );
    };

    handleOpen = () => {
        const { token } = this.props;
        Promise.resolve(getAllCategories(token)).then((categories) => {
            const parsedCategories = categories.map((category) => {
                return { text: category.name, value: category.sequence };
            });
            if (this.props.category) {
                this.setState({
                    ...INITIAL_STATE,
                    categories: parsedCategories,
                    category: {
                        ...this.props.category,
                        parent: this.props.category.parentSequence,
                    },
                    modalOpen: true,
                });
            } else {
                this.setState({ ...INITIAL_STATE, categories: parsedCategories, modalOpen: true });
            }
        });
    };

    handleClose = () => this.setState(INITIAL_STATE);

    handleSubmit = () => {
        if (this.validateForm()) {
            if (this.props.category) {
                this.props.updateCategory(this.props.token, this.state.category, this.handleClose);
            } else {
                this.props.createCategory(this.props.token, this.state.category, this.handleClose);
            }
        }
    };

    handleChange = (e, { name, value }) => {
        this.setState({
            ...this.state,
            category: {
                ...this.state.category,
                [name]: value,
            },
        });
    };

    handleChangeParent = (e, el) => {
        this.setState({
            ...this.state,
            category: {
                ...this.state.category,
                parent: el.value,
            },
        });
    };

    validateForm = () => {
        let errors = {};
        Object.keys(this.state.category)
            .forEach((field) => {
                switch (field) {
                    case 'name':
                        errors[field] = validateTextInputField(this.state.category[field], field, displayFieldsMap[field], 'CREATE_CATEGORY_FORM');
                        if (!errors[field]) delete errors[field];
                        break;
                    // case 'iconOn':
                    //     errors[field] = validateImageField(this.state.category[field], field, displayFieldsMap[field], 'CREATE_CATEGORY_FORM');
                    //     if (!errors[field]) delete errors[field];
                    //     else displayNotification({ type: 'error', message: 'Icon On image field may not be empty.' });
                    //     break;
                    // case 'iconOff':
                    //     errors[field] = validateImageField(this.state.category[field], field, displayFieldsMap[field], 'CREATE_CATEGORY_FORM');
                    //     if (!errors[field]) delete errors[field];
                    //     else displayNotification({ type: 'error', message: 'Icon Off image field may not be empty.' });
                    //     break;
                    default:
                        console.log(`Something went wrong evaluating ${field}.`);
                }
            });

        if (Object.keys(errors).length === 0) errors = null;
        this.setState({ formErrors: errors });
        return errors === null;
    };

    render() {
        const { fixBody } = this.props;
        const { modalOpen, category, categories, formErrors } = this.state;
        const { name, parent, iconOn, iconOff, iconOnURL, iconOffURL } = category;
        const isEditing = !!this.props.category;

        if (!modalOpen) return this.getTrigger();

        return (
            <Modal
                closeIcon
                className="create-category-modal"
                onClose={this.handleClose}
                onUnmount={fixBody}
                closeOnDimmerClick={false}
                closeOnEscape={false}
                open={modalOpen}
                size="mini"
                trigger={this.getTrigger()}>
                <Modal.Header>{isEditing ? `Edit Category - ${this.props.category.name}` : 'Create Category'}</Modal.Header>
                <Modal.Content>
                    <Grid>
                        <Grid.Column width={16}>
                            <Form.Input
                                fluid
                                placeholder="Name"
                                name="name"
                                label={`Name ${(formErrors && formErrors.name && formErrors.name.message) || ''}`}
                                error={formErrors && !!formErrors.name}
                                value={name || ''}
                                onChange={this.handleChange}
                            />
                        </Grid.Column>
                        {false && (
                            <Grid.Column width={16} style={{ marginLeft: '19%' }}>
                                <h5>Icon On</h5>
                                <Dropzone
                                    accept="image/png, image/jpeg, image/svg+xml"
                                    onDrop={this.onDropIconOn}
                                    multiple={false}>
                                    {!iconOn && !iconOnURL &&
                                    <Fragment>
                                        <p>Please drag and drop a file or click here to open file selection dialog.</p>
                                    </Fragment>
                                    }
                                    {!iconOn && iconOnURL &&
                                    <Image src={getApiImage(iconOnURL)} style={{ width: '200px', height: '196px' }}/>
                                    }
                                    {iconOn &&
                                    <Image src={iconOn.preview} style={{ width: '200px', height: '196px' }}/>
                                    }
                                </Dropzone>
                            </Grid.Column>
                        )}
                        {false && (
                            <Grid.Column width={16} style={{ marginLeft: '19%' }}>
                                <h5>Icon Off</h5>
                                <Dropzone
                                    accept="image/png, image/jpeg, image/svg+xml"
                                    onDrop={this.onDropIconOff}
                                    multiple={false}>
                                    {!iconOff && !iconOffURL &&
                                        <p>Please drag and drop a file or click here to open file selection dialog.</p>
                                    }
                                    {!iconOff && iconOffURL &&
                                    <Image src={getApiImage(iconOffURL)} style={{ width: '200px', height: '196px' }}/>
                                    }
                                    {iconOff &&
                                    <Image src={iconOff.preview} style={{ width: '200px', height: '196px' }}/>
                                    }
                                </Dropzone>
                            </Grid.Column>
                        )}
                        <Grid.Column width={16}>
                            <Form.Dropdown
                                search
                                selection
                                fluid
                                label="Parent"
                                placeholder="Select Category"
                                data-testid="category-dropdown"
                                value={parent}
                                onChange={this.handleChangeParent}
                                options={categories}
                            />
                        </Grid.Column>
                    </Grid>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        icon
                        onClick={this.handleSubmit}
                        labelPosition="right"
                        color="green"
                    >
                        Save <Icon name="save"/>
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

CreateCategoryModal.propTypes = {
    fixBody: PropTypes.func,
    category: PropTypes.object,
    createCategory: PropTypes.func,
    updateCategory: PropTypes.func,
};

CreateCategoryModal.defaultProps = {
    fixBody: () => {},
    category: null,
    createCategory: () => {},
    updateCategory: () => {},
};

const mapStateToProps = ({ auth }) => {
    const { token } = auth;
    return { token };
};

export default connect(mapStateToProps, {
    createCategory,
    updateCategory,
})(withModal(CreateCategoryModal));
