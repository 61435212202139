/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { Button, Form, Header, Modal, Popup } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import withModal from '../hocs/with_modal';

import { RowActionButton } from '../elements';

const initialState = {
    modalOpen: false,
    link: '',
};

class TextLinkModal extends Component {
    static propTypes = {
        cb: PropTypes.func.isRequired,
        title: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,
        icon: PropTypes.element,
        tooltip: PropTypes.string,
        disabled: PropTypes.bool,
        color: PropTypes.string,
        ref: PropTypes.any,
    };

    static defaultProps = {
        icon: 'delete',
        tooltip: 'delete',
        disabled: false,
        color: 'red',
        ref: null,
    };

    constructor(props) {
        super(props);
        const funcsToBind = [
            'handleOpen',
            'handleCancel',
            'handleSubmit',
        ];
        funcsToBind.forEach((f) => {
            this[f] = this[f].bind(this);
        });

        this.state = initialState;
    }

    onInputChange = (e) => {
        this.setState({
            link: e.currentTarget.value,
        });
    }

    handleOpen() {
        this.setState({ modalOpen: true });
    }

    handleCancel() {
        this.setState(initialState);
    }

    handleSubmit() {
        const { link } = this.state;
        this.props.cb(link);
        this.setState({ modalOpen: false });
    }

    render() {
        const {
            title,
            content,
            icon,
            tooltip,
            color,
            fixBody,
            ref,
            disabled,
        } = this.props;

        const { link } = this.state;

        return (
            <Modal
                ref={ref}
                onUnmount={fixBody}
                open={this.state.modalOpen}
                onClose={this.handleClose}
                size="tiny"
                trigger={
                    <React.Fragment>
                        <Popup
                            trigger={<RowActionButton
                                disabled={disabled}
                                text={title}
                                onClick={this.handleOpen}
                                color={color}
                                icon={icon}
                                circular={false}
                            />}
                            flowing
                            inverted
                            content={tooltip}
                            position="top center"
                        />
                    </React.Fragment>
                }>
                <Header icon={icon} content={title}/>
                <Modal.Content>
                    <h4>{content}</h4>
                    <Form.Input
                        fluid
                        label="Link"
                        value={link}
                        onChange={this.onInputChange}
                        style={{ marginBottom: '20px' }}
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button.Group>
                        <Button
                            data-testId="link-cancel-button"
                            negative
                            content="Cancel"
                            onClick={this.handleCancel}
                        />
                        <Button.Or/>
                        <Button
                            data-testId="link-accept-button"
                            positive
                            icon="checkmark"
                            labelPosition="right"
                            content={title}
                            onClick={this.handleSubmit}/>
                    </Button.Group>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default withModal(TextLinkModal);
