/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

import { REHYDRATE } from 'redux-persist';

import {
    STORIES_REQUEST, STORIES_RECEIVED, STORIES_REQUEST_ERROR,
    STORIES_CREATE_STORY_REQUEST, STORIES_CREATE_STORY_REQUEST_ERROR,
    STORIES_DELETE_STORY_REQUEST, STORIES_DELETE_STORY_REQUEST_ERROR,
} from '../actions/types';

const INITIAL_STATE = {
    stories: [],
    isFetching: false,
    fetchingMessage: '',
    paginationObj: null,
    formErrors: null,
};

export default (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case REHYDRATE:
            if (payload && payload.stories) {
                return {
                    ...payload.stories,
                    isFetching: false,
                    fetchingMessage: '',
                    formErrors: null,
                };
            }
            return state;
        case STORIES_REQUEST:
            return {
                ...state,
                isFetching: true,
                fetchingMessage: 'Fetching stories...',
            };
        case STORIES_RECEIVED:
            return {
                ...state,
                ...payload,
                isFetching: false,
            };
        case STORIES_REQUEST_ERROR:
            return {
                ...state,
                isFetching: false,
            };
        case STORIES_CREATE_STORY_REQUEST:
            return {
                ...state,
                isFetching: true,
                fetchingMessage: 'Saving story...',
            };
        case STORIES_CREATE_STORY_REQUEST_ERROR:
            return {
                ...state,
                isFetching: false,
            };
        case STORIES_DELETE_STORY_REQUEST:
            return {
                ...state,
                isFetching: true,
                fetchingMessage: 'Deleting story...',
            };
        case STORIES_DELETE_STORY_REQUEST_ERROR:
            return {
                ...state,
                isFetching: false,
            };
        default:
            return state;
    }
};
