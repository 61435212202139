/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default function (WrappedComponent) {
    class WithPagination extends Component {
        static propTypes = {
            fetchListData: PropTypes.func.isRequired,
            type: PropTypes.string,
            // token: PropTypes.string.isRequired,
        };

        static defaultProps = {
            type: '',
        };

        componentDidMount() {
            const { token, type, fetchListData } = this.props;
            if (type) {
                fetchListData(token, 1, {}, type);
            } else {
                fetchListData(token, 1, {});
            }
        }

        changePage = (page, searchObj) => {
            const { token, type } = this.props;
            if (type) {
                this.props.fetchListData(token, page, searchObj, type);
            } else {
                this.props.fetchListData(token, page, searchObj);
            }
        };

        render() {
            return (
                <WrappedComponent
                    paginateChangePage={this.changePage}
                    {...this.props}/>
            );
        }
    }

    return WithPagination;
}
