/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { Header, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';

export class TableHeader extends Component {
    static propTypes = {
        title: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired,
    };

    render() {
        const { title, content, icon } = this.props;

        return (
            <Header as="h2" dividing>
                <Icon name={icon}/>
                <Header.Content>
                    {title}
                    <Header.Subheader>
                        {content}
                    </Header.Subheader>
                </Header.Content>
            </Header>
        );
    }
}

