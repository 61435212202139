/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

import axios from 'axios';

import {
    COUNTRIES_REQUEST, COUNTRIES_RECEIVED, COUNTRIES_REQUEST_ERROR,
    COUNTRIES_CREATE_COUNTRY_REQUEST, COUNTRIES_CREATE_COUNTRY_REQUEST_ERROR,
    COUNTRIES_DELETE_COUNTRY_REQUEST, COUNTRIES_DELETE_COUNTRY_REQUEST_ERROR,
} from './types';

import { displayNotification, getRequestConfig, handleCustomErrors, handleServerErrors } from '../utils';

import { API_URL } from '../settings';

// LIST COUNTRIES
const requestCountries = () => ({
    type: COUNTRIES_REQUEST,
});

const receiveCountries = (countries, maxResults, selectedPage, totalResults) => ({
    type: COUNTRIES_RECEIVED,
    payload: {
        countries,
        paginationObj: {
            maxResults,
            selectedPage,
            totalResults,
        },
    },
});

const requestCountriesError = () => ({
    type: COUNTRIES_REQUEST_ERROR,
});

// CREATE COUNTRY
const requestCountriesCreateCountry = () => ({
    type: COUNTRIES_CREATE_COUNTRY_REQUEST,
});

const requestCountriesCreateCountryError = () => ({
    type: COUNTRIES_CREATE_COUNTRY_REQUEST_ERROR,
});

// DELETE COUNTRIES
const requestCountriesDeleteCountry = () => ({
    type: COUNTRIES_DELETE_COUNTRY_REQUEST,
});

const requestCountriesDeleteCountryError = () => ({
    type: COUNTRIES_DELETE_COUNTRY_REQUEST_ERROR,
});

// UI ACTIONS

export const fetchCountries = (token, selectedPage = 1, searchObj = {}) => {
    return async (dispatch) => {
        dispatch(requestCountries());
        try {
            let url = `${API_URL}/country`;

            let filtersNum = 0;

            if (searchObj.countrySearch && searchObj.countrySearch.length > 0) {
                url = url.concat(`?countryCode=${searchObj.countrySearch}`);
                filtersNum++;
            }

            if (searchObj.citiesSearch && searchObj.citiesSearch.length > 0) {
                let prefix = '?';
                if (filtersNum > 0) prefix = '&';
                url = url.concat(`${prefix}citiesNumber=${searchObj.citiesSearch}`);
                filtersNum++;
            }

            if (searchObj.sortColumn && searchObj.sortColumn.length > 0) {
                let prefix = '?';
                if (filtersNum > 0) prefix = '&';
                url = url.concat(`${prefix}sort=${searchObj.sortColumn}&sortAsc=${searchObj.sortDirection === 'ascending'}`);
            }

            const { data, headers } = await axios.get(
                url,
                getRequestConfig(token, selectedPage),
            );
            dispatch(receiveCountries(
                data,
                headers['max-results'], headers['selected-page'], headers['total-results'],
            ));
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.other && error.response.data.other.errorCode) {
                handleCustomErrors(error.response.data.other.errorCode);
            } else {
                handleServerErrors(error, dispatch, requestCountriesError);
            }
        }
    };
};

export const createCountry = (token, country, modalClose) => {
    return async (dispatch) => {
        dispatch(requestCountriesCreateCountry());
        try {
            await axios.post(`${API_URL}/country`, country, getRequestConfig(token, null, false));

            dispatch(fetchCountries(token));

            displayNotification({ type: 'success', message: 'Country created!' });

            modalClose();
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.other && error.response.data.other.errorCode) {
                handleCustomErrors(error.response.data.other.errorCode);
            } else {
                handleServerErrors(error, dispatch, requestCountriesCreateCountryError);
            }
        }
    };
};

export const deleteCountry = (token, sequence, selectedPage, filtersObj = {}) => {
    return async (dispatch) => {
        try {
            dispatch(requestCountriesDeleteCountry());
            await axios.delete(`${API_URL}/country/${sequence}`, getRequestConfig(token, selectedPage));
            dispatch(fetchCountries(token, selectedPage, filtersObj));
            displayNotification({ type: 'success', message: 'Country deleted!' });
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.other && error.response.data.other.errorCode) {
                handleCustomErrors(error.response.data.other.errorCode);
            } else {
                handleServerErrors(error, dispatch, requestCountriesDeleteCountryError);
            }
        }
    };
};
