/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Accordion, Image, Table } from 'semantic-ui-react';

import { TablePaginationFooter, TableHeader, SearchBar } from '../../elements';
import withPagination from '../../hocs/with_pagination';
import { fetchStories, deleteStory } from '../../../actions';
import DeleteConfirmationModal from '../../modals/general_delete_confirmation_modal';
import { formatDate, getApiImage } from '../../../utils';
import CreateStoryModal from '../../modals/create_story_modal';

class StoriesTableClass extends Component {
    state = {
        dateSearch: '',
        nameSearch: '',
        authorSearch: '',
        storyCardsNumberSearch: '',
        storyTypeNameSearch: '',
        userNameSearch: '',
        sortColumn: '',
        sortDirection: '',
        searchOpen: false,
    };

    getRows = () => {
        const { data } = this.props;
        if (!data) return null;
        if (data.length === 0) return (<Table.Row key="no_results"><Table.Cell>No results</Table.Cell></Table.Row>);

        return data.map((story) => {
            const { sequence, title, storyDate, userName, storyType, storyCardsNumber, photoPublicUrls } = story;
            let thumbnail = null;
            if (photoPublicUrls) ({ mediaThumbnailUrl: thumbnail } = photoPublicUrls);

            return (
                <Table.Row key={sequence}>
                    <Table.Cell>
                        <Image src={getApiImage(thumbnail)} style={{ width: '35px', height: '35px' }}/>
                    </Table.Cell>
                    <Table.Cell>{title}</Table.Cell>
                    <Table.Cell>{formatDate(storyDate, 'DD-MM-YYYY')}</Table.Cell>
                    <Table.Cell>{userName}</Table.Cell>
                    <Table.Cell>{userName}</Table.Cell>
                    <Table.Cell>{storyType.name}</Table.Cell>
                    <Table.Cell>{storyCardsNumber}</Table.Cell>
                    <Table.Cell textAlign="right">
                        <CreateStoryModal story={story}/>
                        <DeleteConfirmationModal
                            title="Delete Story"
                            content={`Are you sure you want to delete ${title}?`}
                            icon="trash outline"
                            tooltip="Delete Story"
                            sequence={sequence}
                            cb={this.deleteStory}/>
                    </Table.Cell>
                </Table.Row>
            );
        });
    };

    deleteStory = (tagSequence) => {
        const { token, paginationObj } = this.props;
        this.props.deleteStory(token, tagSequence, paginationObj.selectedPage, this.state);
    };

    handleSearchName = (event, { value }) => {
        const { token } = this.props;
        const { sortColumn, sortDirection, authorSearch } = this.state;
        clearTimeout(this.timeout);
        this.setState({
            ...this.state,
            nameSearch: value,
        });
        this.timeout = setTimeout(() => {
            this.props.fetchListData(token, 1, { nameSearch: value, authorSearch, sortColumn, sortDirection });
        }, 500);
    };

    clearSearchName = () => {
        this.handleSearchName(null, { value: '' });
    };

    handleSearchAuthor = (event, { value }) => {
        const { token } = this.props;
        const { sortColumn, sortDirection, nameSearch } = this.state;
        clearTimeout(this.timeout);
        this.setState({
            ...this.state,
            authorSearch: value,
        });
        this.timeout = setTimeout(() => {
            this.props.fetchListData(token, 1, { authorSearch: value, nameSearch, sortColumn, sortDirection });
        }, 500);
    };

    clearSearchAuthor = () => {
        this.handleSearchAuthor(null, { value: '' });
    };

    handleSort = (clickedColumn) => {
        const { sortColumn, sortDirection, nameSearch } = this.state;
        const { paginationObj, token } = this.props;

        let newColumn = clickedColumn;
        let newDirection = 'ascending';

        if (sortColumn === newColumn) {
            if (sortDirection === 'ascending') {
                newDirection = 'descending';
            } else {
                newColumn = null;
                newDirection = null;
            }
        }

        this.setState({
            sortColumn: newColumn,
            sortDirection: newDirection,
        });

        this.props.fetchListData(
            token,
            paginationObj.selectedPage,
            { sortColumn: newColumn, sortDirection: newDirection, nameSearch },
        );
    };

    render() {
        const { paginationObj, paginateChangePage } = this.props;
        const { nameSearch, sortColumn, sortDirection, searchOpen, authorSearch } = this.state;
        if (paginationObj === null) return null;

        return (
            <div>
                <TableHeader title="Stories" icon="book" content="Manage Stories"/>
                <div>
                    <CreateStoryModal/>
                    <br/><br/>
                    <Accordion styled style={{ width: '100%' }}>
                        <Accordion.Title
                            active={searchOpen}
                            onClick={() => this.setState({ ...this.state, searchOpen: !searchOpen })}>
                            Search
                        </Accordion.Title>
                        <Accordion.Content active={searchOpen}>
                            <div style={{ width: '300px', display: 'inline-block' }}>
                                Name<br/>
                                <SearchBar
                                    searchFunc={this.handleSearchName}
                                    clearSearchFunc={this.clearSearchName}
                                    value={nameSearch}
                                />
                            </div>
                            <div style={{ width: '300px', display: 'inline-block' }}>
                                Author<br/>
                                <SearchBar
                                    searchFunc={this.handleSearchAuthor}
                                    clearSearchFunc={this.clearSearchAuthor}
                                    value={authorSearch}
                                />
                            </div>
                        </Accordion.Content>
                    </Accordion>
                    <Table striped sortable fixed singleLine>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell width={1}>
                                    Cover
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    width={3}
                                    sorted={sortColumn === 'NAME' ? sortDirection : null}
                                    onClick={() => { this.handleSort('NAME'); }}>
                                    Name
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    width={2}
                                    sorted={sortColumn === 'DATE' ? sortDirection : null}
                                    onClick={() => { this.handleSort('DATE'); }}>
                                    Date
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    width={2}
                                    sorted={sortColumn === 'USER_NAME' ? sortDirection : null}
                                    onClick={() => { this.handleSort('USER_NAME'); }}>
                                    Author
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    width={2}
                                    sorted={sortColumn === 'USER_NAME' ? sortDirection : null}
                                    onClick={() => { this.handleSort('USER_NAME'); }}>
                                    Author Name
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    width={2}
                                    sorted={sortColumn === 'STORY_TYPE_NAME' ? sortDirection : null}
                                    onClick={() => { this.handleSort('STORY_TYPE_NAME'); }}>
                                    Type
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    width={2}
                                    sorted={sortColumn === 'STORY_CARDS_NUMBER' ? sortDirection : null}
                                    onClick={() => { this.handleSort('STORY_CARDS_NUMBER'); }}>
                                    Cards
                                </Table.HeaderCell>
                                <Table.HeaderCell width={2}>
                                    Options
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {this.getRows()}
                        </Table.Body>
                        <TablePaginationFooter
                            paginationObj={paginationObj}
                            colSpan={10}
                            handleChangePage={paginateChangePage}
                            searchObj={this.state}
                        />
                    </Table>
                </div>
            </div>
        );
    }
}

function mapStateToProps({ auth, stories }) {
    const { token } = auth;
    const { stories: data, paginationObj } = stories;

    return {
        token,
        data,
        paginationObj,
    };
}

export const StoriesTable = connect(mapStateToProps, {
    fetchListData: fetchStories,
    deleteStory,
})(withPagination(StoriesTableClass));

