/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

import { upperFirst } from 'lodash';
import { LOGOUT } from '../actions/types';
import { displayNotification } from './notification';

// LOGOUT
const requestLogout = () => ({
    type: LOGOUT,
});

export const handleServerErrors = (error, dispatch, actionHandler) => {
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log('Response data', error.response.data);
        console.log('Response status', error.response.status);
        console.log('Response headers', error.response.headers);
        switch (error.response.status) {
            case 400:
                let errorMessage = '';
                if (error && error.response && error.response.data && Object.keys(error.response.data).length > 0) ({ errorMessage } = error.response.data[Object.keys(error.response.data)[0]]);
                if (errorMessage.length > 0) errorMessage = `${upperFirst(Object.keys(error.response.data)[0])} ${errorMessage}`;
                displayNotification({ type: 'error', message: errorMessage });
                break;
            case 401:
                dispatch(requestLogout());
                break;
            default:
                console.log(`Something went wrong evaluating status ${error.response.status}.`);
        }
    } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.warn('No response received. Request ', error.request);
    } else {
        // Something happened in setting up the request that triggered an Error
        console.warn('Something happened in setting up the request that triggered an Error ', error.message);
    }
    console.warn('Not even an error response exists. Config ', error.config);
    return null;
};

export const handleCustomErrors = (errorCode) => {
    const messages = {
        1: 'The user already has an active route. Finish the current route before creating another one.',
        2: 'Route not found.',
        3: 'The selected route doesn\'t belong to the logged user.',
        4: 'No active route found.',
        5: 'Invalid Thumbnail.',
        6: 'Country not found.',
        7: 'A user should be at least 13 years old.',
        8: 'A user with the same username already exists.',
        9: 'User not found.',
        10: 'User already activated.',
        11: 'Invalid user access.',
        12: 'User locked.',
        13: 'Username or password invalid.',
        14: 'User blocked by administrator.',
        15: 'Username and/or password invalid.',
        16: 'This route is already finished or cancelled',
        17: 'This route hasn\'t started yet or is already finished or cancelled',
        18: 'This route isn\'t paused',
        19: 'This route isn\'t in created state',
        20: 'This route hasn\'t started yet or is cancelled',
        21: 'Current position is over the boundaries of the chosen route',
        22: 'Constraint Violation',
        24: 'User not found',
        27: 'Password needs be specified!',
        28: 'User not activated',
        29: 'Invalid token.',
        30: 'User is not activated',
        31: 'Pin expired, please request new one.',
        32: 'Password missing',
        33: 'Only formats allowed are jpeg and png',
        34: 'Invalid thumbnail',
        35: 'Not supported',
        36: 'Invalid format',
        37: 'Invalid number format',
        38: 'Username already in use',
        41: 'Company sequence missing',
        42: 'Friend already added',
        43: 'Country not found',
        44: 'Friend not found',
        45: 'Friend Group not found',
        46: 'Password must have at least 8 characters, 1 uppercase character, 1 number and 1 special character.',
        47: 'Missing access token',
        48: 'Selected social network registration mode not available',
        49: 'It was not possible to activate the account with the given username and token',
        53: 'Invalid email',
        54: 'Invalid country',
        55: 'Country already exists',
        56: 'City with same google location id already exists',
        57: 'Invalid icon off',
        58: 'Invalid icon on',
        59: 'Parent category not found',
        60: 'Place category not found',
        61: 'Invalid Place Category',
        62: 'Place tag not found',
        63: 'Invalid Place Tag',
        64: 'Place not found',
        65: 'Place with same google place id already exists',
        66: 'Place category with same name already exists',
        67: 'Place tag with same name already exists',
        68: 'The uploaded file is not valid. Only images and videos are supported.',
        69: 'Invalid media',
        70: 'You cannot delete a country with associated cities',
        71: 'Media not found!',
        72: 'City not found!',
        73: 'It was not possible to validate the media file.',
        74: 'Duplicated media detected!',
        75: 'Spot not found',
        76: 'Spot with same google place id already exists',
        77: 'Privacy policy not accepted',
        78: 'User already requested city editor privileges',
        79: 'City editor request not found',
        80: 'User is already a city editor',
        81: 'Story Type not found',
        82: 'Story not found',
        83: 'Each story card should have a different order value',
        84: 'Story card not found',
        85: 'File not found',
        86: 'Place media not found',
        87: 'Spot media not found',
        88: 'Selected parent category is a child of this category',
        89: 'Selected parent category is the same as the category',
        90: 'The system needs at least one Admin user.',
        91: 'You cannot remove the Administrator profile from yourself.',
        92: 'You cannot delete a place used in a story.',
        1001: 'User not found',
        1002: 'User temporarily locked',
        1003: 'Password or username invalid',
        1004: 'User is locked',
        1005: 'User blocked by administrator',
        1006: 'Access token is mandatory',
        1007: 'Token secret is mandatory',
        1008: 'Twitter account not verified',
        1009: 'User doesn\'t match',
        1010: 'Non Twitter Account',
        1011: 'No account type defined',
        1012: 'Unknown error logging in with twitter',
        1013: 'Error fetching information from Google PLUS',
        1014: 'Google Plus username, permission denied. Please check',
        1015: 'Non Google Plus Account',
        1020: 'Non Facebook Account',
        1021: 'Facebook username, permission denied. Please check',
        1022: 'Facebook error',
        1023: 'Not an admin user',
        1024: 'Wrong username for pin',
        1025: 'User is not activated',
        1026: 'Pin expired, please request new one.',
        1027: 'Reset must be in other device',
        1028: 'Empty password',
        1029: 'Account activation not supported for this type of Account access',
        1030: 'User already activated',
        1031: 'This account type doesn\'t support activation email',
    };

    displayNotification({
        type: 'error', message: messages[errorCode] || 'Something was wrong.',
    });
};
