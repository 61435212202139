/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

import axios from 'axios';

import {
    MEDIA_REQUEST, MEDIA_RECEIVED, MEDIA_REQUEST_ERROR,
    MEDIA_CREATE_MEDIA_REQUEST, MEDIA_CREATE_MEDIA_REQUEST_ERROR,
    MEDIA_DELETE_MEDIA_REQUEST, MEDIA_DELETE_MEDIA_REQUEST_ERROR,
    MEDIA_CREATE_MEDIA_REQUEST_SUCCESS,
} from '../actions/types';

import { displayNotification, getRequestConfig, handleCustomErrors, handleServerErrors } from '../utils';

import { API_URL } from '../settings';

// LIST COUNTRIES
const requestMedia = () => ({
    type: MEDIA_REQUEST,
});

const receiveMedia = (media, maxResults, selectedPage, totalResults) => ({
    type: MEDIA_RECEIVED,
    payload: {
        media,
        paginationObj: {
            maxResults,
            selectedPage,
            totalResults,
        },
    },
});

const requestMediaError = () => ({
    type: MEDIA_REQUEST_ERROR,
});

// CREATE MEDIA
const requestMediaCreateMedia = () => ({
    type: MEDIA_CREATE_MEDIA_REQUEST,
});

const requestMediaCreateMediaSuccess = () => ({
    type: MEDIA_CREATE_MEDIA_REQUEST_SUCCESS,
});

const requestMediaCreateMediaError = () => ({
    type: MEDIA_CREATE_MEDIA_REQUEST_ERROR,
});

// DELETE COUNTRIES
const requestMediaDeleteMedia = () => ({
    type: MEDIA_DELETE_MEDIA_REQUEST,
});

const requestMediaDeleteMediaError = () => ({
    type: MEDIA_DELETE_MEDIA_REQUEST_ERROR,
});

// UI ACTIONS

export const fetchMedia = (token, selectedPage = 1) => {
    return async (dispatch) => {
        dispatch(requestMedia());
        try {
            const url = `${API_URL}/media`;

            const { data, headers } = await axios.get(
                url,
                {
                    headers: {
                        Authorization: token,
                        'selected-page': selectedPage,
                        'max-results': 99,
                    },
                },
            );
            dispatch(receiveMedia(
                data,
                headers['max-results'], headers['selected-page'], headers['total-results'],
            ));
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.other && error.response.data.other.errorCode) {
                handleCustomErrors(error.response.data.other.errorCode);
            } else {
                handleServerErrors(error, dispatch, requestMediaError);
            }
        }
    };
};

export const createMedia = (token, data, cb = null) => (
    async (dispatch) => {
        const { mediaRaw } = data;
        const mediaMeta = data.mediaMeta || {};
        const { caption, captionUrl, captionDate, userObject } = mediaMeta;
        let userSequence = null;
        if (userObject) ({ sequence: userSequence } = userObject);

        dispatch(requestMediaCreateMedia());
        try {
            const formData = new FormData();
            formData.append('media', new Blob([mediaRaw], { type: 'application/octet-stream' }));
            if (caption) {
                formData.append('caption', new Blob([caption], { type: 'application/json' }));
            }
            if (captionUrl) {
                formData.append('captionUrl', captionUrl);
            }
            if (captionDate) {
                formData.append('captionDate', captionDate);
            }
            if (userSequence) {
                formData.append('userSequence', userSequence);
            }
            const {
                data: { sequence },
            } = await axios.post(
                `${API_URL}/media/${mediaRaw.type === 'application/pdf' ? 'pdf' : ''}`,
                formData,
                getRequestConfig(token, null, false),
            );
            if (cb) cb(sequence);
            dispatch(requestMediaCreateMediaSuccess());
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.other && error.response.data.other.errorCode) {
                if (error.response.data.other.errorCode === 22) displayNotification({ type: 'error', message: 'File is too big or invalid' });
                else handleCustomErrors(error.response.data.other.errorCode);
            } else {
                handleServerErrors(error, dispatch, requestMediaCreateMediaError);
            }
        }
    }
);

export const updateMedia = (token, data, mediaSequence, cb) => (
    async (dispatch) => {
        try {
            const { mediaMeta: { caption, captionUrl, captionDate, userObject } } = data;
            let userSequence = null;
            if (userObject) ({ sequence: userSequence } = userObject);
            const parsedObject = {
                caption,
                captionUrl,
                captionDate,
                userSequence,
            };
            await axios.put(`${API_URL}/media/${mediaSequence}`, parsedObject, getRequestConfig(token));
            if (cb) cb();
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.other && error.response.data.other.errorCode) {
                if (error.response.data.other.errorCode === 22) displayNotification({ type: 'error', message: 'File is too big or invalid' });
                else handleCustomErrors(error.response.data.other.errorCode);
            } else {
                handleServerErrors(error, dispatch, requestMediaCreateMediaError);
            }
        }
    }
);

export const deleteMedia = (token, sequence, selectedPage = 1) => {
    return async (dispatch) => {
        try {
            dispatch(requestMediaDeleteMedia);
            await axios.delete(`${API_URL}/media/${sequence}`, getRequestConfig(token, selectedPage));
            dispatch(fetchMedia(token, selectedPage));
            displayNotification({ type: 'success', message: 'Media deleted!' });
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.other && error.response.data.other.errorCode) {
                handleCustomErrors(error.response.data.other.errorCode);
            } else {
                handleServerErrors(error, dispatch, requestMediaDeleteMediaError);
            }
        }
    };
};

export const updateDefaultMedia = (token, data, cb) => {
    return async (dispatch) => {
        const parsedObject = {};
        if (data.defaultCityCover) {
            parsedObject.cityCoverSequence = data.defaultCityCover;
        }
        if (data.defaultFeedCover) {
            parsedObject.feedCoverSequence = data.defaultFeedCover;
        }
        if (data.defaultPlaceCover) {
            parsedObject.placeCoverSequence = data.defaultPlaceCover;
        }
        if (data.defaultUserCover) {
            parsedObject.userCoverSequence = data.defaultUserCover;
        }
        try {
            await axios.put(`${API_URL}/media/default-medias`, parsedObject, getRequestConfig(token));
            cb(false);
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.other && error.response.data.other.errorCode) {
                handleCustomErrors(error.response.data.other.errorCode);
            } else {
                displayNotification({ type: 'error', message: 'Error' });
            }
        }
    };
};
