/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

import React from 'react';

export const NoMatchScreen = ({ location }) => (
    <div>
        <h1>404</h1>
        <h3>No match for <code>{location.pathname}</code></h3>
    </div>
);
