/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Image, Table, Accordion } from 'semantic-ui-react';

// ELEMENTS
import { TablePaginationFooter, TableHeader, SearchBar, CountriesAutosuggest } from '../../elements';
// HOCS
import withPagination from '../../hocs/with_pagination';
// ACTIONS
import { fetchPlaces, deletePlace } from '../../../actions';
// MODALS
import CreateStationModal from '../../modals/create_station_modal';
import DeleteConfirmationModal from '../../modals/general_delete_confirmation_modal';

import { getApiImage, getCountryName } from '../../../utils';

class StationTableClass extends Component {
    state = {
        countrySearch: '',
        citySearch: '',
        titleSearch: '',
        photosSearch: '',
        categoriesSearch: '',
        mainCategorySearch: '',
        sortColumn: '',
        sortDirection: '',
        searchOpen: false,
    };

    componentDidMount() {
        const { token } = this.props;
        this.timeout = setTimeout(() => {
            this.props.fetchListData(token, 1, { ...this.state }, 'STATION');
        }, 600);
    }

    getRows = () => {
        const { data } = this.props;

        if (!data) return null;
        if (data.length === 0) return (<Table.Row key="no_results"><Table.Cell colSpan={16}>No results</Table.Cell></Table.Row>);

        return data.map((place) => {
            const { sequence, coverPublicUrls, countryCode, cityName, title, photosNumber, categoriesNumber, mainCategoryName } = place;
            let thumbnailUrl = null;
            if (coverPublicUrls) ({ mediaThumbnailUrl: thumbnailUrl } = coverPublicUrls);

            return (
                <Table.Row key={sequence}>
                    <Table.Cell>
                        <Image src={getApiImage(thumbnailUrl)} style={{ width: '35px', height: '35px' }}/>
                    </Table.Cell>
                    <Table.Cell>{getCountryName(countryCode)}</Table.Cell>
                    <Table.Cell>{cityName}</Table.Cell>
                    <Table.Cell>{title}</Table.Cell>
                    <Table.Cell>{photosNumber}</Table.Cell>
                    <Table.Cell>{categoriesNumber}</Table.Cell>
                    <Table.Cell>{mainCategoryName}</Table.Cell>
                    <Table.Cell textAlign="right">
                        <CreateStationModal place={place}/>
                        <DeleteConfirmationModal
                            title="Delete Station"
                            content={`Are you sure you want to delete ${title}?`}
                            icon="trash outline"
                            tooltip="Delete Station"
                            sequence={sequence}
                            cb={this.deleteStation}/>
                    </Table.Cell>
                </Table.Row>
            );
        });
    };

    deleteStation = (sequence) => {
        const { token, paginationObj } = this.props;
        const { countrySearch, citySearch, sortColumn, sortDirection } = this.state;
        this.props.deletePlace(token, sequence, paginationObj.selectedPage, { countrySearch, citySearch, sortColumn, sortDirection }, true);
    };

    handleSearchCountry = (event, { value }) => {
        const { token } = this.props;
        clearTimeout(this.timeout);
        this.setState({
            ...this.state,
            countrySearch: value,
        });
        this.timeout = setTimeout(() => {
            this.props.fetchListData(token, 1, { ...this.state, countrySearch: value }, 'STATION');
        }, 500);
    };

    clearSearchCountry = () => {
        this.handleSearchCountry(null, { value: '' });
    };

    handleSearchCities = (event, { value }) => {
        const { token } = this.props;
        clearTimeout(this.timeout);
        this.setState({
            ...this.state,
            citySearch: value,
        });
        this.timeout = setTimeout(() => {
            this.props.fetchListData(token, 1, { ...this.state, citySearch: value }, 'STATION');
        }, 500);
    };

    clearSearchCities = () => {
        this.handleSearchCities(null, { value: '' });
    };

    handleSearchTitle = (event, { value }) => {
        const { token } = this.props;
        clearTimeout(this.timeout);
        this.setState({
            ...this.state,
            titleSearch: value,
        });
        this.timeout = setTimeout(() => {
            this.props.fetchListData(token, 1, { ...this.state, titleSearch: value }, 'STATION');
        }, 500);
    };

    clearSearchTitle = () => {
        this.handleSearchTitle(null, { value: '' });
    };

    handleSearchPhotos = (event, { value }) => {
        const { token } = this.props;
        clearTimeout(this.timeout);
        this.setState({
            ...this.state,
            photosSearch: value,
        });
        this.timeout = setTimeout(() => {
            this.props.fetchListData(token, 1, { ...this.state, photosSearch: value }, 'STATION');
        }, 500);
    };

    clearSearchPhotos = () => {
        this.handleSearchPhotos(null, { value: '' });
    };

    handleSearchCategories = (event, { value }) => {
        const { token } = this.props;
        clearTimeout(this.timeout);
        this.setState({
            ...this.state,
            categoriesSearch: value,
        });
        this.timeout = setTimeout(() => {
            this.props.fetchListData(token, 1, { ...this.state, categoriesSearch: value }, 'STATION');
        }, 500);
    };

    clearSearchCategories = () => {
        this.handleSearchCategories(null, { value: '' });
    };

    handleSearchMainCategory = (event, { value }) => {
        const { token } = this.props;
        clearTimeout(this.timeout);
        this.setState({
            ...this.state,
            mainCategorySearch: value,
        });
        this.timeout = setTimeout(() => {
            this.props.fetchListData(token, 1, { ...this.state, mainCategorySearch: value }, 'STATION');
        }, 500);
    };

    clearSearchMainCategory = () => {
        this.handleSearchMainCategory(null, { value: '' });
    };

    handleSort = (clickedColumn) => {
        const { sortColumn, sortDirection } = this.state;
        const { paginationObj, token } = this.props;

        let newColumn = clickedColumn;
        let newDirection = 'ascending';

        if (sortColumn === newColumn) {
            if (sortDirection === 'ascending') {
                newDirection = 'descending';
            } else {
                newColumn = null;
                newDirection = null;
            }
        }

        this.setState({
            sortColumn: newColumn,
            sortDirection: newDirection,
        });

        this.props.fetchListData(
            token,
            paginationObj.selectedPage,
            { ...this.state, sortColumn: newColumn, sortDirection: newDirection },
            'STATION',
        );
    };

    render() {
        const { paginationObj, paginateChangePage } = this.props;
        const {
            citySearch, titleSearch, photosSearch, categoriesSearch,
            mainCategorySearch, sortColumn, sortDirection,
            searchOpen,
        } = this.state;
        if (paginationObj === null) return null;

        return (
            <div>
                <TableHeader title="Transport Stations" icon="train" content="Manage Stations"/>
                <div>
                    <CreateStationModal/>
                    <br/><br/>
                    <Accordion styled style={{ width: '100%' }}>
                        <Accordion.Title
                            active={searchOpen}
                            onClick={() => this.setState({ ...this.state, searchOpen: !searchOpen })}>
                            Search
                        </Accordion.Title>
                        <Accordion.Content active={searchOpen}>
                            <div style={{ width: '300px', display: 'inline-block' }}>
                                Country<br/>
                                <CountriesAutosuggest handleChange={this.handleSearchCountry} clearSearch={this.clearSearchCountry}/>
                            </div>
                            <div style={{ width: '300px', display: 'inline-block' }}>
                                City<br/>
                                <SearchBar
                                    searchFunc={this.handleSearchCities}
                                    clearSearchFunc={this.clearSearchCities}
                                    value={citySearch}/>
                            </div>
                            <div style={{ width: '300px', display: 'inline-block' }}>
                                Title<br/>
                                <SearchBar
                                    searchFunc={this.handleSearchTitle}
                                    clearSearchFunc={this.clearSearchTitle}
                                    value={titleSearch}/>
                            </div>
                            <div style={{ width: '300px', display: 'inline-block' }}>
                                Number of Photos<br/>
                                <SearchBar
                                    searchFunc={this.handleSearchPhotos}
                                    clearSearchFunc={this.clearSearchPhotos}
                                    value={photosSearch}
                                    numeric/>
                            </div>
                            <br/><br/>
                            <div style={{ width: '300px', display: 'inline-block' }}>
                                Number of Categories<br/>
                                <SearchBar
                                    searchFunc={this.handleSearchCategories}
                                    clearSearchFunc={this.clearSearchCategories}
                                    value={categoriesSearch}
                                    numeric/>
                            </div>
                            <div style={{ width: '300px', display: 'inline-block' }}>
                                Main Category<br/>
                                <SearchBar
                                    searchFunc={this.handleSearchMainCategory}
                                    clearSearchFunc={this.clearSearchMainCategory}
                                    value={mainCategorySearch}/>
                            </div>
                        </Accordion.Content>
                    </Accordion>
                    <Table striped sortable fixed singleLine>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell width={1}>
                                    Photo
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    width={2}
                                    sorted={sortColumn === 'COUNTRY' ? sortDirection : null}
                                    onClick={() => { this.handleSort('COUNTRY'); }}
                                >
                                    Country
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    width={2}
                                    sorted={sortColumn === 'CITY' ? sortDirection : null}
                                    onClick={() => { this.handleSort('CITY'); }}>
                                    City
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    width={3}
                                    sorted={sortColumn === 'TITLE' ? sortDirection : null}
                                    onClick={() => { this.handleSort('TITLE'); }}>
                                    Title
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    width={1}
                                    sorted={sortColumn === 'PHOTOS_NUMBER' ? sortDirection : null}
                                    onClick={() => { this.handleSort('PHOTOS_NUMBER'); }}>
                                    Photos
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    width={2}
                                    sorted={sortColumn === 'CATEGORIES_NUMBER' ? sortDirection : null}
                                    onClick={() => { this.handleSort('CATEGORIES_NUMBER'); }}>
                                    Categories
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    width={4}
                                    sorted={sortColumn === 'MAIN_CATEGORY' ? sortDirection : null}
                                    onClick={() => { this.handleSort('MAIN_CATEGORY'); }}>
                                    Main Category
                                </Table.HeaderCell>
                                <Table.HeaderCell width={2}>
                                    Options
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {this.getRows()}
                        </Table.Body>
                        <TablePaginationFooter
                            paginationObj={paginationObj}
                            colSpan={10}
                            handleChangePage={paginateChangePage}
                            searchObj={this.state}
                        />
                    </Table>
                </div>
            </div>
        );
    }
}

function mapStateToProps({ auth, places }) {
    const { token } = auth;
    const { places: data, paginationObj } = places;

    return {
        token,
        data,
        paginationObj,
        type: 'STATION',
    };
}

export const StationTable = connect(mapStateToProps, {
    fetchListData: fetchPlaces,
    deletePlace,
})(withPagination(StationTableClass));

