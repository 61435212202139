/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import IndexScreen from './screens/index_screen';
import LoginScreen from './screens/login_screen';
import CountriesScreen from './screens/countries_screen';
import CitiesScreen from './screens/cities_screen';
import TagsScreen from './screens/tags_screen';
import CategoriesScreen from './screens/categories_screen';
import CategoriesTreeScreen from './screens/categories_tree_screen';
import CityEditorRequestsScreen from './screens/city_editor_requests_screen';
import PlacesScreen from './screens/places_screen';
import StoriesScreen from './screens/stories_screen';
import UsersScreen from './screens/users_screen';
import UserCategoriesScreen from './screens/user_categories_screen';
import NoContentScreen from './screens/no_content_screen';
import Station from './screens/transport_station_screen';
import { NoMatchScreen } from './screens/404_screen';

import {
    LOGIN_ROUTE,
    COUNTRIES_ROUTE,
    CITIES_ROUTE,
    TAGS_ROUTE,
    CATEGORIES_ROUTE,
    CATEGORIES_TREE_ROUTE,
    CITY_EDITOR_REQUESTS_ROUTE,
    PLACES_ROUTE,
    STORIES_ROUTE,
    USERS_ROUTE,
    USER_CATEGORIES_ROUTE,
    NO_CONTENT_ROUTE,
    STATION_ROUTE,
    STATIONS_DISABLED,
} from '../constants';


export default class Router extends Component {
    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Route exact path="/" component={IndexScreen}/>
                    <Route path={LOGIN_ROUTE} component={LoginScreen}/>
                    <Route path={COUNTRIES_ROUTE} component={CountriesScreen}/>
                    <Route path={CITIES_ROUTE} component={CitiesScreen}/>
                    <Route path={TAGS_ROUTE} component={TagsScreen}/>
                    <Route path={CATEGORIES_ROUTE} component={CategoriesScreen}/>
                    <Route path={CATEGORIES_TREE_ROUTE} component={CategoriesTreeScreen}/>
                    <Route path={CITY_EDITOR_REQUESTS_ROUTE} component={CityEditorRequestsScreen}/>
                    <Route path={PLACES_ROUTE} component={PlacesScreen}/>
                    <Route path={STORIES_ROUTE} component={StoriesScreen}/>
                    <Route path={USERS_ROUTE} component={UsersScreen}/>
                    <Route path={USER_CATEGORIES_ROUTE} component={UserCategoriesScreen}/>
                    <Route path={NO_CONTENT_ROUTE} component={NoContentScreen}/>
                    {!STATIONS_DISABLED && <Route path={STATION_ROUTE} component={Station} />}
                    <Route component={NoMatchScreen}/>
                </Switch>
            </BrowserRouter>
        );
    }
}
