/*
 *
 * @Copyright 2018 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Accordion, Table } from 'semantic-ui-react';

// ELEMENTS
import { TablePaginationFooter, TableHeader, SearchBar, CountriesAutosuggest } from '../../elements';
// HOCS
import withPagination from '../../hocs/with_pagination';
// ACTIONS
import { fetchCountries } from '../../../actions';

import { getCountryName } from '../../../utils';

class sorts {
    static compareCountryName(a, b) {
        if (a.countryName < b.countryName) return -1;
        if (a.countryName > b.countryName) return 1;
        return 0;
    }

    static reverseCompareCountryName(a, b) {
        if (a.countryName < b.countryName) return 1;
        if (a.countryName > b.countryName) return -1;
        return 0;
    }

    static compareCountryCities(a, b) {
        if (a.citiesNumber < b.citiesNumber) return 1;
        if (a.citiesNumber > b.citiesNumber) return -1;
        return 0;
    }

    static reverseCompareCountryCities(a, b) {
        if (a.citiesNumber < b.citiesNumber) return -1;
        if (a.citiesNumber > b.citiesNumber) return 1;
        return 0;
    }
}

class CountriesTableClass extends Component {
    state = {
        countrySearch: '',
        citiesSearch: '',
        sortColumn: '',
        sortDirection: '',
        searchOpen: false,
    };

    getRows = () => {
        const { data } = this.props;
        if (data.length === 0) return (<Table.Row key="no_results"><Table.Cell>No results</Table.Cell></Table.Row>);

        return data.map((country) => {
            const { sequence, countryCode, citiesNumber } = country;
            let { countryName } = country;
            if (!countryName) {
                country.countryName = getCountryName(countryCode);
                countryName = getCountryName(countryCode);
            }

            return (
                <Table.Row key={sequence}>
                    <Table.Cell>{countryName}</Table.Cell>
                    <Table.Cell>{citiesNumber}</Table.Cell>
                </Table.Row>
            );
        });
    };

    deleteCountry = (countrySequence) => {
        const { token, paginationObj } = this.props;
        const { countrySearch, citiesSearch, sortColumn, sortDirection } = this.state;
        this.props.deleteCountry(token, countrySequence, paginationObj.selectedPage, { countrySearch, citiesSearch, sortColumn, sortDirection });
    };

    handleSearchCountry = (event, { value }) => {
        const { token } = this.props;
        const { citiesSearch, sortColumn, sortDirection } = this.state;
        clearTimeout(this.timeout);
        this.setState({
            ...this.state,
            countrySearch: value,
        });
        this.timeout = setTimeout(() => {
            this.props.fetchListData(token, 1, { countrySearch: value, citiesSearch, sortColumn, sortDirection });
        }, 500);
    };

    clearSearchCountry = () => {
        this.handleSearchCountry(null, { value: '' });
    };

    handleSearchCities = (event, { value }) => {
        const { token } = this.props;
        const { countrySearch, sortColumn, sortDirection } = this.state;
        clearTimeout(this.timeout);
        this.setState({
            ...this.state,
            citiesSearch: value,
        });
        this.timeout = setTimeout(() => {
            this.props.fetchListData(token, 1, { countrySearch, citiesSearch: value, sortColumn, sortDirection });
        }, 500);
    };

    clearSearchCities = () => {
        this.handleSearchCities(null, { value: '' });
    };

    handleSort = (clickedColumn) => {
        const { data } = this.props;
        const { sortColumn, sortDirection, countrySearch, citiesSearch } = this.state;
        const { paginationObj, token } = this.props;

        let newColumn = clickedColumn;
        let newDirection = 'descending';

        if (sortColumn === newColumn) {
            if (sortDirection === 'descending') {
                newDirection = 'ascending';
                if (newColumn === 'COUNTRY_CODE') data.sort(sorts.reverseCompareCountryName);
                else { data.sort(sorts.reverseCompareCountryCities); }
            } else {
                newColumn = null;
                newDirection = null;
                this.props.fetchListData(token, paginationObj.selectedPage, { countrySearch, citiesSearch });
            }
        } else if (newColumn === 'COUNTRY_CODE') {
            data.sort(sorts.compareCountryName);
        } else {
            data.sort(sorts.compareCountryCities);
        }

        this.setState({
            sortColumn: newColumn,
            sortDirection: newDirection,
        });
    };

    render() {
        const { paginationObj, paginateChangePage } = this.props;
        const { citiesSearch, sortColumn, sortDirection, searchOpen } = this.state;
        if (paginationObj === null) return null;

        return (
            <div>
                <TableHeader title="Countries" icon="globe" content="Manage Countries"/>
                <div>
                    <Accordion styled style={{ width: '100%' }}>
                        <Accordion.Title
                            active={searchOpen}
                            onClick={() => this.setState({ ...this.state, searchOpen: !searchOpen })}>
                            Search
                        </Accordion.Title>
                        <Accordion.Content active={searchOpen}>
                            <div style={{ width: '300px', display: 'inline-block' }}>
                                Country<br/>
                                <CountriesAutosuggest handleChange={this.handleSearchCountry} clearSearch={this.clearSearchCountry}/>
                            </div>
                            <div style={{ width: '300px', display: 'inline-block' }}>
                                Number of Cities<br/>
                                <SearchBar
                                    searchFunc={this.handleSearchCities}
                                    clearSearchFunc={this.clearSearchCities}
                                    value={citiesSearch}
                                    numeric/>
                            </div>
                        </Accordion.Content>
                    </Accordion>
                    <Table striped sortable fixed singleLine>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell
                                    width={8}
                                    sorted={sortColumn === 'COUNTRY_CODE' ? sortDirection : null}
                                    onClick={() => { this.handleSort('COUNTRY_CODE'); }}
                                >
                                    Country
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    width={8}
                                    sorted={sortColumn === 'CITIES_NUMBER' ? sortDirection : null}
                                    onClick={() => { this.handleSort('CITIES_NUMBER'); }}
                                >
                                    Cities
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {this.getRows()}
                        </Table.Body>
                        <TablePaginationFooter
                            paginationObj={paginationObj}
                            colSpan={10}
                            handleChangePage={paginateChangePage}
                            searchObj={this.state}
                        />
                    </Table>
                </div>
            </div>
        );
    }
}

function mapStateToProps({ auth, countries }) {
    const { token } = auth;
    const { countries: data, paginationObj } = countries;

    return {
        token,
        data,
        paginationObj,
    };
}

export const CountriesTable = connect(mapStateToProps, {
    fetchListData: fetchCountries,
})(withPagination(CountriesTableClass));

